import * as React from "react";
import {useNavigate} from "react-router-dom";

// Design
import {Box, Button, Grid, Typography} from "@mui/material";
import { motion } from "framer-motion";
import BlockIcon from '@mui/icons-material/Block';

export const PpaInvalidUrl = ({ ppaLockedMessage, networkCode, isPpaHasExpired }) => {

    const navigate = useNavigate();

    const backToPpaType = () => {
        navigate(`/ppa/${ networkCode }`);
    }

    console.log(isPpaHasExpired)

    const ppaExpiredMessage = "Unfortunately, the PPA has expired"
    const invalidMessage = isPpaHasExpired ? ppaExpiredMessage : ppaLockedMessage

    return (
        <motion.div
            initial={{y: -120, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: .4}}
            className={"invalid-url flex flex-wrap justify-center my-auto"}
        >
            <BlockIcon sx={{ width: '100%', fontSize:'100px', margin: '0 0 20px' }}  color="error"/>
            <Typography component={"h2"}
                        variant="h2-poppins"
                        className={"invalid-url-title"}
            >
                <span>PPA Error</span>
            </Typography>

            <Box component={"div"}
                 sx={{ width: '100%', textAlign: 'center' }}
            >
                <Typography component={"h3"}
                            variant="h3-poppins"
                            className={"invalid-url-text"}
                >
                    <div dangerouslySetInnerHTML={{__html: invalidMessage}}
                         className={"max-w-[520px] mx-auto"}
                    ></div>
                </Typography>
            </Box>

            <Grid container>
                <Grid item xs={12} sx={{ margin: '60px 0 0' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={backToPpaType}
                        className="button back"
                        sx={{ display: 'flex', margin: '0 auto'}}
                        aria-label="Back to PPA Type Selection"
                    >
                        Back to PPA Type Selection
                    </Button>
                </Grid>
            </Grid>


        </motion.div>
    )
}
