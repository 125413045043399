import * as React from "react";
import {useNavigate} from "react-router-dom";

// Design
import {Button, CircularProgress, Grid} from "@mui/material";

// Redux
import {reusableFormSlice} from "../../store/reusableFormSlice";
import {useSelector} from "react-redux";

export const FormContentButtons = ({   requiredFieldsValidation,
                                       handleSubmit,
                                       activeStep,
                                       setActiveStep,
                                       listTabs,
                                       form,
                                       valid,
                                       validMandatory,

                                       isRead,

                                       joinedInputs,
                                       listTypeFormsInputs,

                                       isSubmitting,
                                       isDescription,
                                  }) => {

    // React Router Dom Hooks
    const navigate = useNavigate();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if(activeStep === 0) {
            navigate(-1);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const { selectListTypeFormsDatas} = reusableFormSlice.selectors
    const listTypeForms = useSelector(selectListTypeFormsDatas);


    return (
        <Grid container
              justifyContent="space-between"
              sx={{
                  margin: 'auto 0 30px',
                  padding: '20px 0 0',
                  ...listTabs.length === 0 && ({
                      display: 'none'
                  })
              }}
        >
            <Grid item xs={6} lg={2}>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleBack}
                        className="button back flex"
                        sx={{
                            display: (isDescription && activeStep === 0) ? 'none' : 'flex',
                        }}
                    >
                        Back
                    </Button>

            </Grid>
            <Grid item xs={6} lg={2}
                  sx={{display: 'flex', justifyContent: 'flex-end'}}>
                {(activeStep === listTabs.length - 1 && !isRead) && (
                    <Button
                        variant="contained"
                        color="primary"
                        // disabled={isSubmitting || joinedInputs.length === 0} // TODO - Temporary control
                        disabled={isSubmitting || joinedInputs.length === 0 || (listTypeForms.length !== 0 && listTypeFormsInputs.length === 0)}
                        className="button next"
                        type="button"
                        onClick={() => {
                            form.change("saveform", false);
                            requiredFieldsValidation(handleSubmit);
                        }}
                    >
                        {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Submit' }
                    </Button>
                )}
                {!(activeStep === listTabs.length - 1) && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={!validMandatory || !valid}
                        className="button next"
                        type="button"
                    >
                        Next
                    </Button>
                )}

            </Grid>
        </Grid>
    )
}
