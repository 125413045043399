import React, {useContext, useEffect, useState} from "react";
import {Field} from "react-final-form";

// Design
import {Grid} from "@mui/material";
import {AnimatePresence, motion} from "framer-motion";
import {CustomizedInputLabel, CustomizedTextField} from "./FormCustomizedInputs";
import {IoInformationCircleOutline} from "react-icons/io5";

// Components
import {FormLabelTooltip} from "./FormLabelTooltip";

// Custom Functions
import {isValidEmail, isValidUrl, missingFieldsChecking} from "../../../utils/CustomFunctions";

// Context
import { ListTypeFormContext } from "./listtypeform/ListTypeFormsContainer";
import {FormContentFormsContext} from "../FormContentForms";


export const FormTextField = ({ id,
                                name,
                                description,
                                inputIndex,
                                label,
                                type,
                                required,
                                isBlocked,
                                hide,
                                values,

                                keyLinkValue,
                                isRead
                            }) => {

    const [textDatas, setTextDatas] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidUrl, setInvalidUrl] = useState(false);

    // From Context (Cancel Field Management)
    const listTypeFormContext = useContext(ListTypeFormContext);
    const {
        cancelFieldsData: cancelFieldsDataContext,
        setCancelFieldsData: setCancelFieldsDataContext,
        remainingLinesData: remainingLinesDataContext,
        setRemainingLinesData: setRemainingLinesDataContext
    } = listTypeFormContext !== null && listTypeFormContext;

    // From Context (Missing Field Management)
    const formContentFormsContext = useContext(FormContentFormsContext);
    const {
        reloadMissingFieldsChecking: reloadMissingFieldsCheckingContext,
        setReloadMissingFieldsChecking: setReloadMissingFieldsCheckingContext,
    } = formContentFormsContext !== null && formContentFormsContext;


    // Avoid duplicate id Browser Issue
    const fieldUniqueId = inputIndex + '_' + id

    const handleChangeValue = (event) => {
        setTextDatas(event.target.value);
    }

    const validateField = () => {
        if ((type === 'email') && textDatas !== '') {

            if (isValidEmail(textDatas)) {
                setInvalidEmail(false)
                return textDatas
            } else {
                setInvalidEmail(true)
                return textDatas
            }

        } else if ((type === 'url') && textDatas !== '') {

            if (isValidUrl(textDatas)) {
                setInvalidUrl(false)
                return textDatas
            } else {
                setInvalidUrl(true)
                return textDatas
            }

        } else {

            return textDatas

        }
    }

    const textValuesSetAfterSave = () => {

        const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
        const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)

        // Restore data for remaining lines after a cancel or a line removal
        for (let i = 0; i < listTypeItemFromStorageArray.length; i++) {
            if ((listTypeItemFromStorageArray[i].input) === ('/api/inputs/' + id) &&
                (listTypeItemFromStorageArray[i].keyLink === keyLinkValue)) {

                const loadedFromStorage = (typeof listTypeItemFromStorageArray[i].value !== "string") ? "" : listTypeItemFromStorageArray[i].value;
                setTextDatas(loadedFromStorage)

            }
        }

        if (listTypeFormContext !== null) {
            setCancelFieldsDataContext(false);
            setRemainingLinesDataContext(false);
        }
    }

    // Retrieve Values
    const retrieveExistingListInputValues = () => {
        if (values.listInputValues[inputIndex]) {
            if (typeof values.listInputValues[inputIndex].value === "string" && values.listInputValues[inputIndex].value !== "") {
                const loadedTextValues = values.listInputValues[inputIndex].value;
                setTextDatas(loadedTextValues)
            }
        }
    }

    // PPA Existing Values From Token
    // useEffect(() => {
    //     values.token !== null && retrieveExistingListInputValues();
    // }, [values.token]);
    //
    // // DESCRIPTIONS Existing Values
    // useEffect(() => {
    //     (values.descriptionForm && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    // }, [values.descriptionForm, values.listInputValues]);

    useEffect(() => {
        (values.token !== null && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    }, [values]);


    // Values assigning
    useEffect(() => {

        if (values.token !== null && values.listInputValues[inputIndex]) {

            values.listInputValues[inputIndex].value = (textDatas !== '') ? validateField() : '';

        } else {
            // values.listInputValues[inputIndex] = { value : (type === 'email') ? (isValidEmail(textDatas) ? textDatas : "") : textDatas };
            values.listInputValues[inputIndex] = { value : validateField() };
            Object.assign(values.listInputValues[inputIndex], { keyLink: keyLinkValue, input: '/api/inputs/' + id });
        }

    }, [textDatas]);


    // Required Fields Detection
    useEffect(() => {
        missingFieldsChecking(hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, textDatas === '');
    }, [textDatas, invalidUrl, invalidEmail]);


    // List Type Form Saved Data Verification
    useEffect(() => {
        if (cancelFieldsDataContext || remainingLinesDataContext) {
            localStorage.listTypeItem && textValuesSetAfterSave();
        }
    }, [cancelFieldsDataContext, remainingLinesDataContext]);

    // console.log(isRead)
    return (
        <Grid item
              xs={12}
              lg={6}
              hidden={hide}
              className={`${!hide && 'flex-grid-input'} relative`}
        >
            <Field name={ name }
            >
                {props => (
                    <>
                        <CustomizedInputLabel shrink
                                              htmlFor={ `${fieldUniqueId}` }
                        >
                            { label } {required && " *"}

                            { description && (
                                <FormLabelTooltip title={ <div dangerouslySetInnerHTML={{__html: description}} /> }>
                                    <span>
                                        <IoInformationCircleOutline className={"mt-[1.6px] text-blue-500 text-xl"}/>
                                    </span>
                                </FormLabelTooltip>
                            )}
                        </CustomizedInputLabel>

                        <CustomizedTextField
                            name={props.input.name}
                            type={type}
                            value={textDatas}
                            onChange={handleChangeValue}
                            id={ `${fieldUniqueId}` }
                            inputProps={{
                                'data-inputid':`${id}`
                            }}
                            disabled={isRead || isBlocked}
                            // For email & URL fields
                            className={`${(((type === 'email') && invalidEmail) || ((type === 'url') && invalidUrl)) ? 'no-value' : ''} ${isRead ? 'disabled' : ''}`}
                            onBlur={() => {
                                (type === 'email' && textDatas === '') && setInvalidEmail(false);
                                (type === 'url' && textDatas === '') && setInvalidUrl(false)
                            }}
                        />
                        {props.meta.error && props.meta.touched && <span>{props.meta.error}</span>}
                    </>
                )}
            </Field>

            <AnimatePresence>
                {((type === 'email') && invalidEmail || (type === 'url') && invalidUrl) && (
                    <motion.div
                        initial={{x: -80, opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                        transition={{duration: .3}}
                        exit={{x: -80, opacity: 0}}
                        className={'field-error text-white bg-[#FE5353] py-1 px-3 rounded-[4px] absolute bottom-[-20px] text-sm'}
                    >
                        {(type === 'email') && 'Please enter a valid email'}
                        {(type === 'url') && 'Please enter a valid URL'}
                    </motion.div>
                )}
            </AnimatePresence>

        </Grid>
    )
}
