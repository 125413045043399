import React, {useState} from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";


export const TreeItemsSelection = ({treeItems, selectedViewItems, setSelectedViewItems}) => {

    // Select/Deselect All
    const [allItemsSelection, setAllItemsSelection] = useState([]);
    const [allItemsSelectionLabel, setAllItemsSelectionLabel] = useState(false);

    const getAllItemsIds = () => {
        const itemIds = []
        const registerItemId = (item) => {
            itemIds.push(item.id.toString())
            item.children.forEach(registerItemId)
        }

        treeItems.forEach(registerItemId)

        return itemIds
    }

    const handleAllItemsSelection = (event) => {
        const allItemsChecked = event.target.checked;

        if (allItemsChecked) {
            setAllItemsSelection(allItemsSelection.length === 0 ? getAllItemsIds() : []);
            setSelectedViewItems(allItemsSelection.length === 0 ? getAllItemsIds() : []);
            setAllItemsSelectionLabel(  allItemsSelection.length === 0);
        }

        if (!allItemsChecked) {
            setAllItemsSelection([]);
            setSelectedViewItems([]);
            setAllItemsSelectionLabel(  false);
        }
    }


    return (
        <div className={"checkbox-selection w-full flex justify-between items-center border-b border-b-zinc-200"}>
            <FormControlLabel
                label={allItemsSelectionLabel ? 'Deselect all' : 'Select all' }
                control={
                    <Checkbox
                        checked={allItemsSelection.length !== 0 && allItemsSelection.length === selectedViewItems.length}
                        indeterminate={allItemsSelection.length !== 0 && allItemsSelection.length !== selectedViewItems.length}
                        onChange={handleAllItemsSelection}
                    />
                }
                className={'text-sm'}
            />
            {selectedViewItems.length !== 0 && (
                <div className={'bg-gray-100 text-gray-800 px-3 py-[6px] rounded-sm text-sm'}>
                    <span>{selectedViewItems.length} resource{selectedViewItems.length > 1 && 's'} selected</span>
                </div>
            )}
        </div>
    );
}