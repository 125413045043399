import AxiosService from '../../../services/AxiosService'

const urlResourceActivities = 'api/resource_activities'
class ResourceActivityService {
    getFromResource(resourceId, token) {
        return AxiosService.getWithToken(
            `${urlResourceActivities}/resource/${resourceId}?pagination=false`,
            token,
            'jsonld'
        )
    }
}
export default ResourceActivityService
