import AxiosService from "./AxiosService";

const urlProjects = 'api/projects';
const urlProjectsPpas = 'api/ppa';

class ProjectService {
    getProjects(filters, token) {
        return AxiosService.getWithToken(urlProjects + `${filters ? filters : ''}`, token, 'jsonld');
    }

    getProjectsPpasPerInfrastructure(id, status, token){
        return AxiosService.getWithToken(urlProjectsPpas + `${id ? '?infrastructure.id=' + id : ''}${status ? '&status=' + status : ''}&exists[deletedAt]=false&pagination=false`, token, 'jsonld');
    }

    getProjectsPerInfrastructureId(id, token){
        return AxiosService.getWithToken(urlProjects + `${id ? '?infrastructure.id=' + id + '&pagination=false' : '?pagination=false'}`, token, 'jsonld');
    }

    getProjectPerId(id, token){
        return AxiosService.getWithToken(urlProjects + '/' + id, token, 'jsonld');
    }
}

export default ProjectService;