// Utils
import dayjs from "dayjs";

export const ItemDescriptifDate = ({myStyle, title, dataInfo, inputType}) => {

    const dateValue = dayjs(Array.isArray(dataInfo) ? dataInfo.join('') : dataInfo)
        .format(
        inputType.htmlType === 'date' ?
            'DD/MM/YYYY'
            : inputType.htmlType === 'time' ?
                'HH:mm'
            : 'DD/MM/YYYY - HH:mm'
        );

    return (
        <div className={myStyle} title={title}>
            {dateValue}
        </div>
)};