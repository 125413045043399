import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'

const changelogData = require('./changelog.json')

export const ChangeLog = () => {
    return (
        <motion.article
            id="change_log"
            className="min-h-screen
                       pt-4
                       lg:pt-4"
            initial={{ opacity: 0 }}
            transition={{ type: 'spring', duration: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="container">
                <div
                    className="wrapper
                            flex
                            flex-col
                            gap-10
                            lg:gap-14
                            pb-10"
                >
                    <div className="content flex flex-wrap justify-between pb-10">
                        <Typography
                            variant="h1"
                            className="title"
                            sx={{ maxWidth: { lg: '500px' }, margin: { xs: '0 0 20px', lg: '0 auto 0 0' } }}
                        >
                            Change <span className="text-blue-600/100">Log</span>
                        </Typography>

                        {changelogData.releases.map((release) => (
                            <React.Fragment key={release.version}>
                                <h1 key={release.version}>
                                    Release notes (<code>{release.version}</code>)
                                </h1>
                                {Object.entries(release.sections).map(([sectionTitle, items]) => (
                                    <React.Fragment key={sectionTitle}>
                                        <h3 className={'capitalize'}>{(sectionTitle.replaceAll('_', ' ').trim())}</h3>
                                        <ul>
                                            {items.map((item) => (
                                                <li key={item.id}>
                                                    {item.url &&
                                                        <a
                                                            href={item.url}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            [{Number(item.id) ? '#' : ''}{item.id}]
                                                        </a>
                                                    }
                                                    {' '}{item.description}
                                                </li>
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                ))}
                                <hr />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </motion.article>
    )
}
