import AxiosService from '../../../services/AxiosService'


const urlResourceTypes = "api/resource_types"
const urlResourceTypesConfig = "api/resource_types/config"

class ResourceTypeService {

    getAllResourceTypeByInfraId(id, token) {
        return AxiosService.getWithToken(`${urlResourceTypes}?infrastructure.id=${id}`, token, "jsonld");
    }

    searchResourceByInfraId(id, searchTerm, token, searchResourceType = null) {
        let url = `${urlResourceTypes}/infrastructures/${id}/resources/search?searchTerm=${searchTerm}`;
        if (null !== searchResourceType) {
            url += `&resourceTypeId=${searchResourceType.id}`
        }
        return AxiosService.getWithToken(url, token, "jsonld");
    }

    getOneResourceTypeById(id, token) {
        return AxiosService.getWithToken(`${urlResourceTypes}/${id}`, token, "jsonld");
    }

    postResourceTypeWithToken(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlResourceTypes, data, "jsonld");
    }

    putResourceTypeWithToken(data, id, token) {
        return AxiosService.putWithToken(urlResourceTypes + "/" + id, data, token, "jsonld");
    }

    deleteResourceTypeWithToken(id, token) {
        return AxiosService.deleteWithToken(urlResourceTypes + "/" + id, token, "jsonld");
    }

    // RT CONFIG
    getOneResourceTypeConfigById(id, token) {
        return AxiosService.getWithToken(`${urlResourceTypesConfig}/${id}`, token, "jsonld");
    }
}

export default ResourceTypeService;