import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";

// Design
import {Loader} from "../../../../../components/loader/Loader";

// Components
import {Forbidden} from "../../../../../pages/Forbidden/Forbidden";

// Context
import {DrawerTemporaryContext} from "../../../../../components/drawer/DrawerTemporary";

// Custom Hooks
import {useFetchUserRole} from "../../../../../hooks/useFetchUserRole";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {displayMessage} from "../../../../../redux/alertAction";
import {createSelector} from "reselect";

// Service
import ResourceService from "../../../services/Resource";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);


export const FormQrCode = ({ resource, setTextValue }) => {

    // User token
    const user = useSelector(selectUser);
    const token = user.token;

    const context = useContext(DrawerTemporaryContext);

    const method = context.method ?? "CREATE";
    const isUpdate = method === "UPDATE";

    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const role = useFetchUserRole();

    const {
        handleSubmit,
        reset,
        setValue,
        getValues,
        control
    } = useForm({
        defaultValues: {
            text: "",
        },
    })

    useEffect(() => {
        if (null !== resource) {
            setValue('text', resource?.qrcodeText !== '' ? resource?.qrcodeText : resource?.name);
        } else {
            reset();
        }
    }, [resource]);

    const onSubmit = async (data) => {

        setValue("text", data.text);
        setTextValue(data.text);

        try {
                setSubmitting(true);
                const requestData = {
                    qrcodeText: data.text,
                };

                const resourceService = new ResourceService()
                await resourceService.putResourceQrCode(requestData, context.idSelected, token).then(() => {
                    dispatch(displayMessage('Success', 'QR Code text updated', 'success'))
                })

                // reset({
                //     text: "",
                // });

                context.onDrawerClose();

                setSubmitting(false);


        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    if (!role
        || (!user.isSuperAdministrator
            && (
                (!role.canUpdateResourceTypes && isUpdate)))
    ) {
        return <Forbidden isInDrawer={true} />
    }

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
                <div className="w-full">

                    {submitting ? (
                        <Loader content="Submitting"/>
                    ) : (
                        <div className="w-full flex flex-col gap-6">
                            <div className="w-full flex flex-col gap-2">
                                <label htmlFor="text" className="text-slate-700 text-md font-bold">Enter your text</label>

                                <Controller
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 256,
                                            message: "The text must be at most 256 characters"
                                        },
                                    }}
                                    render={({ field: { onChange, value }, formState: {errors} }) => (
                                        <>
                                            <textarea placeholder="Text"
                                                      onChange={onChange}
                                                      value={value}
                                                      rows={5}
                                                      className="border border-slate-300 border-solid rounded-sm w-full grow p-4 focus:outline-none focus:ring focus:ring-blue-200 resize-none"
                                            ></textarea>
                                            {errors.text && (
                                                <span className={"text-sm text-red-500/70"}
                                                      role="alert">{errors.text.message}</span>
                                            )}
                                        </>
                                    )}
                                    name="text"
                                />
                            </div>
                            <div className="flex items-center gap-4">
                                <button
                                    type="submit"
                                    className={`grow border-dashed rounded-sm text-slate-700 bg-slate-200 text-md h-10 flex items-center justify-center hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                                >
                                    Save change
                                </button>
                            </div>
                        </div>
                    )}

                </div>
            </form>
        </div>
    )
};

