import { Controller, useForm } from 'react-hook-form'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import React, { useContext, useEffect, useState } from 'react'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import { Loader } from '../../../../components/loader/Loader'
import { FormLoader } from '../../../servicetype/loaders/Loaders'
import ConfirmDialog from '../../../../components/confirm/ConfirmDialog'
import { MyEditor } from '../../../../components/editor/MyEditor'
import Select from 'react-select'
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { useFetchResourceFromResourceTypeId } from '../../hooks/useFetchResourceFromResourceTypeId'
import { displayMessage } from '../../../../redux/alertAction'
import EventService from '../../../../services/EventService'
import { useFetchEventById } from '../../hooks/useFetchEventById'
import { FiTrash } from 'react-icons/fi'
import { motion } from 'framer-motion'
import { TfiBackRight } from 'react-icons/tfi'
import Tooltip from '@mui/material/Tooltip'
import { ResourceTree } from '../ResourceTree'

const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user,
)

export function EventForm({ resourceType, resourceEventTypes, from, to, resource, apiRef }) {
    dayjs.locale('fr')
    const user = useSelector(selectUser)

    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? 'CREATE'
    const isCreate = method === 'CREATE'
    const isUpdate = method === 'UPDATE'
    const [submitting, setSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [openConfirmationDeleteImage, setOpenConfirmationDeleteImage] = useState(false)
    const [description, setDescription] = useState('')
    const [temporaryfileIdToDelete, setTemporaryFileIdToDelete] = useState(null)
    const [fileIdToDelete, setFileIdToDelete] = useState([])
    const [isLoadingResource, setIsLoadingResource] = useState(false)

    const dispatch = useDispatch()
    const { listResources } = useFetchResourceFromResourceTypeId(resourceType.id, user.token, setIsLoading)
    const { event, eventIsLoading } = useFetchEventById(context.idSelected, user.token)

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: {
            eventType: null,
            title: '',
            description: '',
            from: null,
            to: null,
            resources: [],
            files: null,
        },
    })

    const setResource = (itemId) => {
        setValue('resources', itemId)
    }

    useEffect(() => {
        if (resource) {
            setResource(resource.id)
        }
    }, [isLoadingResource])

    useEffect(() => {
        if (!eventIsLoading) {
            if (event) {
                setValue('title', event.title)
                setValue('description', event.content)
                setValue('eventType', event.resourceEventType)
                setValue('from', dayjs(event.startingDate))
                setValue('to', dayjs(event.endingDate))
                setValue('resources', event.resource.id)
            } else {
                reset()
                setValue('from', dayjs(from?.toJSON()))
                setValue('to', dayjs(to?.toJSON()))
                setValue('resources', resource.id)
            }
        }
    }, [
        isLoading,
        from,
        to,
        event,
    ])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            if (isCreate || isUpdate) {
                const eventService = new EventService()

                const formData = new FormData()
                formData.append('title', data.title)
                formData.append('content', description)
                formData.append('resource', 'api/resources/' + data.resources)
                formData.append('startingDate', data.from)
                formData.append('endingDate', data.to)
                formData.append('resourceEventType', 'api/resource_event_types/' + data.eventType?.id)

                if (data.files) {
                    const arrayFiles = Array.from(data.files)
                    if (arrayFiles && arrayFiles.length > 0) {
                        Array.from(arrayFiles).forEach((file, index) => {
                            formData.append(`listEventFiles[${index}][file]`, file)
                            formData.append(`listEventFiles[${index}][filename]`, file.name)
                        })
                    }
                }

                if (fileIdToDelete && fileIdToDelete.length > 0) {
                    fileIdToDelete.forEach((fileId, index) => {
                        formData.append(`eventFilesToDelete[${index}]`, fileId)
                    })
                }

                if (isCreate) {
                    await eventService.postEvent(formData, user.token).then()
                } else {
                    await eventService.putEvent(context.idSelected, formData, user.token).then()
                }

                reset()

                dispatch(displayMessage('Success', isCreate ? 'Element created' : 'Element updated', 'success'))

                context.onDrawerClose()
            }
            setSubmitting(false)
        } catch (error) {
            console.log(error)
            setSubmitting(false)
            if (error.data) {
                dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
            } else {
                dispatch(displayMessage('Error', 'An error occurred', 'error'))
            }
        }
    }

    const handleEditorChange = (data) => {
        setDescription(data)
    }

    const handleDelete = async () => {
        try {
            const eventService = new EventService()
            eventService.deleteEvent(context.idSelected, user.token)
            reset()

            dispatch(displayMessage('Success', 'Element deleted', 'success'))

            context.onDrawerClose()
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    const handleDeleteFileConfirmation = (fileId) => {
        setTemporaryFileIdToDelete(fileId)
        setOpenConfirmationDeleteImage(true)
    }

    const handleDeleteFile = async () => {
        setFileIdToDelete((prev) => [...prev, temporaryfileIdToDelete]);
    }

    const handleCancelDeleteFile = async (fileId) => {
        setFileIdToDelete((prev) => prev.filter((prevId) => prevId !== fileId));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                <Loader content="Submitting" />
            ) : (
                <>
                    {/* 01 */}
                    {isLoading ? (
                        <FormLoader />
                    ) : (
                        <>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h3 className="text-xl font-bold mb-2 tracking-wide required">Title</h3>
                                    </div>
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            placeholder="Event title"
                                            {...register('title', {
                                                required: 'Event title is required',
                                            })}
                                            className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                        />
                                        {errors.name && !watch('title') && (
                                            <span className={'text-sm text-red-500/70'} role="alert">
                                                {errors.title.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide required">Event type</h3>
                                </div>
                                <Controller
                                    name="eventType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={resourceEventTypes}
                                            isClearable
                                            required={true}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <h3 className="text-xl font-bold mb-2 tracking-wide required">Resource</h3>
                            </div>
                            <div className="w-full">
                                <ResourceTree
                                    resourceType={resourceType}
                                    setIsLoadingResource={setIsLoadingResource}
                                    setResource={setResource}
                                    rid={(event && event.resource) ? event.resource?.id.toString() : resource?.id.toString()}
                                    apiRef={apiRef}
                                />
                                {errors.resources && !watch('resources') && (
                                    <span className={'text-sm text-red-500/70'} role="alert">
                                        {errors.resources.message}
                                    </span>
                                )}
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide">Description</h3>
                                </div>
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field }) => (
                                        <MyEditor
                                            initialvalue={watch('description')}
                                            setEditorData={handleEditorChange}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide">Files</h3>
                                </div>
                                {event?.listEventFiles.length > 0 &&
                                    <ul>
                                        {event?.listEventFiles.map((file) => (
                                            <li key={file.id} className={`flex flex-row hover:bg-slate-100 p-1 `}>
                                                <a
                                                    className={`flex w-full text-sm text-blue-500 hover:text-blue-700 ${fileIdToDelete.indexOf(file.id) > -1 ? 'line-through opacity-30' : ''} `}
                                                    href={file.path}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {file.filename}
                                                </a>
                                                {fileIdToDelete.indexOf(file.id) > -1 ?
                                                    <Tooltip title={'Cancel'} placement={'left'}>
                                                        <motion.button
                                                            className="self-end cursor-pointer hover:border-blue-500 hover:bg-blue-500 border-blue-400 bg-blue-400 rounded-full p-[6px]  flex transition ease-linear border ml-1"
                                                            whileTap={{scale: .945}}
                                                            whileHover={{scale: 1.045}}
                                                            transition={{duration: 0.6, ease: "ease-in", type: "spring"}}
                                                            onClick={() => handleCancelDeleteFile(file.id)}
                                                            type="button"
                                                        >
                                                            <div className="text-sm text-slate-50 font-bold"><TfiBackRight/></div>
                                                        </motion.button>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title={'Delete file'} placement={'left'}>
                                                        <motion.button
                                                            className="self-end cursor-pointer hover:border-red-500 hover:bg-red-500 border-red-400 bg-red-400 rounded-full p-[6px]  flex transition ease-linear border ml-1"
                                                            whileTap={{scale: .945}}
                                                            whileHover={{scale: 1.045}}
                                                            transition={{duration: 0.6, ease: "ease-in", type: "spring"}}
                                                            onClick={() => handleDeleteFileConfirmation(file.id)}
                                                            type="button"
                                                        >
                                                            <div className="text-sm text-slate-50 font-bold"><FiTrash/></div>
                                                        </motion.button>
                                                    </Tooltip>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                }
                                <ConfirmDialog
                                    isOpen={openConfirmationDeleteImage}
                                    onClose={() => setOpenConfirmationDeleteImage(false)}
                                    setIsOpen={setOpenConfirmationDeleteImage}
                                    onAgree={() => handleDeleteFile()}
                                />
                                <input
                                    type="file"
                                    placeholder="File"
                                    multiple
                                    {...register('files')}
                                    className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                />
                                {errors.name && !watch('files') && (
                                    <span className={'text-sm text-red-500/70'} role="alert">
                                            {errors.files.message}
                                        </span>
                                )}
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide required">From</h3>
                                </div>
                                <Controller
                                    control={control}
                                    name="from"
                                    render={({ field }) => {
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                                <DateTimePicker
                                                    label="From"
                                                    name="from"
                                                    ampm={false}
                                                    value={field.value}
                                                    inputRef={field.ref}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                    }}
                                                    onChange={(date) => {
                                                        field.onChange(date)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide required">To</h3>
                                </div>
                                <Controller
                                    control={control}
                                    name="to"
                                    render={({ field }) => {
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                                <DateTimePicker
                                                    label="To"
                                                    name="to"
                                                    ampm={false}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                    }}
                                                    value={field.value}
                                                    inputRef={field.ref}
                                                    onChange={(date) => {
                                                        field.onChange(date)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )
                                    }}
                                />
                            </div>
                            {resource.canManage && (
                                <>
                                    <input
                                        type="submit"
                                        value={isCreate ? 'Create' : 'Update'}
                                        className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                    />
                                    {isUpdate ? (
                                        <>
                                            <input
                                                type="button"
                                                value="Delete"
                                                onClick={() => {
                                                    setOpenConfirmation(true)
                                                }}
                                                className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                            />
                                            <ConfirmDialog
                                                isOpen={openConfirmation}
                                                onClose={() => setOpenConfirmation(false)}
                                                setIsOpen={setOpenConfirmation}
                                                onAgree={() => handleDelete()}
                                            />
                                        </>
                                    ) : null}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </form>
    )
}
