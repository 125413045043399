import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";

// Design
import {FiChevronDown, FiChevronUp} from "react-icons/fi";

// Utils
import {isUrlDetection} from "../../utils/isUrlDetection";
import {isISOString} from "../../utils/isISOString";
import dayjs from "dayjs";

// Context
import useCatalogContext from "../../hooks/useCatalogContext";


export const Tables = ({ tab }) => {
    const allKeys = tab.flatMap(item => Object.keys(item));
    const uniqueKeys = [...new Set(allKeys)];

    // More/Fewer Items
    const { typecatalog } = useParams();
    const { styleCatalog } = useCatalogContext("", typecatalog, 500);

    const [sliceNb, setSliceNb] = useState(5)
    const [openRows, setOpenRows] = useState(false);

    const tabFilteredRows = tab?.slice(0, sliceNb);

    const handleTableRows = () => {
        if (!openRows) {
            const tabLength = tab?.length;
            setSliceNb(tabLength);
            setOpenRows(true);
        } else {
            setSliceNb(5);
            setOpenRows(false);
        }
    }


    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-gray-200">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-slate-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    {uniqueKeys.map((key, index) => (
                        <th key={index} className={`px-6 py-3 min-w-32`}>
                            {key}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>

                {tabFilteredRows.map((item, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                    >
                        {uniqueKeys.map((key, colIndex) => {
                            const cellValue =
                                item[key] !== undefined && item[key] !== "" ? item[key] : "N/A";

                            const valueIsUrl = isUrlDetection(item[key]);

                            const valueIsDate = isISOString(item[key]);
                            const dateValue =  valueIsDate ? dayjs(item[key]).format('DD/MM/YYYY - HH:mm') : '';

                            return (
                                <td
                                    key={colIndex}
                                    className={`pl-6 pr-0 py-4 `}
                                    title={cellValue}
                                >
                                    {valueIsUrl ? (
                                        <Link to={cellValue}
                                              target={"_blank"}
                                              title={`${cellValue}`}
                                              className={'underline hover:no-underline'}
                                        >
                                            {cellValue}
                                        </Link>
                                    ) : (
                                        <div className={`max-h-[80px] overflow-y-auto pr-1
                                                        ${(typeof item[key] === 'string' && (item[key]).length < 30) && 'whitespace-nowrap'}
                                                        ${(typeof item[key] === 'string' && (item[key]).length > 100) && 'min-w-60'}
                                                        `}
                                             dangerouslySetInnerHTML={{__html: valueIsDate ? dateValue : cellValue}}
                                        />
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>

            {tab.length > 5 &&
                <button className={`sticky left-[50%] translate-x-[-50%] bottom-2 flex justify-center items-center gap-2 text-sm w-fit mt-3 mb-4 px-3 ${styleCatalog} rounded-md cursor-pointer shadow-md shadow-zinc-200 hover:shadow-none`}
                     onClick={handleTableRows}
                >
                    <div className={`${styleCatalog} border border-solid border-slate-300 text-lg font-bold flex items-center justify-center rounded-full my-2`}>
                        {openRows ? <FiChevronUp/> : <FiChevronDown/>}
                    </div>
                    Display {openRows ? 'fewer' : 'more'} data
                </button>
            }
        </div>
    );
};