import React from 'react'

// Components
import {ItemDescriptifDate} from "./ItemDescriptifDate";
import {Link} from "react-router-dom";

// URL
const url = process.env.REACT_APP_API_URL;


const ItemDescriptif = ({ dataInfo, dataTitle, path, email, id, type, title, inputType }) => {

    const handleClick = () => {
        if (email) {
            if (!email) return;
            window.location.href = `mailto:${email}`;
        }
    }

    const myStyle = `w-fit text-sm text-slate-700 w-1/5 grow py-2 ${(path || email) ? "underline hover:no-underline cursor-pointer flex gap-2" : "cursor-default"}`;

    // Date Fields/Inputs
    const dateInputTypes = ["date", "datetime", "time"];


    return (
        <li className={`flex items-center py-1 gap-x-2 min-h-12 w-full flex-wrap`}>
            { dataTitle && <p className={`text-sm text-slate-500 font-medium fontfamilly break-words max-w-[60%] py-2`}>{dataTitle}</p> }

            {dataInfo ?
                (path ?
                    <Link
                        to={typeof path === "number" ? `/catalog/${id}/${type === "installation" ? "installations" : "services"}/${path}` : path}
                        target={path !== "number" && "_blank"}
                        title={title}
                        className={myStyle}
                    >
                        {dataInfo}
                    </Link>
                    :    inputType?.htmlType === 'textarea' ? (
                            <div
                                className={myStyle}
                                dangerouslySetInnerHTML={{ __html: dataInfo }}
                                title={title}
                            />
                        ) : dateInputTypes.includes(inputType?.htmlType) ? (
                            <ItemDescriptifDate myStyle={myStyle}
                                                title={title}
                                                dataInfo={dataInfo}
                                                inputType={inputType}
                            />
                        ) : inputType?.htmlType === 'file' ? (
                            <Link to={url + dataInfo}
                                  target={"_blank"}
                                  title={`${url + dataInfo}`}
                                  className={`${myStyle} underline hover:no-underline cursor-pointer`}
                                  rel="noreferrer"
                            >
                                {url + dataInfo}
                            </Link>
                        ) : (
                            <p
                                className={myStyle}
                                onClick={handleClick}
                                title={title}
                            >
                                {dataInfo}
                            </p>
                        )
                )
                :
                <span className="text-xs border border-slate-200 bg-slate-50 text-slate-500 px-2">N/A</span>
            }
        </li>
    );
};

export default ItemDescriptif;