import React from "react";

// Design
import {useTheme} from "@mui/material/styles";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import {ProjectTableNoRowsOverlay} from "./project-table-no-rows-overlay";


const CustomToolbar = ({fileName}) => {
    const theme = useTheme();

    const containerStylesProp = {
        gap: { xs: '2px' },
        borderBottom: '1px solid rgba(224, 224, 224, .4)'
    }

    const searchStyleProp = {
        width: '100%',
        maxWidth: {xs:'100%', lg:'346px'},
        background: '#F5F5F5 0% 0% no-repeat padding-box',
        border: '1px solid #F5F5F5',
        borderRadius: '6px',
        opacity: 1,
        padding: '0 6px 4px 6px',
        margin: {xs: '6px 6px', lg: '0 0 0 6px'},
        '.MuiSvgIcon-root' : {
            color: 'white',
            padding: '4px 4px',
            backgroundColor: '#272727',
            borderRadius: '2rem',
            fontSize: '26px',
            margin: '0 6px 0 0',
        }
    }

    const commonStylesProp = {
        padding: '0.65rem 1rem',
        color: theme.palette.neutral.main
    }

    return (
        <GridToolbarContainer sx={containerStylesProp}>
            <GridToolbarQuickFilter sx={searchStyleProp} />
            <Box sx={{ flexGrow: {xs: 0, lg: 1} }} />
            <GridToolbarFilterButton sx={commonStylesProp} />
            <GridToolbarExport sx={commonStylesProp} csvOptions={{fileName: fileName}}/>
        </GridToolbarContainer>
    );
}


export const ProjectsTable = ({ adaptedProjectsData, paginationModel, setPaginationModel, visibleFields, initialSorting, setInitialSorting, dataLoading, exportFileName }) => {

    const columns = React.useMemo(
        () => adaptedProjectsData?.columns?.filter((column) => visibleFields.includes(column.field)),
        [adaptedProjectsData?.columns]
    );

    const customToolbarProps = {
        fileName: exportFileName,
    };

    return (
        <DataGrid

            {...adaptedProjectsData}

            columns={columns}

            getRowId={(row) => row['@id']}
            getRowHeight={() => 'auto'}
            getRowClassName={() => `odd:bg-white even:bg-neutral-50`}

            getCellClassName={() => {
                return 'custom-padding'
            }}

            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}

            sortModel={initialSorting}
            onSortModelChange={(newSortModel) => setInitialSorting(newSortModel)}

            disableRowSelectionOnClick

            slots={{
                toolbar: CustomToolbar,
                noRowsOverlay: ProjectTableNoRowsOverlay,
            }}
            slotProps={{
                toolbar: customToolbarProps,
                loadingOverlay: {
                    variant: 'skeleton',
                    // noRowsVariant: 'skeleton',
                },
            }}

            // loading={dataLoading}
        />
    )
}