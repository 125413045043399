import React, {useState} from 'react';
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {FiFolder, FiGrid, FiHelpCircle, FiHome, FiSend, FiShield} from "react-icons/fi";
import {MdOutlineDashboard} from "react-icons/md";
import {FaNetworkWired} from "react-icons/fa";
import {useFetchNetworkConfig} from "../../../network/hooks/useFetchNetworkConfig";
import {AnimatePresence, motion} from "framer-motion";
import {Link, useNavigate} from "react-router-dom";
import {stopPropagation} from "leaflet/src/dom/DomEvent";

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

const selectUser = createSelector(
    state => state.login.user,
    user => user
);


const FastAccess = ({ isMobile }) => {
    const [fastAccess, setFastAccess] = useState(false);

    const currentInfrastructureId = useSelector(selectCurrentInfrastructure);
    const user = useSelector(selectUser);

    const { totalItem: totalItemNetworks } = useFetchNetworkConfig();

    const handleOpenFastAccess = () => {
        setFastAccess(!fastAccess);
    }

    const fastAccessData = [
        {
            id: 1,
            title: "Home",
            link: "/",
            icon: <FiHome />,
            bgfrom: "from-purple-700",
            border: "border-gray-500",
            display: true,
        },
        {
            id: 2,
            title: "Dashboard",
            link: "/dashboard",
            icon: <MdOutlineDashboard />,
            bgfrom: "bg-indigo-500",
            border: "border-indigo-500",
            display: currentInfrastructureId > 0,
        },
        {
            id: 3,
            title: "Project Proposal",
            link: "/ppa",
            icon: <FiSend/>,
            bgfrom: "from-orange-500",
            border: "border-orange-500",
            display: true,
        },
        {
            id: 4,
            title: "Catalog",
            link: "catalog",
            icon: <FiFolder/>,
            bgfrom: "from-yellow-500",
            border: "border-yellow-500",
            display: true,
        },
        {
            id: 5,
            title: "Networks",
            link: "list/networks",
            icon: <FaNetworkWired/>,
            bgfrom: "bg-blue-500",
            border: "border-blue-500",
            display: totalItemNetworks > 0,
        },
        {
            id: 6,
            title: "SuperAdmin",
            link: "superadmin",
            icon: <FiShield />,
            bgfrom: "from-red-400",
            border: "border-red-400",
            display: user.isSuperAdministrator,
        },
        {
            id: 7,
            title: "Help",
            link: "/docs",
            icon: <FiHelpCircle/>,
            bgfrom: "bg-gray-500",
            border: "border-gray-500",
            display: true,
        }
    ];

    return (
        <>
            <motion.button
                className="border border-slate-400 bg-slate-transparent h-8 w-8 rounded-full"
                onClick={handleOpenFastAccess}
                title="fast access app"
                whileTap={{scale: 0.97}}
                whileHover={{scale: 1.04}}
            >
                <span className="w-full h-full flex justify-center items-center text-slate-400 text-md"><FiGrid/></span>
            </motion.button>

            <AnimatePresence>
                {fastAccess &&
                    <motion.div
                        className={`bg-slate-100 md:w-56 w-[90vw] drop-shadow-md top-20 absolute  ${isMobile ? "left-[5vw]" : "right-0"} flex flex-col rounded-3xl p-2`}
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 10}}
                        transition={{type: "spring", stiffness: 300, damping: 24, delay: .2}}
                    >
                        <div className="rounded-3xl bg-white w-full flex flex-col gap-4 shadow-sm">
                            <ul className="grid md:grid-cols-2 grid-cols-3 h-full w-full text-left gap-4 p-4">
                                {fastAccessData.map(item => item.display &&
                                    <li className="flex flex-col items-center justify-center p-2" key={item.id}>
                                        <Link to={item.link}>
                                            <span className={`w-14 h-14 rounded-full flex items-center text-slate-50 text-2xl font-bold gap-2 justify-center p-2 transition bg-gradient-to-br ${item.bgfrom}`}
                                            >{item.icon}</span>
                                        </Link>
                                        <p className={`whitespace-nowrap text-sm text-slate-500 font-medium`}>{item.title}</p>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
};

export default FastAccess;
