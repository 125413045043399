import React, {useContext, useEffect, useRef, useState} from "react";

// Design
import {useTreeViewApiRef} from "@mui/x-tree-view";
import {BsFillBoxFill} from "react-icons/bs";
import {CircularProgress} from "@mui/material";

// Components
import {FormResourcesTree} from "./items/FormResourcesTree";
import {FormResourcesFieldView} from "./views/FormResourcesFieldView";
import {FormResourceTypesFieldView} from "./views/FormResourceTypesFieldView";
import {DrawerTemporary} from "../../../../../drawer/DrawerTemporary";

// Context
import {ListTypeFormContext} from "../listtypeform/ListTypeFormsContainer";
import {FormContentFormsContext} from "../../FormContentForms";

// Custom Functions
import {missingFieldsChecking} from "../../../../utils/CustomFunctions";

// Custom Hooks
import {
    useFetchResourceFromResourceTypeId
} from "../../../../../../features/resource/hooks/useFetchResourceFromResourceTypeId";

// Redux
import {useSelector} from "react-redux";
import {createSelector} from "reselect";

const selectCurrentInfrastructure = createSelector(
    (state) => state.currentInfrastructure,
    (ci) => ci
);

const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user
);


export const FormResourcesFieldContainer = ({
                                                listInput,
                                                keyLinkValue,
                                                id,
                                                inputIndex,
                                                values,
                                                hide,
                                                required
}) => {

    const [method, setMethod] = useState( null );
    const [titleDrawer, setTitleDrawer] = useState( '');
    const [drawerForm, setDrawerForm] = useState(null);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const apiRefSource = useTreeViewApiRef();

    const [selectedItems, setSelectedItems] = useState([]);
    const visibleField = useRef(null);

    // From Context (Cancel Field Management)
    const listTypeFormContext = useContext(ListTypeFormContext);
    const {
        cancelFieldsData: cancelFieldsDataContext,
        setCancelFieldsData: setCancelFieldsDataContext,
        remainingLinesData: remainingLinesDataContext,
        setRemainingLinesData: setRemainingLinesDataContext
    } = listTypeFormContext !== null && listTypeFormContext;

    // From Context (Missing Field Management)
    const formContentFormsContext = useContext(FormContentFormsContext);
    const {
        reloadMissingFieldsChecking: reloadMissingFieldsCheckingContext,
        setReloadMissingFieldsChecking: setReloadMissingFieldsCheckingContext,
    } = formContentFormsContext !== null && formContentFormsContext;


    // Avoid duplicate id Browser Issue
    const fieldUniqueId = inputIndex + '_' + id

    /***** RESOURCE PRELOAD (SINGLE & MULTIPLE VALUES) *****/

    const selectValuesFormatting = (selectValuesToFormat) => {
        if (!Array.isArray(selectValuesToFormat)) {

            if(!selectValuesToFormat) {
                return;
            }

            // STRING Value(s) - Single && Multiple (isMultiple: true)
            if (typeof(selectValuesToFormat) === "string") {
                const formatLoadedSelectValues = ((selectValuesToFormat.indexOf(',') > -1) ? selectValuesToFormat.split(',') : selectValuesToFormat.split()).map(item => item);
                setSelectedItems(formatLoadedSelectValues)
            }

        } else {
            setSelectedItems(selectValuesToFormat)
        }
    }

    const selectValuesSetAfterSave = () => {

        if (localStorage.listTypeItem) {
            const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
            const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)


            // Restore data for remaining lines after a cancel or a line removal
            for (let i = 0; i < listTypeItemFromStorageArray.length; i++) {
                if ((listTypeItemFromStorageArray[i].input) === ('/api/inputs/' + id) &&
                    (listTypeItemFromStorageArray[i].keyLink === keyLinkValue)) {

                    const loadedSelectValuesFromStorage = listTypeItemFromStorageArray[i].value;
                    selectValuesFormatting(loadedSelectValuesFromStorage);

                }
            }
        }

        if (listTypeFormContext !== null) {
            setCancelFieldsDataContext(false);
            setRemainingLinesDataContext(false);
        }
    }


    // Retrieve Values
    const retrieveExistingListInputValues = () => {
        if (values.listInputValues[inputIndex]) {
            if (typeof values.listInputValues[inputIndex].value === "string" && values.listInputValues[inputIndex].value !== "") {

                const loadedSelectValues = values.listInputValues[inputIndex].value;
                selectValuesFormatting(loadedSelectValues);

            }
        }
    }

    useEffect(() => {
        (values.token !== null && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    }, [values]);


    // Values assigning
    useEffect(() => {
        if (values.token !== null && values.listInputValues[inputIndex]) {

            values.listInputValues[inputIndex].value = (selectedItems.length !== 0) ? selectedItems : "";

        } else {
            values.listInputValues[inputIndex] = { value : selectedItems };
            Object.assign(values.listInputValues[inputIndex], { keyLink: keyLinkValue, input: '/api/inputs/' + id });
        }
    }, [selectedItems]);


    // Required Fields Detection
    useEffect(() => {
        missingFieldsChecking(hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, selectedItems.length === 0, visibleField);
    }, [selectedItems]);


    // List Type Form Saved Data Verification
    useEffect(() => {
        if (cancelFieldsDataContext || remainingLinesDataContext) {
            localStorage.listTypeItem && selectValuesSetAfterSave();
        }
    }, [cancelFieldsDataContext, remainingLinesDataContext]);


    const currentInfrastructure = useSelector(selectCurrentInfrastructure);
    const user = useSelector(selectUser);

    const [isLoading, setIsLoading] = useState(true);

    const { listResources } = useFetchResourceFromResourceTypeId(
        listInput.inputType.code === 'preload_resourcetypes' ? listInput.resourceTypesPreload?.id : null,
        user.token,
        setIsLoading,
        0,
        currentInfrastructure
    );

    // Drawer Form Content
    const handleResourceSelectionClick = () => {
        setMethod('CREATE');
        setTitleDrawer('Resource selection');
        setDrawerForm(
            <FormResourcesTree
                apiRef={apiRefSource}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                isMultiple={listInput.isMultiple}
                id={id}
                fieldUniqueId={fieldUniqueId}

                listResources={listResources}
                isLoading={isLoading}
                handleDrawerClose={handleDrawerClose}
            />
        );
        setIsOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setIsOpenDrawer(false);
    }


    return (
        <>
            <div className={'w-full'} ref={visibleField}>
                <div className={`resource-card rounded-lg border border-zinc-400 text-sm`}>
                    <div className={`resource-card-title w-full px-4 py-3 rounded-lg
                                    flex flex-col justify-start items-start gap-y-3
                                    lg:flex-row lg:items-center`
                    }>
                        <div>
                            <span className={`font-bold mr-2 text-[14px]`}>
                                {listInput?.resourceTypesPreload ? listInput?.resourceTypesPreload?.name : 'Resources'}
                            </span>
                                <span className={`py-[3px] px-[10px] bg-slate-200 rounded-full`}>
                                {selectedItems.length}
                            </span>
                        </div>
                        <button type="button"
                                className={'flex items-center gap-2 px-2 py-1 lg:ml-auto bg-white border border-blue-400 text-blue-500 rounded-md hover:bg-blue-50'}
                                onClick={handleResourceSelectionClick}
                                disabled={isLoading}
                        >
                            {!isLoading ? (
                                <>
                                    <BsFillBoxFill /> {listInput.isMultiple ? 'Select resources' : 'Select a resource'}
                                </>
                            ) : (
                                <>
                                    <CircularProgress size={20} /> Loading resources
                                </>
                            )}
                        </button>

                        {!isOpenDrawer && (
                            <input type={"hidden"} value={selectedItems.length} data-inputid={id} id={fieldUniqueId} />
                        )}
                    </div>

                    {listInput.inputType.code === 'preload_resourcetypes' && (
                        <FormResourceTypesFieldView handleResourceSelectionClick={handleResourceSelectionClick}
                                                    listInput={listInput}
                                                    selectedItems={selectedItems}
                                                    setSelectedItems={setSelectedItems}
                        />
                    )}

                    {listInput.inputType.code === 'preload_resource' && (
                        <FormResourcesFieldView apiRef={apiRefSource}
                                                handleResourceSelectionClick={handleResourceSelectionClick}
                                                listInput={listInput}
                                                listResources={listResources}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItems}
                        />
                    )}

                </div>
            </div>

            <DrawerTemporary
                isOpenDrawer={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                typeForm={'resourceForm'}
                method={method}
                titleDrawer={titleDrawer}
                content={drawerForm}
            />
        </>
    )
};