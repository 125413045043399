import React, { useEffect, useState } from 'react'
import QRCode from "react-qr-code";
import {useLocation} from "react-router-dom";

// Design
import {FiEdit} from "react-icons/fi";
import './assets/scss/_Qr.scss'

// Components
import {DrawerTemporary} from "../../../../components/drawer/DrawerTemporary";
import {FormQrCode} from "./form/FormQRCode";
import {downloadQrCode} from "../../utils/downloadQRCode";


export const Qr = ({resource}) => {

    const url = process.env.REACT_APP_API_URL;
    let location = useLocation();
    const resourceUrl = url + (location.pathname).slice(1) + location.search;

    const [textValue, setTextValue] = useState("");
    const [method, setMethod] = useState( null );
    const [titleDrawerUpdate, setTitleDrawerUpdate] = useState( '');
    const [drawerForm, setDrawerForm] = useState(null);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    // Drawer Form Content
    const handleEditText = () => {
        setMethod('UPDATE');
        setTitleDrawerUpdate('Associate a text to the QR Code');
        setDrawerForm(
            <FormQrCode resource={resource}
                        setTextValue={setTextValue}
            />
        );
        setIsOpenDrawer(true);
    }

    useEffect(() => {
        setTextValue(resource?.qrcodeText !== '' ? resource?.qrcodeText : resource?.name)
    }, [resource])

    const handleDrawerClose = () => {
        setIsOpenDrawer(false);
    }


    return (
        <>
            <div className="w-full lg:w-4/5 mx-auto h-full relative">
                {/*<div className="w-full flex">QR for resource "{resource.name}"</div>*/}

                <div className="qr-code-actions w-full flex flex-wrap justify-center items-center gap-3 my-4">
                    <div>
                        <button
                            type="button"
                            className="py-3 px-4 bg-blue-500 text-white text-sm hover:bg-blue-600 rounded-full min-w-32 cursor-pointer"
                            onClick={downloadQrCode}
                        >
                            Download
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="py-3 px-4 bg-blue-500 text-white text-sm hover:bg-blue-600 rounded-full min-w-32 cursor-pointer"
                            onClick={() => window.print()}
                        >
                            Print
                        </button>
                    </div>
                    {resource?.canManage && (
                        <div className="flex gap-2 sm:ml-auto">
                            <button type="button"
                                    className={"flex gap-2 items-center text-sm md:text-md"}
                                    onClick={handleEditText}
                            >
                                Edit QR Code text
                                <span className="flex border border-slate-200 rounded-full p-3">
                            <FiEdit />
                        </span>
                            </button>
                        </div>
                    )}
                </div>

                <div className="flex flex-col gap-4 w-full py-6 lg:p-16 items-center bg-zinc-50 rounded-md">
                    <div id="QRCodeDiv" className="qr-code print-content  w-64 flex flex-col justify-center items-center">
                        <QRCode id="QRCode" value={resourceUrl} />
                        <div className="flex justify-center items-center text-center mt-4">
                            <p id="QRCodeText">{textValue}</p>
                        </div>
                    </div>
                </div>
            </div>

            <DrawerTemporary
                isOpenDrawer={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                typeForm={'resourceForm'}
                method={method}
                titleDrawerUpdate={titleDrawerUpdate}
                content={drawerForm}
                idSelected={resource.id}
            />
        </>
    )
}