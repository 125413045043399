import { Fragment } from 'react';
import { Grid } from '@mui/material';
import {CollapsibleFields} from "./collapsible-fields";
import { Tables } from '../../components/tables/Tables';
import BoxListDescriptif from "../catalog/components/BoxListDescriptif";
import ItemDescriptif from '../catalog/components/ItemDescriptif';


export const AdditionalFields = ({ additionalFields }) => {

    const handleFormMultiple = (form) => {

        let inputValuesAndKeyLinks = []

        for (let input of form.listInputs) {

            input.listInputValues.map((iv) => {
                inputValuesAndKeyLinks.push({
                    [input.name]: iv.listDisplayValue.join(', '),
                    keyLink: iv.keyLink,
                })
            })

        }

        const inputValuesGroupedByKeyLink = Object.groupBy(inputValuesAndKeyLinks, ({ keyLink }) => keyLink)

        const inputValuesReduced = Object.values(inputValuesGroupedByKeyLink).map((item) => (
            item.reduce((result, current) => {
                return Object.assign(result, current)
            }, {})
        ))

        inputValuesReduced.forEach((element) => delete element.keyLink)

        return inputValuesReduced
    }

    return (
        additionalFields.map((tab, index) => (
            <Fragment key={'tab-' + index}>

                <Grid container rowSpacing={0} columnSpacing={{ xs: 2, lg: 4 }} sx={{ margin: '0' }}>
                    {tab.listForms.map((form, indexForm) => (
                        <Fragment key={'form-' + indexForm}>
                            {form.typeOfForm !== null ? (
                                <CollapsibleFields title={`${form.name}`}
                                                   content={<Tables tab={handleFormMultiple(form)} />}
                                />
                            ) : (
                                <CollapsibleFields
                                    title={`${form.name}`}
                                    content={
                                        <BoxListDescriptif widthFull={false}>
                                            {form.listInputs.map((input, indexInput) => (
                                                <ItemDescriptif title={input.name}
                                                                inputType={input.inputType}
                                                                dataTitle={input.name}
                                                                dataInfo={input.listInputValues.map((iv) => (
                                                                    iv.listDisplayValue.join(', ')
                                                                ))}
                                                                key={'input-' + indexInput}
                                                />
                                            ))}
                                        </BoxListDescriptif>
                                    }
                                />
                            )}
                        </Fragment>
                    ))}
                </Grid>

            </Fragment>
        ))
    )
}