import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';

export default function SubMenu ({ setValue, panel }) {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={"w-[98%] mx-auto py-1.5 px-2 rounded-lg bg-slate-50 border border-slate-200"}>
            <TabList onChange={handleChange} aria-label="lab API tabs example"
                     visibleScrollbar={false}
                     indicatorColor="secondary"
                     sx={{
                         border:"none !important",
                         '.MuiTabs-flexContainer': {
                             justifyContent: 'space-between'
                         }
                     }}

                     TabIndicatorProps={{
                         sx: { bgcolor: '#3b82f6', height: '100%', borderRadius: '6px', transitionDelay: '.3s'}
                     }}
                     variant="scrollable"
                     scrollButtons="auto"
                     allowScrollButtonsMobile
            >
                { panel.map( item =>
                    <Tab label={item.title}
                         key={item.id}
                         value={item.link}
                         sx={{
                             position: 'relative',
                             zIndex: '10',
                             color: '#475569',
                             transition:'color .4s ease .3s',
                             '&.Mui-selected': {
                                 fontWeight: 'bold',
                                 color: 'white',
                                 padding: '0 12px'
                             }
                         }}
                    />
                )}
            </TabList>
        </div>
    );
}