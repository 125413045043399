import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import dayjs from "dayjs";

// Components
import {ProjectsTable} from "../projects-table/projects-table";
import {ProjectsFurtherActions} from "../projects-further-actions/projects-further-actions";

// Design
import {Box, Collapse} from "@mui/material";
import {FiEdit, FiTrash} from "react-icons/fi";
import { FaChevronDown, FaChevronUp, FaInbox, FaRegFilePdf } from 'react-icons/fa'
import {UserCell} from "../cells/user-cell/user-cell";
import {AnimatePresence, motion} from "framer-motion";
import './index.scss';

// Redux
import {useSelector} from "react-redux";

// .Env
const url = process.env.REACT_APP_API_URL;

const PPA_VISIBLE_FIELDS = ['networkPpa', 'ppaName', 'title', 'userData', 'submission_date', 'actions'];


export const ProjectsPpaList = ({currentInfrastructure, installationProjectsPpa}) => {

    const installationsData = useSelector((state) => state.platformReducers.data);
    const currentInfrastructureName = currentInfrastructure ? installationsData.find((installation) => installation.id === currentInfrastructure).name : '';

    const [isLoadingProjectsPpa, setIsLoadingProjectsPpa] = useState(false);

    useEffect(() => {
        installationProjectsPpa.length === 0 ? setIsLoadingProjectsPpa(true) : setIsLoadingProjectsPpa(false);
    }, [installationProjectsPpa]);

    const [toggleTable, setToggleTable] = useState(false);

    const handleToggleTable = () => {
        setToggleTable(!toggleTable);
    }

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    });


    const adaptedInstallationProjectsPpa = {
        rows : installationProjectsPpa,
        columns : [
            {
                field: 'networkPpa',
                headerName: 'Source',
                flex: 2,
                minWidth: 150,
                valueGetter: (data) => {
                    return data?.row?.networkPpa ? `Network - ${data?.row?.networkPpa?.network?.name}`: `${currentInfrastructureName}`
                },
            },
            {
                field: 'ppaName',
                headerName: 'Type',
                flex: 2,
                minWidth: 150,
                valueGetter: (data) => data?.row?.networkPpa?.name,
            },
            {
                field: 'title',
                headerName: 'Title',
                flex: 4,
                minWidth: 150,
                valueGetter: (data) => data.value,
            },
            {
                field: 'userData',
                headerName: 'User',
                flex: 4,
                minWidth: 250,
                valueGetter: (data) => `${data?.row?.firstname} ${data?.row?.lastname} - ${data?.row?.email}`,
                renderCell: (data) => {
                    return <UserCell userData={data}/>
                }
            },
            {
                field: 'submission_date',
                headerName: 'Submission date',
                flex: 2,
                minWidth: 150,
                valueFormatter: (data) => {
                    if (data?.value === null) {
                        return 'N/A';
                    }
                    return dayjs(data?.value).format(' DD/MM/YYYY HH:mm');
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                minWidth: 150,
                sortable: false,
                valueGetter: (data) => `${data?.row?.pdfName ? (url + data?.row?.pdfName) : ''}`,
                renderCell: (data) => {
                    return (
                        <div key={data?.id} className={'w-full flex items-center justify-start'}>
                            <Link to={url + data?.row?.pdfName}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={'View PDF'}
                            >
                                <button
                                    className={`bg-blue-500 p-2 rounded-full text-white mr-1 cursor-pointer ${data?.row?.pdfName ? 'visible' : 'hidden'}`}>
                                    <FaRegFilePdf/>
                                </button>
                            </Link>

                            <ProjectsFurtherActions
                                title="Further actions available soon"
                                content={
                                    <>
                                        <div>
                                            <button
                                                className="bg-green-500 p-2 rounded-full text-white cursor-pointer"
                                                title={'Create a new project'}
                                                onClick={() => {}}
                                            >
                                                <FiEdit/>
                                            </button>
                                            <span>Create a new project</span>
                                        </div>
                                        <div>
                                            <button
                                                className="bg-red-500 p-2 rounded-full text-white cursor-pointer"
                                                title={'Delete'}
                                                onClick={() => {}}
                                            >
                                                <FiTrash/>
                                            </button>
                                            <span>Delete</span>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    )
                },
            },
        ],
    };

    const [initialSorting, setInitialSorting] = useState([
        {
            field: 'submission_date',
            sort: 'desc'
        }
    ]);

    const exportFileName = 'Project proposals list';


    return (
        <AnimatePresence>
            {installationProjectsPpa.length > 0 && (
                <motion.div className={`projects-ppa-list w-full flex flex-col ${toggleTable && 'mb-10'}`}
                            initial={{opacity: 0, translateY: 30}}
                            animate={{opacity: 1, translateY: 0}}

                            transition={{type: "spring", duration: .6, delay: .2, ease: "linear"}}
                >
                    <div className={`heading w-full
                                     flex ${toggleTable ? 'flex-col-reverse lg:flex-row flex-wrap lg:flex-nowrap' : ''} justify-between lg:items-end
                                     gap-6`}
                    >

                        <div className={`w-full flex flex-wrap justify-center lg:justify-start gap-3 px-3 py-4 lg:py-3 rounded-lg ${!toggleTable && 'mb-2'}`}>
                            <h3 className="flex items-center font-bold text-left max-w-[500px] text-zinc-700">
                                <FaInbox size={26} className={"mr-2"}/><span>Incoming project {installationProjectsPpa.length > 1 ? 'proposals' : 'proposal'}</span>
                            </h3>

                            <motion.button initial={{translateY: -15, opacity: 0}}
                                           transition={{type: "spring", duration: .3, ease: "easeInOut"}}
                                           animate={{translateY: 0, opacity: 1}}
                                           exit={{opacity: 0}}
                                           whileHover={{scale: 1.01}}
                                           className={`relative flex items-center justify-center shadow-md shadow-zinc-100 border border-zinc-200 bg-white`}
                                           onClick={handleToggleTable}
                            >
                                <div
                                    className={'absolute top-[-16px] right-[-32px] flex items-center justify-center bg-orange-100 rounded-full p-[5px] mr-[6px]'}>
                                    <div className={'text-white font-bold bg-orange-500 rounded-full min-w-[28px] px-[6px] py-[2px]'}>
                                        {installationProjectsPpa.length}
                                    </div>
                                </div>
                                {!toggleTable ?
                                    <>Show <FaChevronDown className={'ml-1 relative top-[1px]'} size={14} /></>
                                     :
                                    <>Hide <FaChevronUp className={'ml-1 relative top-[1px]'} size={14} /></>
                                }
                            </motion.button>
                        </div>

                    </div>

                    <Collapse in={toggleTable}
                              timeout="auto"
                    >
                        <Box sx={{
                            width: '100%',
                                ...installationProjectsPpa.length > 4 && ({
                                    height: 400,
                                })
                        }}>
                            <ProjectsTable adaptedProjectsData={adaptedInstallationProjectsPpa}
                                           visibleFields={PPA_VISIBLE_FIELDS}
                                           paginationModel={paginationModel}
                                           setPaginationModel={setPaginationModel}
                                           initialSorting={initialSorting}
                                           setInitialSorting={setInitialSorting}
                                           exportFileName={exportFileName}

                                           dataLoading={isLoadingProjectsPpa}

                            />
                        </Box>
                    </Collapse>

                </motion.div>
            )}
        </AnimatePresence>
    );
}
