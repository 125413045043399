import React from "react";

// Design
import {FiX} from "react-icons/fi";


export const FormResourcesDeleteItem = ({selectedItems, setSelectedItems, item}) => {

    const handleDeleteResource = (itemId) => {
        setSelectedItems(selectedItems.filter((item) => item !== itemId));
    }

    return (
        <button type="button"
                onClick={() => handleDeleteResource(item)}
                className={'rounded-full ml-auto p-1 transition duration-200 hover:bg-slate-200'}
                title={'Delete Resource'}
        >
            <FiX size={12} />
        </button>
    );
};