import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const projectSlice = createSlice({
    name: "project",
    initialState: {
        projectsList: [],
        infrastructureProjectsList: [],
    },
    reducers: {
        addProjectsList: (state, action) => {
            return {...state, projectsList: [...action.payload]};
        },
        addInfrastructureProjectsList: (state, action) => {
            return {...state, infrastructureProjectsList: [...action.payload]};
        },
        updateProjectsList: (state, action) => {
            return {...state, projectsList: action.payload};
        },
        updateInfrastructureProjectsList: (state, action) => {
            return {...state, infrastructureProjectsList: action.payload};
        },
        resetProjectsList: (state, action) => {
            return {...state, projectsList: action.payload};
        },
        resetInfrastructureProjectsList: (state, action) => {
            return {...state, infrastructureProjectsList: action.payload};
        },
    },
    selectors: {
        selectProjectsList: (state) => state.projectsList,
        selectInfrastructureProjectsList: (state) => state.infrastructureProjectsList,
    }
})