import React, {useState} from "react";

// Design
import {ImHourGlass} from "react-icons/im";
import {AnimatePresence, motion} from "framer-motion";
import './index.scss';


export const ProjectsFurtherActions = ({ title, content }) => {

    const [showContent, setShowContent] = useState(false);

    return (
        <div className={"relative"}>
            <button
                onMouseOver={() => setShowContent(true)}
                onMouseLeave={() => setShowContent(false)}
                className="bg-gray-500 p-2 rounded-full text-white mr-1 cursor-pointer"
                title={'Project actions'}
                onClick={() => {}}
            >
                <ImHourGlass size={14}/>
            </button>
            <AnimatePresence>
                {showContent && (
                    <motion.div
                        className="w-fit flex flex-col lg:flex-row items-center gap-2 bg-white drop-shadow-md absolute top-[-8px] right-10 px-3 py-2 rounded-lg z-10"
                        initial={{opacity: 0, x: 10}}
                        animate={{opacity: 1, x: 0}}
                        exit={{opacity: 0, x: 10}}
                        transition={{type: "spring", stiffness: 300, damping: 24}}
                    >
                        <div className={"whitespace-nowrap font-bold m-1"}>{ title }</div>
                        <div className={`projects-further-actions`}>
                            { content }
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
};