import {motion} from "framer-motion";
import {LoadMore} from "../../../components/button/LoadMore";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";
import {FiCloudSnow, FiCpu, FiFileText,  FiUsers} from "react-icons/fi";
import {NotFound} from "../../../pages/NotFound/NotFound";
import {truncateTextLetter} from "../../../utils/truncateText";
import {Loader} from "../../../components/loader/Loader";

export const Network = () => {

    const data = useContext(CompConfigContext);

    if (data.isLoading) return <Loader />

    if (data.error) return <NotFound />

    return (
        <div className="pb-10 min-h-[65vh] flex items-start justify-center relative">
            <div className="container">
                <div className={`wrapper flex flex-col items-start gap-4`}>
                    {(!data?.myData || data?.myData.length) === 0 ?
                        <div className="flex flex-col items-center justify-center gap-4 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            <p className="text-slate-500 text-center text-md">
                                No results
                            </p>
                            <span className="block font-bold text-5xl text-slate-400 order-first"><FiCloudSnow/></span>
                        </div>
                        :
                        <motion.div
                            initial={{opacity: 0, translateY: 100}}
                            animate={{opacity: 1, translateY: 0}}
                            exit={{opacity: 0, translateY: 100}}
                            transition={{type: "spring", duration: .6, ease: "easeOut", delay: .4}}
                            className="w-full min-h-[64vh] relative"
                        >
                            <motion.ul
                                className={`grid w-full mt-20 ${data.gridMode ? 'lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8' : 'grid-cols-1 gap-2'} mb-24`}
                            >
                                {data.myData.map(item =>
                                    <motion.li
                                        className={`h-60 relative cursor-pointer border border-slate-300 shadow-sm shadow-slate-200 hover:shadow-slate-500 transition-shadow bg-white rounded-2xl flex ${data.gridMode ? 'flex-col' : 'flex-row'}`}
                                        title={`See the ${item.name} network`}
                                        onClick={() => data.handleClickPath(item.id)}
                                        key={item.id}
                                    >

                                        {/* header box */}
                                        <div
                                            className={`p-4 border-b border-slate-200 flex w-full ${data.gridMode ? "mb-0 gap-8" : "mb-0 gap-0"}`}>
                                            <div className="w-1/6">
                                                <div className="h-14 w-14 rounded-full">
                                                    <figure className="h-14 w-14">
                                                        <img
                                                            src={`${process.env.REACT_APP_API_URL}${item.logo ? item.logo : 'images/logo/isia_logo.png'}`}
                                                            alt={`Logo de ${item.name}`}
                                                            className="w-full h-full object-contain"
                                                        />
                                                    </figure>
                                                </div>
                                            </div>


                                            <div className="flex flex-col grow w-1/6">
                                                <p className="text-md font-medium text-slate-700">{truncateTextLetter(item.name, 25)}</p>
                                                <span className="text-zinc-500 text-xs order-first">{item.code}</span>
                                            </div>
                                        </div>

                                        <ul className={`p-4 flex flex-col divide-y divide-slate-200`}>
                                            <li className="flex items-center justify-between gap-2 p-1">
                                                <p className={`flex items-center gap-2 text-sm text-slate-500`}><span
                                                    className={`text-sm text-slate-600`}><FiCpu/></span>Installations
                                                </p>
                                                <span
                                                    className="rounded-full border border-slate-200 h-8 w-8 p-2 text-xs flex items-center justify-center text-slate-500">{item.listInfra?.length}</span>
                                            </li>
                                            <li className="flex  ietms-center justify-between gap-2 p-1">
                                                <p className={`flex items-center gap-2 text-sm text-slate-500`}><span
                                                    className={`text-sm text-slate-600`}><FiFileText/></span>Proposal
                                                    project</p>
                                                <span
                                                    className="rounded-full border border-slate-200 h-8 w-8 p-2 text-xs flex items-center justify-center text-slate-500">{item.listPPA?.length}</span>
                                            </li>
                                            <li className="flex  ietms-center justify-between gap-2 p-1">
                                                <p className={`flex items-center gap-2 text-sm text-slate-500`}><span
                                                    className={`text-sm text-slate-600`}><FiUsers/></span>Users Managers
                                                </p>
                                                <span
                                                    className="rounded-full border border-slate-200 h-8 w-8 p-2 text-xs flex items-center justify-center text-slate-500">{item.users?.length}</span>
                                            </li>
                                        </ul>

                                    </motion.li>
                                )}
                            </motion.ul>

                            <div className="absolute bottom-0 left-0 w-full">
                                <LoadMore
                                    dataLoad={4}
                                    itemsPerPage={data.itemsPerRow}
                                    setItemsPerPage={data.setItemsPerRow}
                                    totalItems={data.totalItem}
                                />
                            </div>

                        </motion.div>
                    }
                </div>
            </div>
        </div>
    )
}