import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useFetchListResourceEventType } from '../../hooks/useFetchListResourceEventType'
import { motion } from 'framer-motion'
import { FiEdit2, FiPlusCircle } from 'react-icons/fi'
import {Checkbox, Collapse} from '@mui/material'
import { DrawerTemporary } from '../../../../components/drawer/DrawerTemporary'
import { useEffect, useState } from 'react'
import { ResourceEventTypeForm } from '../form/ResourceEventTypeForm'
import { EventForm } from '../form/EventForm'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import timeGridPlugin from '@fullcalendar/timegrid'
import Tooltip from '@mui/material/Tooltip'
import { useFetchListEventByResourceType } from '../../hooks/useFetchListEventByResourceType'
import TuneIcon from '@mui/icons-material/Tune';
import {useMobile} from "../../../../hooks/useMobile";
import * as React from "react";
import { useTreeViewApiRef } from '@mui/x-tree-view'



export const Calendar = ({ resourceType, resource }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const userInfra = useSelector((state) => state.platformReducers.userInfra) ?? null
    const user = useSelector(selectUser)

    const apiRef = useTreeViewApiRef();

    const [calendarState, setCalendarState] = useState({
        isOpenDrawer: false,
        method: 'CREATE',
        isOpenEventDrawer: false,
        methodEvent: 'CREATE',
        selectedId: null,
        selectedEventId: null,
        from: null,
        to: null,
        isLoading: true,
        selectedEventTypes: [],
    })

    useEffect(() => {
        setEventIsLoading(true)
    }, [resource])

    const setIsLoading = (isLoading) => {
        setCalendarState((prev) => ({ ...prev, isLoading: isLoading }))
    }

    const setIsOpenDrawerEvent = (isOpen) => {
        setCalendarState((prev) => ({ ...prev, isOpenEventDrawer: isOpen }))
    }

    const setIsOpenDrawer = (isOpen) => {
        setCalendarState((prev) => ({ ...prev, isOpenDrawer: isOpen }))
    }

    const { resourceEventTypes } = useFetchListResourceEventType({
        resourceTypeId: resourceType.id,
        isLoading: calendarState.isLoading,
        setIsLoading: setIsLoading,
    })

    const { events, eventIsLoading, setEventIsLoading } = useFetchListEventByResourceType(
        userInfra,
        resource,
        user.token
    )

    const handleDrawerClose = () => {
        setCalendarState((prev) => ({
            ...prev,
            isOpenDrawer: false,
            isOpenEventDrawer: false,
            selectedId: null,
            isLoading: true,
            selectedEventId: null,
        }))
        setEventIsLoading(true)
    }

    const handleClickOpenDrawer = (openMethod, id) => {
        setCalendarState((prev) => ({ ...prev, method: openMethod ?? 'CREATE', selectedId: id, isOpenDrawer: true }))
    }

    const handleOpenDrawerEventCreate = (openMethod, info) => {
        setCalendarState((prev) => ({
            ...prev,
            methodEvent: openMethod ?? 'CREATE',
            selectedEventId: info.id ?? null,
            isOpenEventDrawer: true,
            from: info.start ?? new Date(),
            to: info.end ?? new Date(),
        }))
    }
    const handleOpenDrawerEventUpdate = (openMethod, info) => {
        setCalendarState((prev) => ({
            ...prev,
            methodEvent: openMethod ?? 'UPDATE',
            selectedEventId: info.event._def.publicId,
            isOpenEventDrawer: true,
            from: info.event._instance.range.start,
            to: info.event._instance.range.end,
        }))
    }

    useEffect(() => {
        setCalendarState((prev) => ({ ...prev, selectedEventTypes: resourceEventTypes }))
    }, [resourceEventTypes])
    const handleFilters = (resourceEventType) => {
        if (calendarState.selectedEventTypes.filter((eventType) => eventType.id === resourceEventType.id).length > 0) {
            setCalendarState((prev) => ({
                ...prev,
                selectedEventTypes: calendarState.selectedEventTypes.filter(
                    (eventType) => eventType.id !== resourceEventType.id
                ),
            }))
        } else {
            setCalendarState((prev) => ({
                ...prev,
                selectedEventTypes: [...calendarState.selectedEventTypes, resourceEventType],
            }))
        }
    }
    const handleSelectAll = (checked) => {
        if (checked) {
            setCalendarState((prev) => ({ ...prev, selectedEventTypes: resourceEventTypes }))
        } else {
            setCalendarState((prev) => ({ ...prev, selectedEventTypes: [] }))
        }
    }

    const isMobile = useMobile();
    const [toggleContent, setToggleContent] = useState(false);


    return (
        <>
            <div className="resource-calendar grid grid-rows-[auto_1fr] grid-cols-1 lg:grid-cols-[200px_repeat(5,_1fr)] gap-2 lg:gap-1 text-sm lg:text-md relative">
                <div className="w-full flex justify-between lg:flex-col lg:justify-start lg:col-span-1">
                    <div className={'lg:mb-4 lg:max-h-[80%] flex flex-col items-center border-2 border-blue-50 p-1'}>
                        <div className={'w-full flex justify-between items-center px-2 py-2 lg:p-2 border-b-2 border-blue-500 gap-2'}>
                            <span>Event types</span>
                            {resource.canManage && (
                                <Tooltip title='Add new Event Type' placement='top' >
                                    <button
                                        className="block text-xs bg-blue-300 hover:bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-1"
                                        onClick={() => {
                                            handleClickOpenDrawer('CREATE')
                                        }}
                                    >
                                        <FiPlusCircle size={15} />
                                    </button>
                                </Tooltip>
                            )}
                            <Tooltip title={calendarState.selectedEventTypes.length > 0 ? 'Deselect All' : 'Select All'}>
                                <Checkbox
                                    onChange={(event, checked) => handleSelectAll(checked)}
                                    checked={calendarState.selectedEventTypes.length > 0}
                                    size="small"
                                ></Checkbox>
                            </Tooltip>
                        </div>

                        {resourceEventTypes.length !== 0 && (
                            <div className={'w-full relative z-10 border-t border-T-slate-200 lg:hidden'}>
                                <button type={"button"}
                                        className={'w-full flex items-center gap-2 mt-1 px-2 py-1 bg-white rounded-md'}
                                        onClick={() => setToggleContent(!toggleContent)}
                                >
                                    <span>See all types</span>
                                    <TuneIcon sx={{ fontSize: '26px' }} className={"text-white  bg-blue-500 rounded-full p-1"} />
                                </button>
                            </div>
                        )}

                        <Collapse in={isMobile ? toggleContent : true}
                                  timeout="auto"
                                  sx={{ width: '100%',
                                        position: {xs:'absolute', lg:'relative'},
                                        left: '0',
                                        top: {xs:'80px', lg: 0},
                                        zIndex: 5,
                                        margin: {xs:'20px 0 0', lg:'10px 0 0'},
                                        maxHeight: {xs: '80%', lg: 'none'},
                                        overflow: 'auto',
                                  }}
                        >
                            <div className={`background-layer w-full h-full fixed left-0 top-0 z-[1] ${toggleContent ? 'bg-black bg-opacity-20 lg:hidden' : 'hidden'}`}></div>
                            <div className={"bg-white w-full h-full relative z-10 rounded-md p-[6px] lg:p-0"}>
                                {resourceEventTypes.sort((a,b) => a.name.localeCompare(b.name)).map((item) => (
                                    <ul className="flex flew-row justify-between w-full relative z-30 bg-white overflow-hidden mb-1" key={item.id}>
                                        <motion.li
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                type: 'spring',
                                                duration: 0.5,
                                                ease: 'linear',
                                            }}
                                            className={`grow relative p-3 rounded-sm border bg-slate-50 hover:border-blue-500 transition-all ease-in-out border-slate-100 flex items-center justify-between cursor-pointer group w-3/4`}
                                            onClick={() => {
                                                handleClickOpenDrawer('UPDATE', item.id)
                                            }}
                                        >
                                <span
                                    className={`absolute top-0 left-0 h-full w-1 bg-green-200`}
                                    style={{ background: item.color }}
                                ></span>
                                            <p className="text-sm font-medium ">{item.name}</p>
                                            {resource.canManage && (
                                                <button
                                                    className={`block text-xs border border-slate-200 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity`}
                                                >
                                                    <FiEdit2 size={10} />
                                                </button>
                                            )}
                                        </motion.li>
                                        <Checkbox
                                            key={`fileter_${item.id}`}
                                            onChange={(event) => handleFilters(item)}
                                            checked={
                                                calendarState.selectedEventTypes.filter((eventType) => eventType.id === item.id)
                                                    .length > 0
                                            }
                                            size="small"
                                        ></Checkbox>
                                    </ul>
                                ))}
                            </div>

                        </Collapse>
                    </div>

                </div>
                <div className="mt-3 lg:m-0 lg:col-span-5">
                    {eventIsLoading ? null : (
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                            initialView="dayGridMonth"
                            aspectRatio={1.8}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: `dayGridMonth,timeGridWeek,timeGridDay${resource.canManage ? ' newEvent' : ''}`,
                            }}
                            customButtons={{
                                newEvent: {
                                    text: 'Add',
                                    click: () => {
                                        handleOpenDrawerEventCreate('CREATE', {})
                                    },
                                },
                            }}
                            select={(info) => handleOpenDrawerEventCreate('CREATE', info)}
                            selectable={true}
                            locale="en"
                            slotMinTime={'08:00:00'}
                            slotMaxTime={'20:00:00'}
                            businessHours={{
                                daysOfWeek: [1, 2, 3, 4, 5],
                                startTime: '08:00',
                                endTime: '20:00',
                                allDay: false,
                            }}
                            firstDay={1}
                            defaultAllDay={'08:00'}
                            forceEventDuration={true}
                            displayEventEnd={true}
                            eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
                            eventClick={(event) => handleOpenDrawerEventUpdate('UPDATE', event)}
                            events={events.map((event) => {
                                return {
                                    id: event.id,
                                    title: event.title,
                                    start: event.startingDate,
                                    end: event.endingDate,
                                    editable: true,
                                    allDay: false,
                                    display:
                                        calendarState.selectedEventTypes.filter(
                                            (eventType) => eventType.id === event.resourceEventType.id
                                        ).length > 0
                                            ? 'auto'
                                            : 'none',
                                    backgroundColor: event.resourceEventType.color,
                                }
                            })}
                        />
                    )}
                </div>
            </div>
            <DrawerTemporary
                isOpenDrawer={calendarState.isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                method={calendarState.method}
                titleDrawer={`Create new "${resource.name}" event`}
                titleDrawerUpdate={`Update event`}
                content={<ResourceEventTypeForm resourceType={resourceType} />}
                idSelected={calendarState.selectedId}
            />
            <DrawerTemporary
                isOpenDrawer={calendarState.isOpenEventDrawer}
                setIsOpenDrawer={setIsOpenDrawerEvent}
                onDrawerClose={handleDrawerClose}
                method={calendarState.methodEvent}
                titleDrawer={`Create new "${resource.name}" event`}
                titleDrawerUpdate={`Update event`}
                content={
                    <EventForm
                        resourceType={resourceType}
                        resourceEventTypes={resourceEventTypes}
                        from={calendarState.from}
                        to={calendarState.to}
                        resource={resource}
                        apiRef={apiRef}
                    />
                }
                idSelected={calendarState.selectedEventId}
            />
        </>
    )
}
