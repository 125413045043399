import { useState } from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { FiSearch } from 'react-icons/fi'
import { useMobile } from '../../../../hooks/useMobile'
import { SearchModal } from './SearchModal'

export const SearchBar = ({ isFromResource = false }) => {

    const [open, setOpen] = useState(false)

    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci,
    )
    const currentInfrastructure = useSelector(selectCurrentInfrastructure)

    const isMobile = useMobile()

    return (
        <>
            {(currentInfrastructure !== null && currentInfrastructure !== 0) ?
                <div className="flex items-center gap-2 text-slate-500">
                    {!isMobile && 'Search'}
                    <motion.button
                        className="border border-slate-400 bg-slate-transparent h-8 w-8 rounded-full"
                        onClick={() => setOpen(true)}
                        whileTap={{ scale: 0.97 }}
                        whileHover={{ scale: 1.04 }}
                    >
                        <span
                            className="w-full h-full flex justify-center items-center text-slate-400 text-md"><FiSearch /></span>
                    </motion.button>
                </div> : null

            }
            {open &&
                <SearchModal open={open} setOpen={setOpen} />
            }
        </>
    )
}