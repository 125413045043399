import { Navigate, useParams } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import {
    FiChevronUp,
    FiChevronDown,
} from 'react-icons/fi'

import { CustomBreadcrumbs } from '../../../components/breadcrumb/CustomBreadcrumbs'
import { useFetchOneInstallation } from '../hooks/useFetchOneInstallation'
import { Loader } from '../../../components/loader/Loader'
import { useState } from 'react'

import { truncateTextLetter } from '../../../utils/truncateText'
import { isPPARoute } from '../../../utils/variables'
import MapLeaflet from '../../../components/map/MapLeaflet'
import BoxListDescriptif from '../../catalog/components/BoxListDescriptif'
import ItemDescriptif from '../../catalog/components/ItemDescriptif'
import HeadingDescriptif from '../../catalog/components/HeadingDescriptif'
import LeftMenu from '../../catalog/components/menu/LeftMenu'
import Pagination from '../../../components/pagination/Pagination'
import MessageCustom from '../../../components/message/MessageCustom'
import { ButtonPpa } from '../../ppa/components/button/ButtonPpa'
import { AdditionalFields } from '../../descriptiveSheet/AdditionalFields'
import { InstallationsEoscFields } from "../../descriptiveSheet/eosc-fields/installations-eosc-fields";


export const Installation = ({ type }) => {
    const { id, idcatalog } = useParams()

    const [activeLeftMenu, setActiveLeftMenu] = useState(1)
    const [menuSelect, setMenuSelect] = useState(null)

    const [sliceNb, setSliceNb] = useState(12)
    const [openKeyword, setOpenKeyword] = useState(false)
    const [contactActive, setContactActive] = useState(0)

    const { data, isLoading, error } = useFetchOneInstallation(idcatalog, id);

    const currentContact = data?.contact || []

    if (error) {
        return <Navigate to="/notfound" />
    }

    const mylinkData = [
        {
            id: 3,
            title: 'Installation catalog',
            link: '#',
        },
        {
            id: 4,
            title: data?.infraName,
            link: '#',
        },
    ]

    const handleClickMenu = (menuId) => {
        setActiveLeftMenu(menuId)
    }

    const keywordFilter = data?.keywords?.slice(0, sliceNb)

    const handleKeyword = () => {
        if (!openKeyword) {
            const lengthKey = data?.keywords?.length
            setSliceNb(lengthKey)

            setOpenKeyword(true)
        } else {
            setSliceNb(12)
            setOpenKeyword(false)
        }
    }

    if (isLoading) {
        return <Loader content="Load descriptive page" color="orange" />
    }


    return (
        <AnimatePresence>
            <motion.article
                id="section"
                className={`min-h-screen w-full flex lg:flex-row flex-col items-stretch lg:gap-2 bg-slate-100`}

                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}

                transition={{ type: 'spring', duration: .5, ease: 'easeIn' }}
            >

                { /*Left Box*/}
                <div className="lg:w-1/5 w-full grow py-10 flex flex-wrap items-stretch justify-center gap-4 bg-white shadow-sm">
                    <div className={`lg:w-9/12 w-11/12 flex flex-col gap-2`}>
                        {/* Title */}
                        <h2 className={`lg:text-3xl text-xl font-medium fontfamilly`}>{data.infraName}</h2>
                        {/* Tagline */}
                        {!data.tagLine ?
                            ''
                            :
                            <p className={`text-sm text-slate-500`}>{data.tagLine}</p>
                        }
                        {/* BreadCrumb */}
                        <div className="order-first border-b border-slate-200 lg:h-12 h-16">
                            <CustomBreadcrumbs linkData={mylinkData} />
                        </div>

                        {/* Image preview */}
                        <motion.div
                            className=" w-full lg:h-[500px] h-[550px] overflow-hidden my-4 border border-slate-200 rounded-xl shadow-sm shadow-slate-200"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}

                            transition={{ type: 'spring', duration: .5, ease: 'easeIn' }}
                        >
                            <figure className="lg:h-[500px] h-[550px]">
                                <img
                                    src={data.mainImage ? `${process.env.REACT_APP_API_URL}${data.mainImage}` : `/uploads/update.jpg`}
                                    alt={`${data.name}`}
                                    className="w-full h-full rounded-lg object-cover" />
                            </figure>
                        </motion.div>

                        {/* description */}
                        {data.description &&
                            <div className={`flex flex-col gap-4`}>
                                <HeadingDescriptif title="Description of our installation" />
                                <div className="text-slate-700"
                                     dangerouslySetInnerHTML={{ __html: data.description }} />
                            </div>
                        }
                    </div>
                    <div className={`lg:w-9/12 w-11/12 flex flex-col`}>
                        <InstallationsEoscFields data={data} />

                        {data?.additionalFields.length > 0 && (
                            <AdditionalFields additionalFields={data?.additionalFields}
                            />
                        )}
                    </div>
                </div>

                {/* Right Box */}
                <div className="lg:w-1/3 w-full lg:p-4 flex gap-8 rounded-2xl shadow-sm shadow-slate-300 relative">
                    <div
                        className="lg:fixed right-0 top-0 lg:w-1/3 w-full flex flex-col gap-0 overflow-hidden shadow-sm">
                        <LeftMenu
                            type="installation"
                            activeLeftMenu={activeLeftMenu}
                            menuSelect={menuSelect}
                            setMenuSelect={setMenuSelect}
                            handler={handleClickMenu}
                        />

                        <div
                            className={`flex flex-col gap-4 w-full grow pb-10 relative lg:h-screen min-h-screen bg-white pt-10`}>
                            {activeLeftMenu === 1 &&
                                <div
                                    className={`lg-w-9/12 flex flex-col p-8 items-start gap-8 lg:h-screen w-full lg:overflow-y-auto`}>
                                    {/* General Infos */}
                                    <div className="flex flex-col gap-4 w-full">
                                        <p className="text-md font-semibold text-slate-700 mb-2 flex items-center gap-2">General</p>

                                        <div className="flex items-start gap-4">
                                            {data.logo &&
                                                <div className=" h-12 w-32">
                                                    <figure className=" h-12 w-32">
                                                        <img
                                                            src={`${process.env.REACT_APP_API_URL}${data.logo}`}
                                                            alt={`logo du ${data.title}`}
                                                            className="w-full h-full object-contain" />
                                                    </figure>
                                                </div>
                                            }
                                            <div
                                                className={`flex items-center gap-2 h-12  px-4 py-2 border border-slate-200 grow rounded-md`}>
                                                <p className={`text-sm text-slate-500 font-medium fontfamilly min-w-1/5`}>Provider</p>
                                                <p title={data.provider} className={`text-sm text-slate-700 w-1/5 grow`}
                                                >{data.provider ? truncateTextLetter(data.provider, 55) : <span
                                                    className="text-xs border border-slate-200 bg-slate-50 text-slate-500 px-2">N/A</span>}
                                                </p>
                                            </div>
                                        </div>
                                        <BoxListDescriptif>
                                            <ItemDescriptif title={data.institution} dataTitle="Institution"
                                                            dataInfo={data.institution} />
                                            <ItemDescriptif title={data.website} dataTitle="Website"
                                                            dataInfo={truncateTextLetter(data.website, 40)}
                                                            path={data.website} />
                                            <ItemDescriptif dataTitle="Country" dataInfo={data.location?.country} />
                                            <ItemDescriptif dataTitle="Region" dataInfo={data.location?.region} />
                                            <ItemDescriptif dataTitle="City" dataInfo={data.location?.city} />
                                            <ItemDescriptif dataTitle="Street" dataInfo={data.location?.street} />
                                        </BoxListDescriptif>
                                    </div>

                                    {/*ALL keywords*/}
                                    {!data?.keywords || data.keywords?.length === 0 ?
                                        ''
                                        :
                                        <div className="flex flex-col gap-4 w-full">
                                            <p className="text-md font-semibold text-slate-700 mb-2 flex items-center gap-2">Keywords</p>
                                            <div
                                                className={`flex items-center justify-center w-full min-h-20 bg-none border-dashed border border-slate-300 rounded-xl relative`}>
                                                <ul className="flex flex-wrap gap-2 p-4 w-full">
                                                    {keywordFilter?.map((item) =>
                                                        <li key={item.id}
                                                            className="flex items-center justify-center p-3 h-[20px] bg-slate-100 rounded-2xl text-xs text-gray-500">{item.value}</li>,
                                                    )}
                                                </ul>
                                                {data?.keywords?.length > 12 &&
                                                    <button onClick={handleKeyword}
                                                            className="border border-solid border-blue-300 bg-blue-50 text-blue-500 text-lg font-bold flex items-center justify-center rounded-full absolute -bottom-2">
                                                        {openKeyword ? <FiChevronUp /> : <FiChevronDown />}
                                                    </button>
                                                }
                                            </div>

                                        </div>
                                    }
                                    {data?.contact?.length > 0 &&
                                        <div className="flex flex-col gap-4 w-full">
                                            <div className="flex justify-between">
                                                <p className="text-md font-semibold text-slate-700 mb-2 flex items-center gap-2">Contact</p>
                                                <Pagination dataLength={data?.contact?.length}
                                                            setContactActive={setContactActive} />
                                            </div>
                                            <BoxListDescriptif>
                                                <ItemDescriptif dataTitle="Lastname"
                                                                dataInfo={currentContact[contactActive]?.lastname} />
                                                <ItemDescriptif dataTitle="Firstname"
                                                                dataInfo={currentContact[contactActive]?.firstname} />
                                                <ItemDescriptif dataTitle="Position"
                                                                dataInfo={currentContact[contactActive]?.position} />
                                                <ItemDescriptif dataTitle="Organisation"
                                                                dataInfo={currentContact[contactActive]?.organisation} />
                                                <ItemDescriptif dataTitle="Phone"
                                                                dataInfo={currentContact[contactActive]?.phone} />
                                                <ItemDescriptif dataTitle="Email"
                                                                dataInfo={currentContact[contactActive]?.email}
                                                                email={currentContact?.email} />
                                            </BoxListDescriptif>
                                        </div>
                                    }

                                    <hr className="w-full" />
                                    {/* Map */}
                                    <div
                                        className={`lg:min-h-80 h-96 relative border border-slate-200 shadow-sm shadow-slate-200 bg-slate-50 w-full rounded-2xl overflow-hidden`}>
                                        <MapLeaflet coordonates={[data]} catalogType={type} />
                                    </div>

                                </div>
                            }

                            {activeLeftMenu === 2 && (
                                (data.scientificDomain?.length === 0 && data.scientificSubDomain?.length === 0) ? (
                                    <MessageCustom />
                                ) : (
                                    <div
                                        className={`lg-w-9/12 grid lg:grid-cols-2 grid-cols-1 p-8 gap-4 w-full lg:overflow-y-auto`}>
                                        <ul className="flex flex-col gap-2 w-full">
                                            <li className="flex flex-col w-full border rounded-xl overflow-hidden">
                                                <div
                                                    className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start px-4">
                                                    <p className="text-sm font-semibold text-slate-700">Scientific
                                                        Domains</p>
                                                </div>
                                                {data.scientificDomain?.length > 0 ?
                                                    <BoxListDescriptif border={false}>
                                                        {data.scientificDomain.map((item, index) =>
                                                            <ItemDescriptif key={index}
                                                                            title={item}
                                                                            dataInfo={truncateTextLetter(item, 52)}

                                                            />,
                                                        )}
                                                    </BoxListDescriptif>
                                                    :
                                                    <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                        <span className="text-sm text-slate-500">Not Set</span>
                                                    </li>
                                                }
                                            </li>
                                        </ul>

                                        <ul className="flex flex-col gap-4 w-full">
                                            <li className="flex flex-col w-full border rounded-xl overflow-hidden">
                                                <div
                                                    className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start px-4">
                                                    <p className="text-sm font-semibold text-slate-700">Scientific
                                                        SubDomains</p>
                                                </div>
                                                {data.scientificSubDomain?.length > 0 ?
                                                    <BoxListDescriptif border={false}>
                                                        {data.scientificSubDomain.map((item, index) =>
                                                            <ItemDescriptif key={index}
                                                                            title={item}
                                                                            dataInfo={truncateTextLetter(item, 32)} />,
                                                        )}
                                                    </BoxListDescriptif>
                                                    :
                                                    <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                        <span className="text-sm text-slate-500">Not Set</span>
                                                    </li>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                )
                            )}


                            {activeLeftMenu === 3 && (
                                (data.listService?.length === 0) ? (
                                        <MessageCustom content="Services Not Specified" />
                                    )
                                    :
                                    (
                                        <div className="lg-w-9/12 w-12/11 flex flex-col p-8 items-start gap-8 h-full max-h-[90vh] lg:max-h-none">
                                            <div className="flex flex-col w-full border rounded-xl overflow-y-auto relative ">

                                                <div
                                                    className="bg-slate-100 h-10 text-sm text-slate-700 flex items-center justify-start p-4 sticky top-0 left-0">
                                                    <p className="text-sm font-semibold text-slate-700">List of Services
                                                        (<span
                                                            className="text-slate-500 text-sm">{data.listService?.length}</span>)
                                                    </p>
                                                </div>
                                                {data.listService?.length > 0 ?
                                                    <BoxListDescriptif border={false}>
                                                        {data.listService.map(item =>
                                                            <ItemDescriptif key={item.id}
                                                                            title={item.name}
                                                                            dataInfo={truncateTextLetter(item.name, 80)}
                                                                            path={item.id} id={id} />,
                                                        )}
                                                    </BoxListDescriptif>
                                                    :
                                                    <li className={`border border-slate-200 rounded-2xl h-24 flex items-center justify-center`}>
                                                        <span className="text-sm text-slate-500">Not Set</span>
                                                    </li>
                                                }
                                            </div>
                                        </div>
                                    )

                            )

                            }
                            {isPPARoute &&
                                <ButtonPpa catalog="installations" />
                            }
                        </div>
                    </div>
                </div>

            </motion.article>
        </AnimatePresence>
    )
}