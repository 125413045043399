import {createSelector} from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import {useFetchResourceFromResourceTypeId} from '../hooks/useFetchResourceFromResourceTypeId'
import {AnimatePresence} from 'framer-motion'

import {useEffect, useState} from 'react'
import {useTreeViewApiRef} from '@mui/x-tree-view'
import {useFetchResourceById} from '../hooks/useFetchResourceById'
import {useSearchParams} from 'react-router-dom'
import {DrawerTemporary} from "../../../components/drawer/DrawerTemporary";
import {ResourceForm} from "./form/ResourceForm";
import {DuplicateResourceForm} from "./form/DuplicateResourceForm";
import {ResourceTree} from "./ResourceTree";
import ResourceService from '../services/Resource'
import { displayMessage } from '../../../redux/alertAction'
import { MoveResourceForm } from './form/MoveResourceForm'
import { LuPanelLeftClose} from "react-icons/lu";
import * as React from "react";
import { Loader } from '../../../components/loader/Loader'

export const ResourceSidebar = ({
                                    resourceType,
                                    setResource,
                                    setIsLoadingResource,
                                }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )

    const [isLoading, setIsLoading] = useState(true)

    const user = useSelector(selectUser)
    const dispatch = useDispatch()


    const [incrementMainTree, setIncrementMainTree] = useState(0);

    const {listResources} = useFetchResourceFromResourceTypeId(
        resourceType?.id,
        user.token,
        setIsLoading,
        incrementMainTree
    )

    const [treeItems, setTreeItems] = useState([])

    const apiRef = useTreeViewApiRef();
    const apiRefResourceForm = useTreeViewApiRef();
    const apiRefSource = useTreeViewApiRef();
    const apiRefDestination = useTreeViewApiRef();
    const apiRefMoveDestination = useTreeViewApiRef();

    const [curResourceId, setCurResourceId] = useState(null)

    const [increment, setIncrement] = useState(0)

    const {resource} = useFetchResourceById(
        curResourceId,
        resourceType.id,
        user.token,
        setIsLoadingResource,
        increment
    )

    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedItems, setSelectedItems] = useState([]);
    const [expandedItems, setExpandedItems] = useState([]);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [method, setMethod] = useState( null );
    const [titleDrawer, setTitleDrawer] = useState( '');
    const [idSelected, setIdSelected] = useState(null);
    const [drawerForm, setDrawerForm] = useState(null);

    const [rid, setRid] = useState(searchParams.get('rid'));

    const handleOnItemLabelChange = async (itemId, newLabel) => {

        try {
            const requestData = {
                name: newLabel,
                parent: null !== resource && null !== resource.parent ? resource.parent['@id'] : null,
                resourceType: resourceType ? resourceType['@id'] : null,
            }
            const resourceService = new ResourceService()
            await resourceService.putResource(requestData, itemId, user.token).then(() => {
                setIncrement(increment + 1);
                dispatch(displayMessage('Success', 'Element updated', 'success'))
            })
        } catch (error) {
            dispatch(displayMessage(error?.data?.title, error?.data?.detail, 'error'))
        }

    }

    const handleDelete = async () => {
        try {
            const resourceService = new ResourceService()
            await resourceService.deleteResource(rid, user.token).then((result) => {
                setRid(null);
            })

            handleDrawerClose();

            dispatch(displayMessage('Success', 'Element deleted', 'success'))

        } catch (error) {
            dispatch(displayMessage(error?.data?.title, error?.data?.detail, 'error'))
        }
    }
    const handleCreateResourceClick = (resourceSelected) => {
        setMethod('CREATE');
        setTitleDrawer('Create a new resource');
        setIdSelected(null);
        setDrawerForm(
            <ResourceForm
                resourceType={resourceType}
                apiRef={apiRefResourceForm}
                resourceSelected={resourceSelected}
            />
        );
        setIsOpenDrawer(true);
    }


    const handleDuplicateResourceClick = (resourceSelected) => {
        setMethod('CREATE');
        setTitleDrawer('Duplicate resource');
        setIdSelected(null);
        setDrawerForm(
            <DuplicateResourceForm
                resourceType={resourceType}
                apiRefSource={apiRefSource}
                apiRefDestination={apiRefDestination}
                resourceSelected={resourceSelected}
                setRid={setRid}
            />
        );
        setIsOpenDrawer(true);

    }

    const handleMoveResourceClick = (resourceSelected) => {
        setMethod('CREATE');
        setTitleDrawer('Move resource');
        setIdSelected(null);
        setDrawerForm(
            <MoveResourceForm
                resourceType={resourceType}
                resourceSelected={resourceSelected}
                apiRefDestination={apiRefMoveDestination}
            />
        )
        setIsOpenDrawer(true);

    }

    const handleDrawerClose = () => {
        // Should reload JS tree
        setIncrementMainTree(incrementMainTree + 1);
        setIsOpenDrawer(false);
    }

    useEffect(
        () => {
            setSelectedItems([rid])
            setCurResourceId(rid)
        },
        [rid]
    )

    useEffect(
        () => {
            setResource(resource)
        },
        [resource]
    )

    useEffect(() => {
        setTreeItems(listResources['hydra:member'] ?? [])
        if (null !== curResourceId) {
            const parentList = getParentList(curResourceId)
            setExpandedItems(parentList)
        }
    }, [listResources])

    const getParentList = (rId) => {
        let result = []
        const r = apiRef?.current?.getItem(rId)
        if (undefined !== r && null !== r) {
            result.push(rId)
            if (r.parent) {
                const splittedValue = r.parent.split('/')
                const parentId = splittedValue.pop()
                const curParent = apiRef?.current?.getItem(parentId)
                result.push(parentId)
                if (curParent && curParent.parent) {
                    const splittedValueParent = r.parent.split('/')
                    const parentParentId = splittedValueParent.pop()
                    const parentResult = getParentList(parentParentId)
                    parentResult.map((pr) => {
                        return result.push(pr)
                    })
                }
            }
            return result
        }
        return []
    }

    // Mobile View - Toggle Left Panel
    const [toggleResourcePanel, setToggleResourcePanel] = useState(true);


    return (
        <>
            <div className={`background-layer w-full h-full fixed left-0 top-0 ${toggleResourcePanel ? 'bg-black bg-opacity-20 lg:hidden' : 'hidden'}`}></div>
            <div className={`bg-[#ffffff] order-first drop-shadow-md
                                absolute z-10 lg:relative
                                h-full lg:h-auto w-10/12 lg:w-1/4
                                pb-10 lg:py-0
                                ${toggleResourcePanel ? 'left-0' : 'left-[-82vw] lg:left-0'}
                                transition-all duration-500`
            }>
                <button type="button"
                        className="bg-white rounded-r-md border border-slate-200 border-l-0
                                    flex py-2 pl-0 pr-2
                                    absolute right-[-25px] top-2 z-20
                                    lg:hidden"
                        onClick={() => setToggleResourcePanel(!toggleResourcePanel)}
                >
                    <LuPanelLeftClose />
                </button>

                <AnimatePresence>
                {isLoading ? (
                    <Loader content="Loading resources" />
                ) : (
                    <ResourceTree
                        rid={rid}
                        setRid={setRid}
                        resourceType={resourceType}
                        setIsLoadingResource={setIsLoadingResource}
                        setResource={setResource}
                        apiRef={apiRef}
                        isMainTree={true}
                        handleCreateResourceClick={handleCreateResourceClick}
                        handleDuplicateResourceClick={handleDuplicateResourceClick}
                        handleMoveResourceClick={handleMoveResourceClick}
                        handleOnItemLabelChange={handleOnItemLabelChange}
                        handleDelete={handleDelete}
                    />
                )}
                </AnimatePresence>
            </div>

            <DrawerTemporary
                isOpenDrawer={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                typeForm={'resourceForm'}
                method={method}
                titleDrawer={titleDrawer}
                content={drawerForm}
                idSelected={idSelected}
            />
        </>

    )
}
