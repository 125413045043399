import {useEffect, useState} from 'react';
import {createSelector} from "reselect";
import {useSelector} from "react-redux";

const useAuth = () => {
    const [auth, setAuth] = useState(false);

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );

    const user = useSelector(selectUser);

    useEffect(() => {
        if (user) {
            setAuth(true)
        } else {
            setAuth(false)
        }
    }, [user, auth]);

    return { auth }
};

export default useAuth;
