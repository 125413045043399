import React, { Fragment } from 'react'

// Design
import { Grid } from '@mui/material'

// Components
import { Tables } from '../../../components/tables/Tables'
import ItemDescriptif from '../../catalog/components/ItemDescriptif'
import BoxListDescriptif from "../../catalog/components/BoxListDescriptif";
import {CollapsibleFields} from "../collapsible-fields";

// Utils
import {dataToArray} from "../../../utils/dataToArray";



export const InstallationsEoscFields = ({ data }) => {

    const eoscNetworks = [...data.networks];
    const eoscParticipatingCountries = [...data.participatingCountries];

    return (
        <Grid container columnSpacing={{ xs: 2, lg: 4 }} sx={{ margin: '20px 0 0' }}>

            {(data.multimedia && data.multimedia.length !== 0) && (
                <CollapsibleFields title="Marketing Information Multimedia"
                                   content={<Tables tab={dataToArray(data.multimedia)} />}
                />
            )}

            <CollapsibleFields
                title="Dependencies Information"
                content={
                    <BoxListDescriptif widthFull={false}>
                        <ItemDescriptif dataTitle="Networks" dataInfo={
                            eoscNetworks.map((network, index) => (
                                <Fragment key={network.optionId ? network.optionId : index}>
                                    {network.value ? (
                                        <span>{network.value}</span>
                                    ) : (
                                        <span className="text-xs border border-slate-200 bg-slate-50 text-slate-500 px-2">N/A</span>
                                    )}
                                    <span className={'last-of-type:hidden mr-2'}>,</span>
                                </Fragment>
                            ))
                        }/>

                        <ItemDescriptif dataTitle="Participating Countries" dataInfo={
                            eoscParticipatingCountries.map((country, index) => (
                                <Fragment key={country.optionId ? country.optionId : index}>
                                    {country.value ? (
                                        <span>{country.value}</span>
                                    ) : (
                                        <span className="text-xs border border-slate-200 bg-slate-50 text-slate-500 px-2">N/A</span>
                                    )}
                                    <span className={'last-of-type:hidden mr-2'}>,</span>
                                </Fragment>
                            ))
                        }/>

                        <ItemDescriptif dataTitle="Catalogue" dataInfo={data.catalogue}/>
                        <ItemDescriptif dataTitle="Affiliations" dataInfo={data.affiliations} />
                    </BoxListDescriptif>
                }
            />

        </Grid>
    )
}