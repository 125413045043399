import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {login} from "./user/authentificationReducer";
import {displayMessage} from "./alertReducer";
import {infrastructureCatalogReducer} from "./infrastructureCatalogReducer";
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import serviceCatalogReducer from "./serviceCatalogReducer";
import {changeInfrastructure} from "./user/changeInfrastructureReducer";
import {getSelectedPermissions} from "./role/roleReducer";
import {resetStore} from "./resetStoreAction";
import {newSelectName} from "./selects/newNameSelectReducer";
import { catalogReducers } from "./catalog/catalogReducers";
import {filterReducers} from "./filter/filterReducers";
import {networkConfigReducers} from "./networkConfig/networkConfigReducers";
import {platformReducers} from "./platform/platformReducers";
import {ppaSlice} from "../features/ppa/store/ppaSlice";
import {reusableFormSlice} from "../components/reusableform/store/reusableFormSlice";
import {selectConfigByNetworkReducers} from "./selects/selectConfigByNetworkReducers";
import { selectOptionReducers } from "./selectoption/selectOptionReducers";
import {projectSlice} from "./project/projectSlice";

const reducers = combineReducers({
    login: (state, action) => login(state, action),
    displayMessage: (state, action) => displayMessage(state, action),
    infrastructureRoute: (state, action) => infrastructureCatalogReducer(state, action),
    serviceCatalogFilters: (state, action) => serviceCatalogReducer(state, action),
    currentInfrastructure: (state, action) => changeInfrastructure(state, action),
    selectNewName: (state, action) => newSelectName(state, action),
    selectedPermissions: (state, action) => getSelectedPermissions(state, action),
    catalogReducers:(state, action) => catalogReducers(state, action),
    filterReducers:(state, action) => filterReducers(state, action),
    networkConfigReducers:(state, action) => networkConfigReducers(state, action),
    platformReducers:(state, action) => platformReducers(state,action),
    selectConfigByNetworkReducers:(state, action) => selectConfigByNetworkReducers(state, action),
    selectOptionReducers:(state, action) => selectOptionReducers(state, action),
    resetStore: (state, action) => resetStore(state, action),

    // PPA Reducer
    ppa: ppaSlice.reducer,

    // Generic Form Reducer
    reusableForm: reusableFormSlice.reducer,

    // Project Reducer
    project: projectSlice.reducer,
});

const rootReducer = (state, action) => {
    if (action.type === "RESET_STORE") {
        console.log(action);
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root');

        return reducers(undefined, action);
    }
    return reducers(state, action);
}

const rootPersistConfig = {
    key: 'root',
    storage: storage,

    // Avoid persistence
    blacklist: ['ppa', 'reusableForm', 'project']
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    // eslint-disable-next-line no-undef
    // devTools: process.env.NODE_ENV !== 'production',
    devTools: process.env.REACT_APP_ENV === 'dev',
})