import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import dayjs from "dayjs";

// Components
import {ProjectsTable} from "../projects-table/projects-table";
import {ServiceTypeCell} from "../cells/service-type-cell/service-type-cell";
import {StepStatusCell} from "../cells/step-status-cell/step-status-cell";
import {StatusCell} from "../cells/status-cell/status-cell";
import {ProjectsFurtherActions} from "../projects-further-actions/projects-further-actions";
import {Loader} from "../../../../components/loader/Loader";

// Design
import {Box} from "@mui/material";
import {FiEdit, FiTrash} from "react-icons/fi";
import {FaClipboardList, FaRegFilePdf} from "react-icons/fa";
import {MdOutlineUpdate} from "react-icons/md";
import {FaBookOpenReader} from "react-icons/fa6";
import {AnimatePresence, motion} from "framer-motion";

// Redux
import {useSelector} from "react-redux";
import {projectSlice} from "../../../../redux/project/projectSlice";

// Utils
import {dataToArray} from "../../../../utils/dataToArray";
import {ProjectsCardTooltip} from "../projects-card-tooltip/projects-card-tooltip";
import {IoInformationCircleOutline} from "react-icons/io5";

// .Env
const url = process.env.REACT_APP_API_URL;

const PROJECTS_VISIBLE_FIELDS = ['status', 'listSteps', 'acronym', 'listServiceTypes', 'source', 'creator', 'validator', 'updatedAt', 'actions'];


export const ProjectsList = ({installationProjectsPpa}) => {

    const { selectInfrastructureProjectsList } = projectSlice.selectors;
    const projectsList = useSelector(selectInfrastructureProjectsList);
    // console.dir(projectsList);

    const [isLoadingProjectsList, setIsLoadingProjectsList] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoadingProjectsList(false);
        }, 1500);
    }, [projectsList]);

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    });


    const adaptedInstallationProjects = {
        rows : projectsList,
        columns : [
            {
                field: 'status',
                headerName: 'Status',
                flex: 2,
                minWidth: 150,
                renderCell: (data) => {
                    return <StatusCell data={data.value}/>
                }
            },
            {
                field: 'listSteps',
                headerName: 'Step Status',
                flex: 2,
                minWidth: 150,
                sortable: false,
                valueFormatter: (data) => {
                    if (data?.value === null) { return 'N/A'; }

                    const objectGroupedByStatus = Object.groupBy(dataToArray(data?.value), ({status}) => status);

                    let arrayByStatus = [];
                    for (const [key, value] of Object.entries(objectGroupedByStatus)) {
                        arrayByStatus.push({status: key, steps: value.length});
                    }

                    const combinedStatus = arrayByStatus.reduce((obj, item) => ({
                        ...obj,
                        [item.status]: ': ' + item.steps + '\n'
                    }), {});

                    return `${Object.entries(combinedStatus).toString().replaceAll(',', '')}`;
                },
                renderCell: (data) => {
                    return <StepStatusCell data={data.value}/>
                }
            },
            {
                field: 'acronym',
                headerName: 'Acronym',
                flex: 1,
                minWidth: 150,
                valueGetter: (data) => `${data.value} - ${data?.row?.name ?? ''}`,
                renderCell: (data) => {
                    return (
                        data?.row?.acronym ? (
                            <>
                                <span>{data?.row?.acronym}</span>
                                <ProjectsCardTooltip title={data?.row?.name && data?.row?.name}>
                                    <span className={"ml-1"}>
                                        <IoInformationCircleOutline className={"mt-[1.6px] text-blue-500 text-xl"}/>
                                    </span>
                                </ProjectsCardTooltip>
                            </>
                        ) : (
                            <span>{data?.row?.name}</span>
                        )
                    )
                }
            },
            {
                field: 'listServiceTypes',
                headerName: 'Service Type',
                flex: 3,
                minWidth: 150,
                valueFormatter: (data) => data?.value.map(item => (item.name)).join(', '),
                renderCell: (data) => {
                    return <ServiceTypeCell data={data.value}/>
                }
            },
            {
                field: 'source',
                headerName: 'Source',
                flex: 3,
                minWidth: 150,
                valueGetter: (data) => {

                    if (data?.row?.isFree) {
                        return `Free`;
                    }
                    if (data?.row?.ticket !== null) {
                        return `Ticket`;
                    }
                    if (data?.row?.ppa && installationProjectsPpa.length !== 0) {

                        const ppaId = data?.row?.ppa?.id;
                        const networkName = installationProjectsPpa?.find((found) => found.id === ppaId)?.networkPpa?.network?.name
                        const networkTypeName = installationProjectsPpa?.find((found) => found.id === ppaId)?.networkPpa?.name

                        return `${networkName} - ${networkTypeName}`;

                    }

                },
            },
            {
                field: 'creator',
                headerName: 'Project Leader',
                flex: 2,
                minWidth: 150,
                valueGetter: (creator) => creator?.value?.user?.fullName,
            },
            {
                field: 'validator',
                headerName: 'Validator',
                flex: 2,
                minWidth: 150,
                valueGetter: (validator) => validator?.value?.user?.fullName,
            },
            {
                field: 'updatedAt',
                headerName: 'Last Update',
                flex: 2,
                minWidth: 150,
                valueFormatter: (data) => {
                    if (data?.value === null) {
                        return 'N/A';
                    }
                    return dayjs(data?.value).format(' DD/MM/YYYY HH:mm');
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 2,
                // minWidth: 150,
                minWidth: 80,
                sortable: false,
                valueGetter: (data) => `${data?.row?.ppa?.pdfName ? (url + data?.row?.ppa?.pdfName) : ''}`,
                renderCell: (installation) => {
                    return (
                        <div key={installation?.id} className={'w-full flex items-center justify-start'}>
                            <Link to={url + installation?.row?.ppa?.pdfName}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={'View PDF'}
                            >
                                <button
                                    className={`bg-blue-500 p-2 rounded-full text-white mr-1 cursor-pointer ${installation?.row?.ppa?.pdfName ? 'visible' : 'hidden'}`}>
                                    <FaRegFilePdf/>
                                </button>
                            </Link>

                            <ProjectsFurtherActions
                                title="Further actions available soon"
                                content={
                                    <>
                                        <div>
                                            <button
                                                className="bg-blue-500 p-2 rounded-full text-white cursor-pointer"
                                                title={'Read'}
                                                onClick={() => {
                                                }}
                                            >
                                                <FaBookOpenReader/>
                                            </button>
                                            <span>Read</span>
                                        </div>
                                        <div>
                                            <button
                                                className="bg-purple-800 p-[6px] rounded-full text-white cursor-pointer"
                                                title={'Update project status'}
                                                onClick={() => {
                                                }}
                                            >
                                                <MdOutlineUpdate size={18}/>
                                            </button>
                                            <span>Update project status</span>
                                        </div>
                                        <div>
                                            <button
                                                className="bg-green-500 p-2 rounded-full text-white cursor-pointer"
                                                title={'Update project'}
                                                onClick={() => {
                                                }}
                                            >
                                                <FiEdit/>
                                            </button>
                                            <span>Update project</span>
                                        </div>
                                        <div>
                                            <button
                                                className="bg-red-500 p-2 rounded-full text-white cursor-pointer"
                                                title={'Delete'}
                                                onClick={() => {
                                                }}
                                            >
                                                <FiTrash/>
                                            </button>
                                            <span>Delete</span>
                                        </div>
                                    </>
                                }
                            />

                        </div>
                    )
                },
            },
        ],
    };

    const [initialSorting, setInitialSorting] = useState([
        {
            field: 'updatedAt',
            sort: 'desc'
        }
    ]);

    const exportFileName = 'Projects list';


    return (
        <AnimatePresence>

            <motion.div className={"projects-list w-full flex flex-col max-h-[80vh] mb-4"}
                        initial={{opacity: 0, translateY: 30}}
                        animate={{opacity: 1, translateY: 0}}
                        transition={{type: "spring", duration: .6, delay: 1, ease: "linear"}}
            >

                <div className={"w-full flex justify-between items-end mb-4 heading border-0 border-t-[1px] border-t-zinc-300 pt-4"}>
                    <div className={"px-3 py-2"}>
                        <h3 className="flex font-bold text-left max-w-[500px] text-zinc-700">
                            <FaClipboardList size={26} className={"mr-2"}/><span>Projects</span>
                        </h3>
                    </div>
                </div>

                <Box sx={{ width: '100%', height: '70vh' }}>

                    {isLoadingProjectsList ? (
                        <Loader content="Loading Projects List" hfull />
                    ) : (
                        <motion.div className={"h-full"}
                                    initial={{opacity: 0, translateY: 30}}
                                    animate={{opacity: 1, translateY: 0}}
                                    transition={{type: "spring", duration: 1, delay: .6, ease: "linear"}}
                        >
                            <ProjectsTable adaptedProjectsData={adaptedInstallationProjects}
                                           visibleFields={PROJECTS_VISIBLE_FIELDS}
                                           paginationModel={paginationModel}
                                           setPaginationModel={setPaginationModel}
                                           initialSorting={initialSorting}
                                           setInitialSorting={setInitialSorting}
                                           exportFileName={exportFileName}

                                           dataLoading={isLoadingProjectsList}
                            />
                        </motion.div>
                    )}

                </Box>

            </motion.div>

        </AnimatePresence>
    )
}
