import {FiEdit, FiEdit2, FiEye, FiEyeOff, FiLock, FiUnlock} from "react-icons/fi";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import FormTabs from "../../tabs/components/forms/FormTabs";
import CreateForm from "../../forms/component/form/CreateForm";
import {Button} from "../../tabs/components/button/Button";
import {DrawerTemporary} from "../../../components/drawer/DrawerTemporary";
import OneForm from "../../forms/component/OneForm";

export const CompCustom = ({ id, filters, networkId, type, setLengthofTabFilters, fetchData, data, isLoading}) => {

    const [submitting, setSubmitting] = useState(false);

    const [myTabs, setMyTabs] = useState([]);
    const [idFormActive, setIdFormActive] = useState(null);

    const [isOpenTabs, setIsOpenTabs] = useState(false);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const [openEditBox, setOpenEditBox] = useState(null);
    const [openFormFormsId, setOpenFormFormsId] = useState(null);

    const [backCover, setBackCover] = useState("#2d7ef5");

    useEffect(() => {

        let dataCustom;

        if (type === "ppa") {
            dataCustom = data?.listTabs;
        }
        if (type === "installation") {
            dataCustom = data?.networkInfrastructureDescription?.listTabs;
        }
        if (type === "service") {
            dataCustom = data?.networkServiceDescription?.listTabs;
        }
        if (type === "resource") {
            dataCustom = data?.listTabs;
        }

        setMyTabs(dataCustom);
        setSubmitting(false);

    }, [data, myTabs]);

    useEffect(() => {
        if (!submitting) return;
        fetchData();
    }, [submitting]);

    const handleOpenEditBox = (itemId) => {
        setOpenEditBox(openEditBox === itemId ? null : itemId);
    };

    const handleOpenDrawer = (idForm) => {
        if (idForm) {
            setIdFormActive(idForm);
        }
        setIsOpenDrawer(true);
    };

    const onDrawerClose = () => {
        setIsOpenDrawer(false);
        setSubmitting(false);
    };

    // Sort data by position
    const sortTabs = myTabs?.sort((a, b) => a.position - b.position);

    const allFiltersUnchecked = filters?.every(filter => !filter.checked);

    // Filter mySortTabs
    const filteredTabs = allFiltersUnchecked
        ? sortTabs
        : sortTabs?.filter(tab => {
            if (filters.find(filter => filter.id === 1 && filter.checked) && tab.isHidden) return true;
            if (filters.find(filter => filter.id === 2 && filter.checked) && !tab.isHidden) return true;
            return false;
        });

    useEffect(() => {
        setLengthofTabFilters(filteredTabs?.length);
    }, [filteredTabs]);

    const idInfra = data?.networkInfrastructureDescription?.id
    const idServ = data?.networkServiceDescription?.id
    const idPPA = data?.id
    const idResourceType = type === "resource" ? data?.id : null;

    // console.dir(data)

    return (
        <>
            {!isLoading &&
                <motion.ul
                    className="flex items-stretch gap-10 pr-32 pb-10"
                    initial={{opacity: 1, translateY: 25}}
                    animate={{opacity: 1, translateY: 0}}
                    exit={{opacity: 0, translateY: 25}}
                    transition={{type: 'spring', duration: .5, ease: 'easeOut'}}
                >
                    { filteredTabs?.map((item) =>
                        <li key={`tab-${item.id}`}
                            className={`w-72 select-none relative rounded-xl`}
                        >
                            {openEditBox === item.id ?
                                <FormTabs
                                    setIsOpenTabs={setIsOpenTabs}
                                    setOpenEditBox={setOpenEditBox}
                                    setSubmitting={setSubmitting}
                                    method="PUT"
                                    type={type}
                                    myTabs={myTabs}
                                    idTab={item.id}
                                    id={id}
                                    networkInfraDescriptionId={idInfra}
                                    networkSeviceDescriptionId={idServ}
                                    networkPPAId={idPPA}

                                    idResourceType={idResourceType}
                                />
                                :
                                <motion.div
                                    className={`bg-zinc-50 border border-solid border-slate-300 rounded-xl shadow-sm text-slate-500 font-black w-full h-auto p-4 flex flex-col gap-4`}
                                    initial={{opacity: 0, translateY: 25}}
                                    animate={{opacity: 1, translateY: 0}}
                                    exit={{opacity: 0}}
                                >
                                    <div className="flex items-start justify-between w-full relative">
                                        <p className={`text-md ${item.isHidden ? "text-slate-500" : "text-slate-700"} flex flex-col gap-2`}>
                                            {item.name}
                                            <span className="flex items-center gap-2">
                                                <span
                                                    className={`w-16 cursor-pointer p-1 flex items-center justify-center rounded-sm text-sm ${item.isHidden ? "bg-orange-200" : "bg-green-200"} text-xs font-light text-slate-700`}
                                                    title={item.isHidden ? "This tab is hidden" : "This tab is visible"}
                                                >
                                                {item.isHidden ? "Hidden" : "Visible"}
                                                </span>
                                                <span
                                                    className="text-xs text-slate-400 font-light underline">{!item.listForms ? 0 : item.listForms?.length} form created
                                                </span>
                                            </span>
                                        </p>
                                        <motion.button
                                            className="border border-slate-300 rounded-full p-2"
                                            whileHover={{scale: 1.045}}
                                            whileTap={{scale: .945}}
                                            onClick={() => handleOpenEditBox(item.id)}
                                        >
                                            <span className="text-sm text-slate-500"><FiEdit/></span>
                                        </motion.button>
                                    </div>

                                    <div className={`max-h-[50vh] overflow-y-auto pr-2`}>
                                        <ul className="flex flex-col gap-4 h-full overflow-hidden">
                                            {item.listForms?.map((item) =>
                                                <motion.li
                                                    key={`form-${item.id}`}
                                                    initial={{opacity: 0}}
                                                    animate={{opacity: 1}}
                                                    exit={{opacity: 0}}
                                                    transition={{type: 'spring', duration: .5, ease: 'linear'}}
                                                    className={`relative bg-white rounded-lg overflow-hidden transition-all ease-in-out  flex flex-col gap-0 items-start justify-between cursor-pointer group ${item.isLocked ? "opacity-75" : "border border-slate-200 shadow-sm opacity-100"}`}
                                                    onClick={(e) => {
                                                        handleOpenDrawer(item.id)
                                                    }
                                                    }
                                                >
                                                    <div className="w-full relative flex flex-col">
                                                        {/*Status*/}
                                                        <div className={`px-2 h-10 relative w-full flex items-center gap-2 ${item.isLocked ? "border border-slate-200 bg-white" : "bg-indigo-200"}`}>
                                                            <span
                                                                className={`text-xs ${item.isHidden ? `text-slate-400` : `text-slate-600`}`}
                                                                title={item.isHidden ? "Hidden" : "Visible"}
                                                            >
                                                                {item.isHidden ? <FiEyeOff/> : <FiEye/>}
                                                            </span>
                                                            <span
                                                                className={`text-xs border border-slate-500 p-1 rounded-full ${item.isLocked ? `text-slate-400` : `text-slate-600`}`}
                                                                title={item.isLocked ? "Locked" : "Unlock"}
                                                            >
                                                                {item.isLocked ? <FiLock/> : <FiUnlock/>}
                                                            </span>
                                                            <span className={`text-xs text-slate-500`}
                                                                  title={item.typeOfForm === null ? "Form classic" : "Form list"}
                                                            >
                                                                {item.typeOfForm === null ? "Form classic" : "Form list"}
                                                            </span>
                                                        </div>
                                                        {!item.isLocked &&
                                                            <div className="p-2 min-h-10">
                                                                <p className="text-sm text-slate-500 font-medium ">{item.name}</p>
                                                            </div>
                                                        }

                                                    </div>

                                                    <button className={`text-slate-700 text-xs border border-slate-100 w-6 h-6 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 transition-opacity absolute top-2 right-2 bg-white `}
                                                    ><span><FiEdit2/></span></button>

                                                </motion.li>
                                            )}

                                            {openFormFormsId === item.id &&
                                                <li>
                                                    <CreateForm
                                                        setOpenFormFormsId={setOpenFormFormsId}
                                                        setIsOpenTabs={setIsOpenTabs}
                                                        setSubmitting={setSubmitting}
                                                        data={myTabs}
                                                        method="POST"
                                                        idTab={item.id}
                                                        type={type}

                                                        idResourceType={idResourceType}
                                                    />
                                                </li>

                                            }
                                        </ul>
                                    </div>

                                    <div className="relative">
                                            <Button
                                                variant="light"
                                                form="forms"
                                                title="Add new form"
                                                itemId={item.id}
                                                setOpenFormFormsId={setOpenFormFormsId}
                                                openFormFormsId={openFormFormsId}
                                            />
                                    </div>
                                </motion.div>
                            }
                        </li>
                    )}

                    <li className="relative">
                        {!isOpenTabs ?
                            <Button
                                variant="dark"
                                form="tabs"
                                title="Add new tab"
                                setIsOpenTabs={setIsOpenTabs}
                            />
                            :
                            <FormTabs
                                setIsOpenTabs={setIsOpenTabs}
                                setOpenEditBox={setOpenEditBox}
                                setSubmitting={setSubmitting}
                                method="POST"
                                type={type}
                                myTabs={myTabs}
                                id={id}
                                networkInfraDescriptionId={idInfra}
                                networkSeviceDescriptionId={idServ}
                                networkPPAId={idPPA}

                                idResourceType={idResourceType}
                            />
                        }
                    </li>
                </motion.ul>
            }

            <DrawerTemporary
                isOpenDrawer={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                setSubmitting={setSubmitting}
                onDrawerClose={onDrawerClose}
                data={myTabs}
                idSelected={idFormActive}
                // content={<OneForm networkId={networkId}/>}
                content={
                    <OneForm networkId={networkId}
                             featureType={type}
                    />
                }
                backCover={backCover}
                titleDrawerUpdate="Update"
                method={"UPDATE"}
            />
        </>
    );
};
