import React from "react";

// Design
import {FaProjectDiagram} from "react-icons/fa";

// Utils
import {dataToArray} from "../../../../../utils/dataToArray";


export const ServiceTypeCell = ({data}) => {

    const listToArray = dataToArray(data);

    return (
        <div className={"flex w-full gap-2"}>
            <ul className={"w-full"}>
                {listToArray.map((list) => (
                    <li key={list.id} className={'py-1'}>
                        <div className={"flex items-center gap-2 relative"}>
                            <FaProjectDiagram className={"absolute opacity-20"} size={16} />
                            <span className={"z-10 ml-6"}>{list.name}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )

}