import React from "react";

// Design
import {useTreeItem2Utils} from "@mui/x-tree-view";
import {TreeItem2} from "@mui/x-tree-view/TreeItem2";
import {FaCheckCircle} from "react-icons/fa";
import {BsFillBoxFill} from "react-icons/bs";
import {FiFolder} from "react-icons/fi";


export const CustomIcons = ({props, checkedIcon}) => {
    return (
        <div className={"relative flex "}>
            {props.children ? <FiFolder size={22} /> : <BsFillBoxFill size={18} />}
            <div className={"absolute left-2 bottom-0"}>
                {checkedIcon}
            </div>
        </div>
    )
}

export const CustomLabel = ({ children, hasChildren }) => {
    return <div className={`custom-label py-2 px-3 rounded-lg ${hasChildren && 'border border-gray-200'}`}>{children}</div>
}

export const customTreeItem2Style = {
    '.MuiTreeItem-iconContainer' : { width: '24px' },
    '.MuiTreeItem-content' : { padding: '2px 8px' },
    '.MuiTreeItem-content:hover, .Mui-selected, .Mui-focused' : { background: 'none' },
    '.MuiTreeItem-content:hover' : {
        '& .custom-label' : { background : 'rgba(0, 0, 0, .04)' }
    },
    '.Mui-selected': {
        '& .custom-label' : { background : 'rgba(239, 246, 255, 1)' }
    },
    '.MuiTreeItem-label' : { gap: '6px' },
}

export const DesignedCustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const { interactions } = useTreeItem2Utils({
        itemId: props.itemId,
        children: props.children,
    });

    const handleIconContainerClick = (event) => {
        interactions.handleExpansion(event);
        event.stopPropagation();
    };

    return (
        <TreeItem2
            {...props}
            ref={ref}
            slots={{ label: CustomLabel }}
            slotProps={{
                checkbox: {
                    size: 'medium',
                    icon: <CustomIcons props={props} />,
                    checkedIcon: <CustomIcons props={props} checkedIcon={<FaCheckCircle size={16} className={"text-green-600 bg-white rounded-full"} />} />,
                },
                iconContainer: { onClick: handleIconContainerClick },
                label: {
                    hasChildren: props.children,
                },
            }}
            sx={{...customTreeItem2Style}}
        />
    );
});