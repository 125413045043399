import React from "react";

// Design
import './index.scss';

// Utils
import {dataToArray} from "../../../../../utils/dataToArray";


export const StepStatusCell = ({data}) => {

    const listToArray = dataToArray(data);

    const objectGroupedByStatus = Object.groupBy(listToArray, ({status}) => status);

    let arrayByStatus = [];
    for (const [key, value] of Object.entries(objectGroupedByStatus)) {
        arrayByStatus.push({status: [key], steps: value});
    }

    const statusData = [
        { status: 'opened', text: 'Opened', color: 'bg-sky-600', },
        { status: 'under_prepare', text: 'Under prepare', color: 'bg-sky-600', },
        { status: 'completed', text: 'Completed', color: 'bg-green-600', },
        { status: 'in_progress', text: 'In progress', color: 'bg-violet-500', },
        { status: 'locked', text: 'Locked', color: 'bg-red-700', },
        { status: 'on_review', text: 'On review', color: 'bg-orange-500', },
        { status: 'on_prepare', text: 'On prepare', color: 'bg-sky-600', },
        { status: 'terminated', text: 'Terminated', color: 'bg-green-600', },
        { status: 'validated', text: 'Validated', color: 'bg-green-600', },
    ];


    return (
        <div className={"step-status w-full flex flex-wrap"}>
            <div className={"w-full"}>
                Total : <strong>{listToArray.length}</strong>
            </div>
            <ul className={"w-full"}>
                {arrayByStatus.map((item) => {

                    const statusColor = statusData.find((found) => found?.status === (item?.status).toString()).color;
                    const statusText = statusData.find((found) => found?.status === (item?.status).toString()).text;

                    return (
                        <li key={item.status} className={`chip ${statusColor}`}>
                            {statusText} : {item.steps.length}
                        </li>
                    )

                })}
            </ul>
        </div>
    )

}