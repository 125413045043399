import {useContext, useEffect, useState} from "react";
import UserService from "../../../../services/UserService";
import CreatableSelect from "react-select/creatable";
import {useDispatch, useSelector} from "react-redux";
import {displayMessage} from "../../../../redux/alertAction";
import {isValidEmail} from "../../../ppa/utils/CustomFunctions";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import {createSelector} from "reselect";
import {Controller, useForm} from "react-hook-form";
import {useFetchOneUserInfra} from "../../../user/hook/useFetchUserInfra";
import {Loader} from "../../../../components/loader/Loader";
import {FormLoader} from "../../../servicetype/loaders/Loaders";
import Select from "react-select";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import * as React from "react";
import {FiAtSign, FiInfo, FiShield, FiTrash, FiUsers} from "react-icons/fi";
import {useFetchUserRole} from "../../../../hooks/useFetchUserRole";
import {Forbidden} from "../../../../pages/Forbidden/Forbidden";
import getFullName from "../../../../utils/getFullName";
import {getStatusColor} from "../../../user/utils/getStatusColor";
import {toast} from "sonner";
import {formatDateToCustom} from "../../../../utils/CustomDate";

const components = {
  DropdownIndicator: null,
};

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

export function UserForm({roles, teams, increment})
{

    // User token
    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);
    const role = useFetchUserRole();

    const dispatch = useDispatch();

    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? "CREATE";
    const isCreate = method === "CREATE";
    const isUpdate = method === "UPDATE";
    // const isResend = method === "RESEND";

    const [isResend, setIsResend] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [emails, setEmails] = useState([]);

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues} = useForm({
        defaultValues: {
            emails: [],
            role:[],
            teams: [],
        },
    });
    const createOption = (label) => ({
        label,
        value: label,
    });

    const [isLoading, setIsLoading] = useState(true);

    const { userInfra } = useFetchOneUserInfra(context.idSelected, user.token, increment, setIsLoading);

    useEffect(() => {
        setIsResend(userInfra?.status === "invited");
    }, [userInfra, isResend]);

    useEffect(() => {
        reset({
            emails: [],
            role: null,
            teams: [],
        });
        setEmails([]);
        setInputValue('');

        if (!isLoading) {
            if (null !== userInfra) {
                const currentEmail = userInfra?.user?.email ?? [];
                setEmails((prev) => [...prev, createOption(currentEmail)]);
                setInputValue('');
                setValue('emails', emails);
                setValue('role', userInfra?.role ?? null);
                setValue('teams', userInfra?.listTeams?.filter((t) => !t.isProject) ?? []);
            } else {
                reset({
                    emails: [],
                    role: null,
                    teams: [],
                });
            }
        }
    }, [isLoading, userInfra]);

    const handleKeyDownOrBlur = (event) => {
        if (!inputValue) return;
        if (event.type === 'blur' || event.key === 'Enter' || event.key === 'Tab' || event.key === ' ' || event.key === ',') {
            const newInputValue = inputValue.trim();
            if (typeof inputValue === 'string' && inputValue.length > 0 && isValidEmail(newInputValue)) {
                const onlyValue = emails.map(e => e.value);
                if(!onlyValue.includes(newInputValue)) {
                    setEmails((prev) => [
                        ...prev,
                        createOption(newInputValue)
                    ]);
                } else {
                    dispatch(displayMessage('Info', 'The address ' + newInputValue + ' is already in the list', 'info'))
                }
                setInputValue('');
            }
            event.preventDefault();
        }
    }


    /****
     * SUBMIT FORMS FUNCTION
     * ****/
    const onSubmit = async (data) => {
        try {
            setSubmitting(true);
            const requestData = {
                emails: emails.map((e) => e.value),
                role: `/api/roles/${data.role?.id}`,
                listTeams: data.teams.map((t) => `/api/teams/${t.id}`),
                infrastructure: `/api/infrastructures/${currentInfrastructure}`,
            };
            const userService = new UserService();
            if (isCreate || isResend) {
                let isInError = false;
                userService.inviteUsers(requestData, user.token).catch((result) => {
                    setSubmitting(false)
                    dispatch(displayMessage(result.data.title, result.data.detail, 'error'))
                    isInError = true;
                }).then(() => {
                    if (!isInError) {
                        // Is ok
                        reset({
                            emails: [],
                            role: null,
                            teams: [],
                        });

                        dispatch(displayMessage("Success", `An email has been successfully sent`, "success"));

                        context.onDrawerClose();
                    }
                });
            } else if (isUpdate) {
                userService.update(userInfra.id, requestData, user.token).then(() => {
                    reset({
                        emails: [],
                        role: null,
                        teams: [],
                    });

                    dispatch(displayMessage("Success", `User '${userInfra?.user?.fullName}' has been updated`, "success"));

                    context.onDrawerClose();
                });
            }
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    const handleDelete = async () => {
        try{
            setSubmitting(true);
            const userService = new UserService();
            userService.delete(userInfra.id, user.token).then(() => {
                reset({
                    emails: [],
                    role: null,
                    teams: [],
                });

                dispatch(displayMessage("Success", `User '${userInfra?.user?.fullName}' has been removed`, "success"));

                context.onDrawerClose();
                setSubmitting(false);
            });

        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    }

    if (!user
        || !role
        || (!user.isSuperAdministrator && (
            ((!role.canUpdateUsers && !role.canDeleteUsers) && isUpdate)
            || (!role.canCreateUsers && isCreate)
            || ((!role.canUpdateUsers && !role.canDeleteUsers) && isResend)
        ))
    ) {
        return <Forbidden isInDrawer={true} />
    }

    const handleClickStatus = () => {
        return toast.message(`An ${userInfra?.status} status`);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">

            {submitting ? (
                    <Loader content="Submitting"/>
                ) :
                (
                    <>
                        {/* 01 */}
                        {isLoading ?
                            (<FormLoader/>)
                            :
                            (
                                <>
                                    <div className="flex flex-col gap-4 w-full">
                                        {(isUpdate || userInfra?.status === "invited") &&

                                            <div className={` border border-slate-200 h-24 flex items-center px-4 relative`}>

                                                <div className="flex items-start gap-2">
                                                    <span
                                                        className={`border border-slate-200 rounded-full h-14 w-14 flex items-center justify-center font-bold bg-slate-200 uppercase`}
                                                        title="See profil"
                                                    >
                                                        {getFullName(userInfra?.user?.fullName)}
                                                    </span>
                                                    <p className="text-slate-700 text-md flex flex-col">
                                                        {userInfra?.user?.fullName}
                                                        <span className="text-sm text-slate-500">{userInfra?.user?.email}</span>
                                                    </p>
                                                </div>

                                            </div>
                                        }

                                        {(isUpdate || userInfra?.status === "invited") ?
                                            <ul className="flex flex-col gap-2">
                                                <li className="w-full flex justify-center items-center">
                                                    <p className="text-slate-500 text-sm flex items-center gap-2 grow">
                                                        Status user
                                                    </p>
                                                    <span
                                                        onClick={handleClickStatus}
                                                        className={`rounded-sm px-2 text-xs h-6 flex items-center cursor-pointer ${getStatusColor(userInfra?.status)}`}
                                                    >{userInfra?.status}</span>
                                                </li>
                                                <li className="w-full flex justify-center items-center">
                                                    <p className="grow text-slate-500 text-sm flex items-center gap-2">
                                                        Date of creation
                                                    </p>

                                                    <span
                                                        className="rounded-sm border border-slate-200 h-6 flex items-center text-slate-700 text-xs px-2">{formatDateToCustom(userInfra?.createdAt)}</span>
                                                </li>
                                                <li className="w-full flex justify-center items-center">
                                                    <p className="grow text-slate-500 text-sm flex items-center gap-2">
                                                        Last updated date
                                                    </p>
                                                    <span
                                                        className="rounded-sm border border-slate-200 h-6 flex items-center text-slate-700 text-xs px-2">{formatDateToCustom(userInfra?.updatedAt)}</span>
                                                </li>
                                            </ul>

                                            :

                                            <p className="border border-slate-200 h-20 flex items-center justify-center px-4 text-slate-700 text-sm">Enter the user’s email, assign their role(s), and select the team(s) they’ll be part of. Once submitted, they’ll receive an invitation to join.</p>
                                        }

                                        <hr/>

                                        <div className="flex flex-col gap-4">
                                            {(isCreate || userInfra?.status === "invited") &&
                                                <>
                                                    <p className="text-slate-700 text-lg flex items-center gap-2">
                                                        <span className="text-slate-500 text-xs border border-slate-200 h-6 w-6 flex items-center justify-center rounded-full"><FiAtSign /></span>Email (s) <sup title="You can enter many emails, separated by comma. Press enter to validate email"> <FiInfo /></sup>
                                                    </p>
                                                    <div className="w-full">
                                                        <Controller
                                                            name="emails"
                                                            control={control}
                                                            render={({field}) => (
                                                                <CreatableSelect
                                                                    {...register("emails", {required: "At least one email is required"})}
                                                                    {...field}
                                                                    components={components}
                                                                    inputValue={inputValue}
                                                                    onBlur={(e) => {
                                                                        setValue("emails", emails);
                                                                        const newValue = e.target.value.trim();
                                                                        if (isValidEmail(newValue)) {
                                                                            setInputValue('');
                                                                        } else {
                                                                            setInputValue(newValue)
                                                                        }
                                                                        handleKeyDownOrBlur(e);
                                                                    }}
                                                                    isClearable
                                                                    isMulti
                                                                    required={false}
                                                                    menuIsOpen={false}
                                                                    onChange={(newValue) => {
                                                                        setEmails(newValue)
                                                                    }}
                                                                    onInputChange={(newValue) => {
                                                                        setInputValue(newValue)
                                                                    }}
                                                                    onKeyDown={handleKeyDownOrBlur}
                                                                    placeholder="Type user(s) email address(es) to invite"
                                                                    value={emails}
                                                                    name="emails-input"
                                                                />
                                                            )}
                                                        />
                                                        {errors.emails && !watch("emails") && (
                                                            <span className={"text-sm text-red-500/70"}
                                                                  role="alert">{errors.emails.message}</span>
                                                        )}
                                                    </div>

                                                </>
                                            }

                                            <div className="w-full flex flex-col gap-4 items-start">
                                                <p className="text-slate-700 text-lg flex items-center gap-2">
                                                    <span className="text-slate-500 text-xs border border-slate-200 h-6 w-6 flex items-center justify-center rounded-full"><FiShield /></span>Role
                                                </p>

                                                <div className="w-full">
                                                    <Controller
                                                        name="role"
                                                        control={control}
                                                        render={({field}) => (
                                                            <Select
                                                                {...register("role", {required: "Role is required"})}
                                                                {...field}
                                                                onChange={(role) => {
                                                                    setValue("role", role);
                                                                }}
                                                                options={roles}
                                                                defaultValue={roles?.find((option) => option.id === userInfra?.role?.id ?? null)}
                                                                getOptionLabel={(option) => option.name} // Récupère le nom de l'option
                                                                getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                                isSearchable
                                                            />
                                                        )}
                                                    />
                                                    {errors.role && !watch("role") && (
                                                        <span className={"text-sm text-red-500/70"}
                                                              role="alert">{errors.role.message}</span>
                                                    )}
                                                </div>

                                            </div>

                                            <div className="w-full flex flex-col gap-4 items-start">
                                                <p className="text-slate-700 text-lg flex items-center gap-2"><span className="text-slate-500 text-xs border border-slate-200 h-6 w-6 flex items-center justify-center rounded-full"><FiUsers /></span>Teams</p>

                                                <div className="w-full">
                                                    <Controller
                                                        name="teams"
                                                        control={control}
                                                        render={({field}) => (
                                                            <Select
                                                                {...register("teams", {required: "Teams is required"})}
                                                                {...field}
                                                                onChange={(t) => {
                                                                    setValue("teams", t);
                                                                }}
                                                                isMulti
                                                                options={teams}
                                                                getOptionLabel={(option) => option.name} // Récupère le nom de l'option
                                                                getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                                isSearchable
                                                            />
                                                        )}
                                                    />
                                                    {errors.teams && !watch("teams") && (
                                                        <span className={"text-sm text-red-500/70"}
                                                              role="alert">{errors.teams.message}</span>
                                                    )}
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    {/* 02 save change button */}
                                    <div className={`absolute bottom-8 left-1/2 transform -translate-x-1/2 flex items-center gap-4 wrapper`}>

                                        <button
                                            type="submit"
                                            className={`grow border border-slate-200 border-dashed rounded-sm text-slate-700 bg-slate-200 text-md h-10 flex items-center justify-center hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                                        >
                                            { (userInfra?.status === "invited") ? "Send again the invitation" : isCreate ? "Send an invitation" : "Save change" }
                                        </button>
                                        {isUpdate &&
                                            <>
                                                {(user.isSuperAdministrator || role.canDeleteUsers) &&
                                                    <>
                                                        <button
                                                            type="button"
                                                            onClick={ () => setOpenConfirmation(true)}
                                                            className={`border border-red-200 rounded-full bg-red-100 text-red-500 text-md h-10 w-10 flex items-center justify-center hover:bg-red-500 hover:text-slate-50 transition ease-linear`}
                                                        >
                                                            <FiTrash />
                                                        </button>
                                                        <ConfirmDialog
                                                            isOpen={openConfirmation}
                                                            onClose={() => setOpenConfirmation(false)}
                                                            setIsOpen={setOpenConfirmation}
                                                            onAgree={() => handleDelete()}
                                                            title={`Remove user`}
                                                            content={`Are you sure you want to remove ${userInfra?.user?.fullName} from your installation ?`}
                                                            textBtnConfirm={`Yes, remove '${userInfra?.user?.fullName}' from my installation`}
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </>)
                        }
                    </>
                )}
        </form>
    )
}