import React from 'react'
import { useEffect, useState } from 'react'

// Design
import {KeyboardArrowDown, KeyboardArrowRight} from '@mui/icons-material'
import { FiChevronDown, FiChevronUp, } from 'react-icons/fi'
import Tooltip from '@mui/material/Tooltip'
import {DesignedCustomTreeItem} from "../../../../../../tree/DesignedCustomTreeItem";

// Components
import {RichTreeView} from '@mui/x-tree-view'
import { Loader } from '../../../../../../loader/Loader'
import {TreeItemsSelection} from "../../../../../../tree/TreeItemsSelection";
import {
    orderTreeByNameAndParentFirst
} from "../../../../../../../features/resource/utils/orderTreeByNameAndParentFirst";
import {toast} from "sonner";


export const FormResourcesTree = ({
                                      apiRef,
                                      isMultiple,
                                      selectedItems,
                                      setSelectedItems,
                                      id,
                                      fieldUniqueId,

                                      listResources,
                                      isLoading,
                                      handleDrawerClose
                                       }) => {

    const [treeItems, setTreeItems] = useState([]);
    const [selectedViewItems, setSelectedViewItems] = useState([]);
    const [expandedItems, setExpandedItems] = useState([]);
    const EXPAND_TREE_ITEMS_LIMIT = 100;
    const messageServiceTypeWarning = 'This element can\'t be selected because it\'s the Resource Type';


    useEffect(() => {
        setTreeItems(listResources['hydra:member'] ?? []);
    }, [listResources, selectedItems]);

    useEffect(() => {
        if (treeItems.length !== 0) {
            orderTreeByNameAndParentFirst(treeItems);
            setTimeout(() => {
                selectedItems.length <= EXPAND_TREE_ITEMS_LIMIT && expandSelectedItemsPathsOnLoad();
            }, 300);
        }
    }, [treeItems]);

    // Single Item Check Only
    const handleItemClick = (event, ids) => {

        if (ids?.startsWith('rt-') && event.type === 'click') {
            toast.warning(messageServiceTypeWarning);
            return
        }

        if (!selectedViewItems.includes(ids)) {
            setSelectedViewItems(isMultiple ? [...selectedViewItems, ids] : [ids]);
        } else {
            setSelectedViewItems(isMultiple ? selectedViewItems.filter(item => item !== ids) : []);
        }
    };

    // Allow Multiple Check
    const handleSelectedItemsChange = (event, ids) => {

        const resourceTypeId = isMultiple ? ids.find((found) => found) : ids;

        if (resourceTypeId?.startsWith('rt-') && event.type === 'change') {
            toast.warning(messageServiceTypeWarning);
            return
        }

        setSelectedViewItems(isMultiple ? ids : [ids]);
    };

    // Update Container Data with View
    useEffect(() => {
        setSelectedItems(selectedViewItems);
    }, [selectedViewItems]);

    // Update View Data with Container
    useEffect(() => {
        setSelectedViewItems(selectedItems);
    }, [selectedItems]);


    /**********************************
    ********* View Management *********
    /**********************************/
    const getParentList = (rId) => {
        let result = []
        const r = apiRef?.current?.getItem(rId)
        if (undefined !== r && null !== r) {
            result.push(rId)
            if (r.parent) {
                const splittedValue = r.parent.split('/')
                const parentId = splittedValue.pop()
                const curParent = apiRef?.current?.getItem(parentId)
                result.push(parentId)
                if (curParent && curParent.parent) {
                    const splittedValueParent = curParent.parent.split('/')
                    const parentParentId = splittedValueParent.pop()
                    const parentResult = getParentList(parentParentId)

                    parentResult.map((pr) => {
                        return result.push(pr)
                    })
                }
            }
            return result
        }
        return []
    }

    const expandSelectedItemsPathsOnLoad = () => {

        let detectParentsToExpand = [];
        const detectedIds = new Set();
        let parentsToExpand = [];

        for (let item of selectedItems) {
            detectParentsToExpand.push(getParentList(item));
        }

        detectParentsToExpand.forEach((item) => {
            const rootParentId = Number(item[item.length -1]);

            for (let treeItem of treeItems) {
                if (treeItem?.children.find((found) => found.id === rootParentId)) {
                    item.push(treeItem.id);
                }
            }
        })

        const flattenedDetectedParents = detectParentsToExpand?.flatMap(item => item);

        flattenedDetectedParents.forEach((item) => {
            if (!detectedIds.has(item)) {
                parentsToExpand.push(item);
                detectedIds.add(item);
            }
        });

        setExpandedItems(parentsToExpand);
    }

    const handleItemExpansionToggle = (event, itemIds, isExpanded) => {
        event.defaultMuiPrevented = true
        let newItemIds = []
        if (isExpanded) {
            newItemIds = expandedItems.concat(itemIds)
        } else {
            newItemIds = expandedItems.toSpliced(expandedItems.indexOf(itemIds), 1)
        }

        setExpandedItems(newItemIds)
    }

    /********* Custom Props ********/
    const customRichTreeViewProps = {
        multiSelect: isMultiple,
    };


    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={"form-resources-tree flex flex-col min-h-[84vh] w-full gap-4"}>

                    {isMultiple && (
                        <TreeItemsSelection treeItems={treeItems}
                                            selectedViewItems={selectedViewItems} // Can be selectedItems
                                            setSelectedViewItems={setSelectedViewItems} // Can be setSelectedItems
                        />
                    )}

                    <RichTreeView
                        apiRef={apiRef}
                        slots={{
                            expandIcon: KeyboardArrowRight,
                            collapseIcon: KeyboardArrowDown,
                            item: DesignedCustomTreeItem
                        }}
                        slotProps={{
                            item: {
                                'data-inputid':`${id}`,
                                'id':`${fieldUniqueId}`
                            }
                        }}
                        getItemId={(item) => item?.id?.toString()}
                        getItemLabel={(item) => item.name + (item.children.length ? ' (' + item.children.length + ')' : '')}
                        items={treeItems}
                        expansionTrigger="iconContainer"
                        checkboxSelection
                        selectedItems={selectedViewItems}
                        expandedItems={expandedItems}
                        onItemClick={handleItemClick}
                        onSelectedItemsChange={handleSelectedItemsChange}
                        onItemExpansionToggle={handleItemExpansionToggle}

                        {...customRichTreeViewProps}
                        className={"text-sm"}
                    />

                    <div className={"form-resources-tree-actions mt-auto pt-8"}>
                        <button type="button"
                                className="w-full py-3 px-4 bg-blue-500 hover:bg-blue-600 text-white text-sm rounded-full cursor-pointer"
                                onClick={handleDrawerClose}
                        >
                            Validate
                        </button>
                    </div>

                </div>
            )}
        </>
    )
}
