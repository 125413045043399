import React from "react";

// Design
// import './index.scss';

// Utils
import {statusData} from "../../../utils/statusData";


export const StatusCell = ({data}) => {

    // const statusData = [
    //     { status: 'opened', text: 'Opened', color: 'bg-sky-500', },
    //     { status: 'under_prepare', text: 'Under prepare', color: 'bg-sky-500', },
    //     { status: 'completed', text: 'Completed', color: 'bg-green-700', },
    //     { status: 'in_progress', text: 'In progress', color: 'bg-violet-500', },
    //     { status: 'locked', text: 'Locked', color: 'bg-red-700', },
    //     { status: 'on_review', text: 'On review', color: 'bg-orange-500', },
    //     { status: 'on_prepare', text: 'On prepare', color: 'bg-sky-500', },
    //     { status: 'terminated', text: 'Terminated', color: 'bg-green-500', },
    //     { status: 'validated', text: 'Validated', color: 'bg-green-500', },
    // ];

    const statusColor = statusData?.find((found) => found?.status === (data).toString())?.color ?? "";
    const statusText = statusData?.find((found) => found?.status === (data).toString())?.text ?? "";

    return (
        <div className={"step-status w-full flex relative"}>
            <span className={`absolute top-[3px] p-2 rounded-full ${statusColor}`}></span>
            <span className={`pl-6`}>{statusText}</span>
        </div>
    )

}