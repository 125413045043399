import { Drawer } from '@mui/material'
import { FiX } from 'react-icons/fi'
import { createContext, useState } from 'react'
import { useMobile } from '../../hooks/useMobile'

export const DrawerTemporaryContext = createContext(null)

export const DrawerTemporary = ({
    isOpenDrawer,
    setIsOpenDrawer,
    setSubmitting,
    idSelected,
    setIdSelected,
    method,
    titleDrawer,
    titleDrawerUpdate,
    content,
    onDrawerClose,
}) => {
    const [closeDrawer] = useState(false)

    const isMobile = useMobile()

    const toggleDrawer = (anchor, open) => (event, reason) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (reason === 'escapeKeyDown') {
            setIsOpenDrawer(false);
            return
        }
        setIsOpenDrawer(open)
    }

    const handleCloseDrawer = () => {
        setIsOpenDrawer(false)
        // onDrawerClose()
    }

    return (
        <Drawer
            sx={{
                width: { xs: '100%', lg: '550px' },
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: { xs: '100%', lg: '550px' },
                },
            }}
            onClose={toggleDrawer('right', true)}
            anchor={isMobile ? 'bottom' : 'right'}
            open={isOpenDrawer}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{
                sx: {
                    width: { xs: '100%', lg: '550px' },
                    transition: '250ms linear',
                    height: { xs: closeDrawer ? '100%' : '92vh', lg: '100%' },
                },
            }}
        >
            <div className="flex flex-col items-center">
                <div
                    className={`w-full flex items-center justify-center border border-slate-200 border-solid px-8 h-20`}
                >
                    <p className="text-left w-full text-xl font-medium">
                        {method === 'CREATE' && titleDrawer && <>{titleDrawer}</>}
                        {method === 'UPDATE' && titleDrawerUpdate && <>{titleDrawerUpdate}</>}
                        {!titleDrawer && !titleDrawerUpdate && <>Informations</>}
                    </p>

                    <button
                        className="block border border-solid border-slate-700 bg-slate-50 rounded-full p-2"
                        onClick={handleCloseDrawer}
                    >
                        <FiX />
                    </button>
                </div>

                <div className="grow flex flex-col w-full gap-4 p-8">
                    <DrawerTemporaryContext.Provider
                        value={{ onDrawerClose, idSelected, method, setIsOpenDrawer, setSubmitting, isOpenDrawer }}
                    >
                        {content}
                    </DrawerTemporaryContext.Provider>
                </div>
            </div>
        </Drawer>
    )
}
