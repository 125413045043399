export const downloadQrCode = () => {
    const svg = document.getElementById("QRCode");
    const text = document.getElementById("QRCodeText").innerText;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
        const padding = 20; // Espace entre l'image et le texte
        let fontSize = 20; // Taille de texte par défaut
        const maxWidth = img.width - 20; // Largeur max du texte (évite débordement)
        let textLines = [];

        // Fonction pour découper le texte en plusieurs lignes
        const breakText = (text, maxWidth, fontSize) => {
            ctx.font = `${fontSize}px Arial`;
            let words = text.split(" ");
            let lines = [];
            let line = "";

            words.forEach(word => {
                let testLine = line + (line ? " " : "") + word;
                let testWidth = ctx.measureText(testLine).width;

                if (testWidth > maxWidth && line.length > 0) {
                    lines.push(line);
                    line = word;
                } else {
                    line = testLine;
                }
            });

            lines.push(line); // Ajouter la dernière ligne
            return lines;
        };

        // Ajuster la taille du texte et découper en lignes
        ctx.font = `${fontSize}px Arial`;
        textLines = breakText(text, maxWidth, fontSize);

        // Si trop de lignes, réduire la taille du texte progressivement
        while (textLines.length > 3 && fontSize > 12) {
            fontSize -= 2;
            textLines = breakText(text, maxWidth, fontSize);
        }

        const textHeight = (textLines.length * fontSize) + padding * 2; // Hauteur du texte

        // Définir la taille du canvas (image + texte)
        canvas.width = img.width + 20;
        canvas.height = img.height + textHeight + 10;

        // Fond blanc pour toute l'image
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Dessiner l'image QR Code
        ctx.drawImage(img, 10, 10);

        // Fond blanc sous le texte
        ctx.fillStyle = "white";
        ctx.fillRect(0, img.height + 10, canvas.width, textHeight);

        // Configurer le texte (noir, centré)
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.font = `${fontSize}px Arial`;

        // Dessiner les lignes de texte sous l'image
        textLines.forEach((line, index) => {
            const textY = img.height + padding + (index + 1) * fontSize;
            ctx.fillText(line, canvas.width / 2, textY);
        });


        const jpegFile = canvas.toDataURL("image/jpeg");
        const downloadLink = document.createElement("a");
        downloadLink.download = text?.replace(/\W+/g, '_');
        downloadLink.href = `${jpegFile}`;
        downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
}


