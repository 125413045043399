import { Box, Typography } from '@mui/material'
import SubMenu from './menu/SubMenu'
import { TabContext, TabPanel } from '@mui/lab'
import { Fragment, useEffect, useState } from 'react'
import { Overview } from './overview/Overview'
import { AnimatePresence, motion } from 'framer-motion'
import { Loader } from '../../../components/loader/Loader'
import { Calendar } from './calendar/Calendar'
import { Link } from './link/Link'
import { Info } from './info/Info'
import { Activity } from './activity/Activity'
import { Access } from './access/Access'
import { Qr } from './qr/Qr'

export const ResourceContent = ({ resourceType, resource, isLoadingResource, tab, setTab }) => {
    const [value, setValue] = useState(tab ?? 'overview');

    useEffect(() => {
        setTab(value);
    }, [value])

    useEffect(() => {
        setValue(value);
    }, [])

    const panel = [
        {
            id: 1,
            title: 'Overview',
            link: 'overview',
            component: <Overview resource={resource} />,
        },
        {
            id: 2,
            title: 'Calendar',
            link: 'calendar',
            component: <Calendar resourceType={resourceType} resource={resource} />,
        },
        {
            id: 3,
            title: 'Link',
            link: 'link',
            component: <Link resource={resource} resourceType={resourceType} />,
        },
        {
            id: 4,
            title: 'Resources infos',
            link: 'resourceinfos',
            component: <Info resource={resource} />,
        },
        {
            id: 5,
            title: 'Resources activities',
            link: 'resourceactivities',
            component: <Activity resource={resource} />,
        },
        {
            id: 6,
            title: 'Access',
            link: 'access',
            component: <Access resource={resource} resourceType={resourceType} />,
        },
        {
            id: 7,
            title: 'QR',
            link: 'qr',
            component: <Qr resource={resource} />,
        },
    ]

    return (
        <AnimatePresence>
            <motion.div
                className="w-1/4 flex flex-col items-stretch grow pt-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                {isLoadingResource ? (
                    <Loader content="Loading resource" />
                ) : (
                    <Fragment>
                        <div title={resource.name}>
                            <Typography component="h2"
                                        variant="h2"
                                        sx={{
                                            fontWeight: 'bold',
                                            padding: {xs: '0 16px', lg: '0'},
                                            width: {xs: '100%', lg: 'auto'},
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        }}
                            >
                                {resource.name}
                            </Typography>
                        </div>
                        <Box sx={{ width: '100%', typography: 'body1', marginTop: '45px', maxWidth: '1200px' }}>
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        border: 'none',
                                        marginBottom: '25px',
                                        background: 'transparent',
                                    }}
                                >
                                    <SubMenu setValue={setValue} panel={panel} />
                                </Box>

                                {panel.map((item) => (
                                    <TabPanel key={item.id} value={item.link}>
                                        {item.component}
                                    </TabPanel>
                                ))}
                            </TabContext>
                        </Box>
                    </Fragment>
                )}
            </motion.div>
        </AnimatePresence>
    )
}
