import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { Checkbox, Divider, Stack, Typography } from '@mui/material'
import { useFetchRole } from '../../hooks/api/useFetchRole'
import { useContext, useEffect, useState } from 'react'
import { DrawerLoader } from '../../../ppa/components/loaders/Loaders'
import { useFetchInfrastructureUsers } from '../../hooks/api/useFetchInfrastructureUsers'
import { useDispatch, useSelector } from 'react-redux'
import { displayMessage } from '../../../../redux/alertAction'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import { createSelector } from 'reselect'
import { Controller, useForm } from 'react-hook-form'
import * as React from 'react'
import { Loader } from '../../../../components/loader/Loader'
import Select from 'react-select'
import ConfirmDialog from '../../../../components/confirm/ConfirmDialog'
import Alert from '@mui/material/Alert'
import {
    Mode,
    ModeOutlined,
    SaveAs,
    SaveAsOutlined,
    SupervisedUserCircle,
    SupervisedUserCircleOutlined,
    VerifiedUser,
    VerifiedUserOutlined,
    Visibility,
    VisibilityOutlined,
} from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import RoleService from '../../../../services/RoleService'
import { useFetchUserRole } from '../../../../hooks/useFetchUserRole'
import { Forbidden } from '../../../../pages/Forbidden/Forbidden'
import {
    FiAlertTriangle,
    FiArrowRight,
    FiChevronRight,
    FiCompass,
    FiEdit,
    FiEdit2,
    FiEye,
    FiFeather,
    FiFolder,
    FiInfo,
    FiPenTool,
    FiRefreshCw,
    FiSave,
    FiShield,
    FiTrash,
    FiXCircle,
} from 'react-icons/fi'
import { MdOutlineSupervisedUserCircle } from 'react-icons/md'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'

const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user
)

const selectCurrentInfrastructure = createSelector(
    (state) => state.currentInfrastructure,
    (ci) => ci
)

const permissionsLevels = {
    canAccess: {
        icon: <FiEye />,
        checkedIcon: <FiEye className="text-blue-300" />,
        title: 'Hide/show the designated web page from menus',
    },
    canSeeAll: {
        icon: <MdOutlineSupervisedUserCircle />,
        checkedIcon: <MdOutlineSupervisedUserCircle className="text-blue-500" />,
        title: 'Allow to see all projects of the installation',
    },
    canCreate: {
        icon: <FiPenTool />,
        checkedIcon: <FiPenTool className="text-blue-300" />,
        title: 'Create',
    },
    canUpdate: {
        icon: <FiRefreshCw />,
        checkedIcon: <FiRefreshCw className="text-blue-500" />,
        title: 'Update',
    },
    canDelete: {
        icon: <FiTrash />,
        checkedIcon: <FiTrash className="text-blue-500" />,
        title: 'Delete',
    },
    isFull: {
        icon: <IoShieldCheckmarkSharp />,
        checkedIcon: <IoShieldCheckmarkSharp className="text-blue-500" />,
        title: 'Allow to see/create/edit/delete all elements (yours and others) for the selected menu',
    },
}

const checkboxMap = {
    'User Management': {
        Users: permissionsLevels,
        Roles: permissionsLevels,
        Teams: permissionsLevels,
        Charters: permissionsLevels,
    },
    'Project Management': {
        Projects: permissionsLevels,
        ServiceTypes: permissionsLevels,
    },
    'Resource Management': {
        EventTypes: permissionsLevels,
        Events: permissionsLevels,
        ResourceTypes: permissionsLevels,
        Resources: permissionsLevels,
    },
}

export function RoleForm({ increment }) {
    const user = useSelector(selectUser)
    const currentInfrastructureId = useSelector(selectCurrentInfrastructure)
    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? 'CREATE'
    const isCreate = method === 'CREATE'
    const isUpdate = method === 'UPDATE'

    const userInfraRole = useFetchUserRole()

    const isRead =
        !userInfraRole || (!user.isSuperAdministrator && !userInfraRole.canUpdateRoles && !userInfraRole.canCreateRoles)

    const rights = [
        'canCreateProjects',
        'canUpdateProjects',
        'canDeleteProjects',
        'canCreateResources',
        'canUpdateResources',
        'canDeleteResources',
        'canCreateUsers',
        'canUpdateUsers',
        'canDeleteUsers',
        'canCreateCharters',
        'canUpdateCharters',
        'canDeleteCharters',
        'canCreateTeams',
        'canUpdateTeams',
        'canDeleteTeams',
        'canCreateRoles',
        'canUpdateRoles',
        'canDeleteRoles',
        'canCreateServiceTypes',
        'canUpdateServiceTypes',
        'canDeleteServiceTypes',
        'canCreateResourceTypes',
        'canUpdateResourceTypes',
        'canDeleteResourceTypes',
        'canAccessRoles',
        'canAccessUsers',
        'canAccessTeams',
        'canAccessCharters',
        'canAccessProjects',
        'canAccessServiceTypes',
        'canAccessResources',
        'canAccessResourceTypes',
        'canAccessEvents',
        'canCreateEvents',
        'canUpdateEvents',
        'canDeleteEvents',
        'canAccessEventTypes',
        'canCreateEventTypes',
        'canUpdateEventTypes',
        'canDeleteEventTypes',
        'isFullProjects',
        'canSeeAllProjects',
        'isFullResources',
    ]

    const defaultFormValues = {
        name: '',
        canCreateProjects: false,
        canUpdateProjects: false,
        canDeleteProjects: false,
        canCreateResources: false,
        canUpdateResources: false,
        canDeleteResources: false,
        canCreateUsers: false,
        canUpdateUsers: false,
        canDeleteUsers: false,
        canCreateCharters: false,
        canUpdateCharters: false,
        canDeleteCharters: false,
        canCreateTeams: false,
        canUpdateTeams: false,
        canDeleteTeams: false,
        canCreateRoles: false,
        canUpdateRoles: false,
        canDeleteRoles: false,
        canCreateServiceTypes: false,
        canUpdateServiceTypes: false,
        canDeleteServiceTypes: false,
        canCreateResourceTypes: false,
        canUpdateResourceTypes: false,
        canDeleteResourceTypes: false,
        canAccessRoles: false,
        canAccessUsers: false,
        canAccessTeams: false,
        canAccessCharters: false,
        canAccessProjects: false,
        canAccessServiceTypes: false,
        canAccessResources: false,
        canAccessResourceTypes: false,
        canAccessEvents: false,
        canCreateEvents: false,
        canUpdateEvents: false,
        canDeleteEvents: false,
        canAccessEventTypes: false,
        canCreateEventTypes: false,
        canUpdateEventTypes: false,
        canDeleteEventTypes: false,
        isFullProjects: false,
        canSeeAllProjects: false,
        isFullResources: false,
        infrastructure: '/api/infrastructures/' + currentInfrastructureId,
        listUserInfras: [],
    }

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: defaultFormValues,
    })

    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [rightValues, setRightValues] = useState({
        canCreateProjects: false,
        canUpdateProjects: false,
        canDeleteProjects: false,
        canCreateResources: false,
        canUpdateResources: false,
        canDeleteResources: false,
        canCreateUsers: false,
        canUpdateUsers: false,
        canDeleteUsers: false,
        canCreateCharters: false,
        canUpdateCharters: false,
        canDeleteCharters: false,
        canCreateTeams: false,
        canUpdateTeams: false,
        canDeleteTeams: false,
        canCreateRoles: false,
        canUpdateRoles: false,
        canDeleteRoles: false,
        canCreateServiceTypes: false,
        canUpdateServiceTypes: false,
        canDeleteServiceTypes: false,
        canCreateResourceTypes: false,
        canUpdateResourceTypes: false,
        canDeleteResourceTypes: false,
        canAccessRoles: false,
        canAccessUsers: false,
        canAccessTeams: false,
        canAccessCharters: false,
        canAccessProjects: false,
        canAccessServiceTypes: false,
        canAccessResources: false,
        canAccessResourceTypes: false,
        canAccessEvents: false,
        canCreateEvents: false,
        canUpdateEvents: false,
        canDeleteEvents: false,
        canAccessEventTypes: false,
        canCreateEventTypes: false,
        canUpdateEventTypes: false,
        canDeleteEventTypes: false,
        isFullProjects: false,
        canSeeAllProjects: false,
        isFullResources: false,
    })

    const { role } = useFetchRole(context.idSelected, user.token, increment, setLoading)
    const { users } = useFetchInfrastructureUsers(currentInfrastructureId, user.token, increment)

    const [roleId, setRoleId] = useState(0)

    useEffect(() => {
        setRoleId(role?.id ?? 0)
        if (!isLoading) {
            if (null !== role) {
                setValue('name', role.name ?? '')
                rights.forEach((r) => {
                    setRightValues((prevState) => ({
                        ...prevState,
                        [`${r}`]: role[r] ?? false,
                    }))
                })
                setValue('listUserInfras', role.listUserInfras ?? [])
                setIsDisabled(role.isRoleAdmin || role.isRoleGuest || isRead)
            } else {
                handleReset()
                setIsDisabled(false)
            }
        }
    }, [isLoading, role])
    function handleReset() {
        reset(defaultFormValues)
        setRightValues(defaultFormValues)
    }

    const onSubmit = async (data) => {
        try {
            if (!isDisabled) {
                const roleService = new RoleService()
                setSubmitting(true)
                if (isCreate) {
                    roleService
                        .createRole(data, user.token)
                        .then(() => {
                            handleReset()

                            dispatch(displayMessage('Success', `The role '${data.name}' has been created`, 'success'))

                            context.onDrawerClose()
                        })
                        .catch((e) => {
                            dispatch(displayMessage(e.title, e.detail, 'error'))
                        })
                } else {
                    roleService
                        .updateRole(roleId, data, user.token)
                        .then(() => {
                            handleReset()

                            dispatch(displayMessage('Success', `The role '${role.name}' has been updated`, 'success'))

                            context.onDrawerClose()
                        })
                        .catch((e) => dispatch(displayMessage(e.title, e.detail, 'error')))
                }
                setSubmitting(false)
            }
        } catch (error) {
            setSubmitting(false)
            console.log(error)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    const handleDelete = async () => {
        try {
            if (!isDisabled && (user.isSuperAdministrator || userInfraRole?.canDeleteRoles)) {
                const roleService = new RoleService()
                setSubmitting(true)
                roleService.deleteRole(roleId, user.token).then(() => {
                    handleReset()
                    setSubmitting(false)

                    dispatch(displayMessage('Success', `The role '${role.name}' has been deleted`, 'success'))

                    context.onDrawerClose()
                })
            }
        } catch (error) {
            setSubmitting(false)
            console.log(error)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }
    function displayCheckboxes() {
        const sections = []
        for (const section in checkboxMap) {
            const stacks = []
            for (const resourceName in checkboxMap[section]) {
                const checkboxes = []
                for (const permission in checkboxMap[section][resourceName]) {
                    if (
                        (permission !== 'isFull' && permission !== 'canSeeAll') ||
                        (permission === 'isFull' && (resourceName === 'Projects' || resourceName === 'Resources')) ||
                        (permission === 'canSeeAll' && resourceName === 'Projects')
                    ) {
                        checkboxes.push(
                            <Tooltip
                                title={checkboxMap[section][resourceName][permission].title}
                                key={'tooltip-' + permission + resourceName + roleId}
                                placement={'top'}
                                children={
                                    <Checkbox
                                        {...register(permission + resourceName, {
                                            disabled: isDisabled,
                                        })}
                                        key={'checkbox-' + permission + resourceName + roleId}
                                        icon={checkboxMap[section][resourceName][permission].icon}
                                        checkedIcon={checkboxMap[section][resourceName][permission].checkedIcon}
                                        checked={rightValues[permission + resourceName]}
                                        sx={{
                                            color: '#707070',
                                            '&.Mui-disabled': {
                                                opacity: '0.5',
                                            },
                                            '&.Mui-checked': {
                                                color: '#3380FF',
                                            },
                                            padding: '0 9px 0 0',
                                        }}
                                        onChange={(event) => {
                                            if ('isFullResources' === permission + resourceName) {
                                                setRightValues((prevState) => ({
                                                    ...prevState,
                                                    canCreateResources: event.target.checked,
                                                    canUpdateResources: event.target.checked,
                                                    canDeleteResources: event.target.checked,
                                                    canAccessResources: event.target.checked,
                                                    [`${permission + resourceName}`]: event.target.checked,
                                                }))
                                                setValue('canCreateResources', event.target.checked)
                                                setValue('canUpdateResources', event.target.checked)
                                                setValue('canDeleteResources', event.target.checked)
                                                setValue('canAccessResources', event.target.checked)
                                                setValue(permission + resourceName, event.target.checked)
                                            } else if ('isFullProjects' === permission + resourceName) {
                                                setRightValues((prevState) => ({
                                                    ...prevState,
                                                    canCreateProjects: event.target.checked,
                                                    canUpdateProjects: event.target.checked,
                                                    canDeleteProjects: event.target.checked,
                                                    canAccessProjects: event.target.checked,
                                                    [`${permission + resourceName}`]: event.target.checked,
                                                }))
                                                setValue('canCreateProjects', event.target.checked)
                                                setValue('canUpdateProjects', event.target.checked)
                                                setValue('canDeleteProjects', event.target.checked)
                                                setValue('canAccessProjects', event.target.checked)
                                                setValue(permission + resourceName, event.target.checked)
                                            } else {
                                                setRightValues((prevState) => ({
                                                    ...prevState,
                                                    [`${permission + resourceName}`]: event.target.checked,
                                                }))
                                            }
                                        }}
                                    />
                                }
                            />
                        )
                    }
                }
                stacks.push(
                    <Stack
                        key={'stack' + resourceName + roleId}
                        direction="row"
                        alignItems="center"
                        className={'justify-between border border-slate-200 rounded-sm h-10 px-2'}
                    >
                        <p key={'title_' + resourceName + roleId} className="text-slate-500 text-sm">
                            {resourceName}
                        </p>
                        <div key={'checkboxes' + resourceName + roleId} className={'justify-end'}>
                            {checkboxes}
                        </div>
                    </Stack>
                )
            }
            sections.push(
                <div key={'section' + section + roleId} className={'flex-col flex gap-2'}>
                    <p
                        key={'section_title' + section + roleId}
                        className={'text-slate-700 underline mb-4 flex items-center gap-2'}
                    >
                        <span className="border border-slate-500 w-6 h-6 text-xs flex items-center justify-center rounded-full">
                            <FiFolder />
                        </span>
                        {section}
                    </p>
                    {stacks}
                </div>
            )
        }
        return sections
    }

    if (isCreate && isRead) {
        return <Forbidden isInDrawer={true} />
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                <Loader content="Submitting" />
            ) : (
                <>
                    {isLoading ? (
                        <DrawerLoader />
                    ) : (
                        <>
                            {isDisabled && role && (
                                <div className="border border-orange-100 bg-orange-50 text-orange-500 h-14 flex gap-2 items-center justify-center px-4 text-sm rounded-sm">
                                    <p className="w-2/3 grow text-orange-400">
                                        The role{' '}
                                        <span className="text-orange-500 font-bold underline">{role.name}</span> cannot
                                        be modified{' '}
                                        {isRead ? "because you don't have right to do so" : 'as it is a default role'}
                                    </p>
                                    <span className=" order-first w-6 h-6 text-xs flex items-center justify-center border border-orange-400 rounded-full text-orange-400">
                                        <FiAlertTriangle />
                                    </span>
                                </div>
                            )}
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col gap-2">
                                        <label htmlFor="name" className="text-slate-700 text-md font-bold">
                                            Name of role
                                        </label>

                                        <div className="w-full">
                                            <input
                                                type="text"
                                                placeholder="Role name"
                                                {...register('name', {
                                                    required: 'Role name is required',
                                                    disabled: isDisabled,
                                                })}
                                                className=" border border-slate-200 rounded-sm w-full grow h-8 p-2 focus:outline-none focus:ring focus:ring-blue-200"
                                            />
                                            {errors.name && !watch('name') && (
                                                <span className={'text-sm text-red-500/70'} role="alert">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div className="border border-slate-200 bg-slate-50 text-slate-500 h-16 flex gap-2 items-center justify-center px-4 text-sm rounded-sm">
                                        <p className=" text-slate-700 grow">
                                            Use this section to adjust access rights as needed in user management.
                                        </p>
                                        <span className="order-first text-xl text-slate-700">
                                            <FiInfo />
                                        </span>
                                    </div>

                                    {displayCheckboxes()}
                                </div>
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div className="w-full flex flex-col gap-2">
                                        <label htmlFor="name" className="text-slate-700 text-md font-bold">
                                            Users having role
                                        </label>
                                        <fieldset disabled={isDisabled}>
                                            <Controller
                                                name="listUserInfras"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...register('listUserInfras')}
                                                        {...field}
                                                        onChange={(t) => {
                                                            setValue('listUserInfras', t)
                                                        }}
                                                        isMulti
                                                        options={users}
                                                        getOptionLabel={(option) =>
                                                            option.user?.fullName.trim() === ''
                                                                ? option.user?.email
                                                                : option.user?.fullName
                                                        } // Récupère le nom de l'option
                                                        getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                        isSearchable
                                                    />
                                                )}
                                            />
                                        </fieldset>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {!isDisabled && (
                                    <div className="flex items-center gap-4">
                                        {(isCreate || isUpdate) && (
                                            <button
                                                type="submit"
                                                className={`grow border border-slate-200 border-dashed rounded-sm text-slate-700 bg-slate-200 text-md h-10 flex items-center justify-center hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                                            >
                                                {isCreate ? 'Create new role' : 'Save change'}
                                            </button>
                                        )}
                                        {isUpdate && (user.isSuperAdministrator || userInfraRole?.canDeleteRoles) && (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={() => setOpenConfirmation(true)}
                                                    className={`border border-red-200 rounded-full bg-red-100 text-red-500 text-md h-10 w-10 flex items-center justify-center hover:bg-red-500 hover:text-slate-50 transition ease-linear`}
                                                >
                                                    <FiTrash />
                                                </button>
                                                <ConfirmDialog
                                                    isOpen={openConfirmation}
                                                    onClose={() => setOpenConfirmation(false)}
                                                    setIsOpen={setOpenConfirmation}
                                                    onAgree={() => handleDelete()}
                                                />
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </form>
    )
}
