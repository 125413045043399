import {Link} from "react-router-dom";
import React from "react";

export const UserCell = ({userData}) => {

    const userFirstName = userData?.row?.firstname;
    const userLastName = userData?.row?.lastname;
    const userEmail = userData?.row?.email;

    return (
        <div className={"flex w-full flex-wrap gap-2"}>
            <span className={'flex'}>
                {userFirstName}&nbsp;{userLastName}
            </span>
            -
            <Link
                to={`mailto:${userEmail}`}
                target={"_blank"}
                title={userEmail}
                className={"underline hover:no-underline"}
            >
                {userEmail}
            </Link>
        </div>
    )
}