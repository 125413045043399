import {NetworkList} from './NetworkList';
import {useState} from "react";

export const NetworkBox = ({ networkId, setNetworkId, setIsShow }) => {

    const [itemsPerPage, setItemsPerPage] = useState(6);

    return (
        <NetworkList
            itemsPerRow={itemsPerPage}
            setNetworkId={setNetworkId}
            networkId={networkId}
            setIsShow={setIsShow}
            setItemsPerPage={setItemsPerPage}
        />
    )
}
