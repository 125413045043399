import axios from 'axios';

// eslint-disable-next-line no-undef
const api_url = process.env.REACT_APP_API_URL;

class AxiosService {

    static getWithToken(url, token, format = 'json', getFullResponse = false) {
        return axios.get(api_url + url, this.getHeadersWithToken(token, format)).then(resp => {
            if (getFullResponse) {
                return Promise.resolve(resp);
            }
            return Promise.resolve(resp.data);
        }).catch((err) => {
            return Promise.reject(err.response);
        });
    }

    static deleteWithToken(url, token , format) {
        return axios.delete(api_url + url, this.getHeadersWithToken(token, format)).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {
            return Promise.reject(err.response);
        });
    }

    static deleteWithoutToken(url, format) {
        return axios.delete(api_url + url, this.getHeadersWithoutToken(format)).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {
            return Promise.reject(err.response);
        });
    }

    static getWithoutToken(url, format) {
        return axios.get(api_url + url, this.getHeadersWithoutToken(format)).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {
            return Promise.reject(err.response);
        });
    }

    static postWithToken(url, data, format = 'json') {
        return axios.post(api_url + url, data, this.getHeadersWithToken(data["token"] ?? "", format)).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {
            return Promise.reject(err.response);
        });
    }

    static putWithToken(url, data, token, format) {
        return axios.put(api_url + url, data, this.getHeadersWithToken(token ?? "", format)).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {
            return Promise.reject(err.response);
        });
    }

    static postWithoutToken(url, data) {
        return axios.post(api_url + url, data, this.getHeadersWithoutToken()).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {

            return Promise.reject(err.response);
        });
    }

    static putWithoutToken(url, data) {
        return axios.put(api_url + url, data, this.getHeadersWithoutToken()).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {

            return Promise.reject(err.response);
        });
    }

    static putWithoutTokenFormat(url, format) {
        return axios.put(api_url + url, this.getHeadersWithoutToken(format)).then(resp => {
            return Promise.resolve(resp.data);
        }).catch((err) => {

            return Promise.reject(err.response);
        });
    }


    static getHeadersWithToken(token, format) {
        switch (format) {
            case 'form': // `multipart/form-data`
                return {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }
            case 'jsonld': {
                return {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Accept': 'application/ld+json',
                        'Authorization': 'Bearer ' + token,
                    }
                }
            }
            case 'json':
            default: {
                return {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                }
            }
        }
    }


    static getHeadersWithoutToken(format) {
        switch (format) {
            default:
            case 'json': {
                return {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                }
            }
            case 'jsonld': {
                return {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Accept': 'application/ld+json',
                    }
                }
            }
        }

    }
    
}

export default AxiosService;