import React from 'react';


export const FormResourcesSeeAll = ({handleResourceSelectionClick}) => {

    return (
        <div className={'w-full border-t border-zinc-200'}>
            <button type="button"
                    className={'flex items-center mx-auto my-4 text-sm underline hover:no-underline'}
                    onClick={handleResourceSelectionClick}
                    title={'See all selected resources'}
            >
                See all selected resources
            </button>
        </div>
    );
}
