import {motion} from "framer-motion";
import {FiTrash, FiX} from "react-icons/fi";
import {useForm} from "react-hook-form";
import {toast} from "sonner";
import {useEffect, useState} from "react";
import TabsService from "../../service/TabsService";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import ErrorMessageRF from "../../../../components/form/errorMessage/ErrorMessageRF";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const FormTabs = ({ setIsOpenTabs, setOpenEditBox, method, type, id, idTab, myTabs, setSubmitting, setAddedTab, networkInfraDescriptionId, networkSeviceDescriptionId, networkPPAId, idResourceType }) => {
    const [data, setData] = useState(null);

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: "",
            position: 1,
            isHidden: false,
            resourceType: null,
            // resourceType: idResourceType ? idResourceType : null,
            step: null,
            isFromConfig: false,
            networkServiceDescription: networkSeviceDescriptionId ? networkSeviceDescriptionId :  null,
            networkInfrastructureDescriptions: networkInfraDescriptionId ? networkInfraDescriptionId : null,
            networkPPA: networkPPAId ? networkPPAId : null,
        }
    })

    const tabsService = new TabsService();

    const user = useSelector(selectUser);
    const token = user.token;

    useEffect(() => {
        const tabById = myTabs.find(item => item.id === idTab);

        setData(tabById);

        if (method === "PUT" && data) {
            setValue("name", data.name);
            setValue("position", data.position);
            setValue("isHidden", data.isHidden);

            setValue("resourceType", data.resourceType);
            setValue("step", data.step);
            setValue("isFromConfig", data.isFromConfig);

            setValue("networkPPA", data.networkPPA);
            setValue("networkServiceDescription", networkSeviceDescriptionId);
            setValue("networkInfrastructureDescriptions", networkInfraDescriptionId);
            setValue("networkInfrastructureDescriptions", networkPPAId);
        }
    }, [data, method, setValue]);

    const onSubmit = async (mydata) => {
        try {
            const requestData = {
                name: mydata.name,
                position: Number(mydata.position),
                isHidden: mydata.isHidden,

                // resourceType: mydata.resourceType ? `api/resource_types/${id}` : null,
                resourceType: idResourceType ? `api/resource_types/${idResourceType}` : null,
                step: mydata.step,
                isFromConfig: mydata.isFromConfig,

                networkPPA: (type === "ppa") ? `api/network_ppa/config/${networkPPAId}` : null,
                networkInfrastructureDescriptions: (type === "installation") ? `api/network_infrastructure_descriptions/${networkInfraDescriptionId}` : null,
                networkServiceDescription: (type === "service") ? `api/network_service_descriptions/${networkSeviceDescriptionId}` : null,
            }

            let res, message;

            if (method === "POST") {
                res = await tabsService.AddTab(requestData, token);
                message = "Tab was add successfully"
            } else if (method === "PUT") {
                res = await tabsService.EditTab(requestData, idTab, token);
                message = "Tab was updated successfully"
            }

            if (res) {

                reset({
                    name: "",
                    position: 1,
                    isHidden: false,
                    resourceType: null,
                    step: null,
                    isFromConfig: false,
                    networkPPA: null,
                    networkServiceDescription: null,
                })

                setSubmitting(true);
                setIsOpenTabs(false);
                setOpenEditBox(null);

                return toast.success(message);
            }
        } catch (error) {
            console.error(error);
            return toast.error("Something went wrong!");
        }
    }

    const handleCloseTabs = () => {
        if (method === "POST") {
            setIsOpenTabs(false);
        }
        if (method === "PUT") {
            setOpenEditBox(null)
        }
    }

    const handleDeleteTab = async () => {
        if (!idTab) return toast.warning("Ooops! Something went wrong!");

        try {
            const res = await tabsService.DeleteTab(idTab, token);
            if (!res) {

                setIsOpenTabs(false);
                setOpenEditBox(null);
                setSubmitting(true);

                return toast.success("Tab was deleted successfully");
            }

        } catch (error) {
            console.error(error)
            return toast.error("Something went wrong! try later...");
        }
    }


    return (

        <>
            {
                <motion.form
                    className="relative bg-zinc-50 border border-solid border-slate-300 shadow-sm rounded-xl text-slate-500 font-black min-h-20 w-72 py-2 px-4 flex flex-col items-start justify-start gap-4"

                    initial={{opacity: 0, translateY: 15}}
                    animate={{opacity: 1, translateY: 0}}
                    exit={{opacity: 0, translateY: 15}}

                    transition={{type: "spring", duration: .3, ease: "linear"}}

                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="w-full">
                        <label
                            htmlFor="name"
                            className="mb-2 block text-sm text-slate-500"
                        > {errors.name ? <ErrorMessageRF content={errors.name.message}/> : "Name"}</label>
                        <textarea
                            placeholder="Enter new name of tabs..."
                            id="name"
                            autoFocus={true}
                            {...register("name", {required: "This field is required!"})}
                            className="bg-transparent border border-slate-300 px-2 py-1 rounded-md text-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 w-full h-auto resize-none overflow-hidden"
                        />
                    </div>

                    <div className="w-full">
                        <label
                            htmlFor="position"
                            className="mb-2 block text-sm text-slate-500"
                        >Position</label>
                        <input
                            type="number"
                            id="position"
                            min={1}
                            {...register("position")}
                            className="bg-transparent focus:outline-none pl-2 w-full h-10 border border-slate-300 rounded-sm"
                        />
                    </div>

                    <div className="w-full flex-col gap-4">
                        <label
                            htmlFor={`isHidden-${method}`}
                            className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isHidden") ? "text-orange-400 bg-orange-100 border-orange-300" : "text-green-400 bg-green-100 border-green-300"}`}
                        >
                            <input
                                type="checkbox"
                                id={`isHidden-${method}`}
                                value={myTabs?.isHidden}
                                {...register("isHidden")}
                                className="sr-only"
                            />

                            <span className={`${watch("isHidden") ? "border-orange-400 left-0" : "right-0 border-green-400"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                        </label>

                        <span className="mt-2 block w-full grow text-xs text-slate-400 border border-slate-200 rounded-sm p-2 font-light">{watch("isHidden") ? "This tab is currently hidden. To display it, please toggle the switch just above." : "This tab is visible. To change its visibility, please toggle the switch just above."}</span>
                    </div>


                    <div className="flex flex-nowrap items-center justify-between gap-4 w-full">
                        <button
                            type="submit"
                            className="bg-blue-400 border border-blue-500 hover:bg-blue-500 transition ease-linear text-slate-50 font-medium rounded-full p-2"
                        >
                            {method === "POST" ? "Add new tab" : "Edit Tab"}
                        </button>

                        {/* Button to delete tab */}
                        {method === "PUT" &&
                            <>
                                <button
                                    type="button"
                                    onClick={() => setOpenConfirmation(true)}
                                    className="flex text-sm items-center justify-center transition ease-linear hover:border-red-500 hover:bg-red-500 border-red-400 bg-red-400 text-slate-50 border rounded-full w-8 h-8"
                                    title="Delete tab"
                                >
                                    <FiTrash/>
                                </button>
                                <ConfirmDialog
                                    isOpen={openConfirmation}
                                    onClose={() => setOpenConfirmation(false)}
                                    setIsOpen={setOpenConfirmation}
                                    onAgree={() => handleDeleteTab()}
                                />
                            </>


                        }

                    </div>

                    <button
                        type="button"
                        className="hover:bg-slate-100 bg-slate-50 rounded-full p-2 w-10 h-10 flex items-center justify-center text-lg border border-slate-300 absolute -top-4 transform -right-2"
                        onClick={handleCloseTabs}
                    >
                        <FiX/>
                    </button>
                </motion.form>
            }
        </>

)
    ;
};

export default FormTabs;
