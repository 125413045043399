import React, {useEffect, useState} from "react";

// Design
import {BsFillBoxFill} from "react-icons/bs";
import {RichTreeView} from "@mui/x-tree-view";
import {FiFolder} from "react-icons/fi";
import {ResourcesFieldLoader} from "../../../../loaders/Loaders";

// Components
import {FormResourcesDeleteItem} from "../items/FormResourcesDeleteItem";
import {FormResourcesSeeAll} from "../items/FormResourcesSeeAll";


export const FormResourcesFieldView = ({
                                           selectedItems,
                                           setSelectedItems,
                                           listInput,
                                           handleResourceSelectionClick,
                                           listResources,
                                           apiRef,
}) => {

    const [treeItems, setTreeItems] = useState([]);
    const [resourceSelection, setResourceSelection] = useState([]);
    const [launchResourceSelection, setLaunchResourceSelection] = useState(false);
    const [itemsToSee, setItemsToSee] = useState([]);
    const sliceNumber = 8;

    useEffect(() => {
        setTreeItems(listResources['hydra:member'] ?? []);
        setLaunchResourceSelection(true);
    }, [listResources]);


    const getParentList = (rId) => {
        let result = []
        const r = apiRef?.current?.getItem(rId)
        if (undefined !== r && null !== r) {
            result.push(rId)
            if (r.parent) {
                const splittedValue = r.parent.split('/')
                const parentId = splittedValue.pop()
                const curParent = apiRef?.current?.getItem(parentId)
                result.push(parentId)
                if (curParent && curParent.parent) {
                    const splittedValueParent = curParent.parent.split('/')
                    const parentParentId = splittedValueParent.pop()
                    const parentResult = getParentList(parentParentId)
                    parentResult.map((pr) => {
                        return result.push(pr)
                    })
                }
            }
            return result
        }
        return []
    }

    const handleResourceFieldView = () => {

        let detectParentsToExpand = [];
        let restructuredSelection = [];

        for (let item of selectedItems) {
            detectParentsToExpand.push(getParentList(item));
        }

        detectParentsToExpand.forEach((item) => {
            const parentId = item.slice(-1).join();
            restructuredSelection.push({ parentId: parentId, children: selectedItems.filter((selected) => item.includes(selected)) });
        });

        let groupedSelectionByParent = Object.entries(restructuredSelection.reduce((accumulator, obj) => {

            const { parentId, children } = obj;

            if (!accumulator[parentId]) {
                accumulator[parentId] = [];
            }

            accumulator[parentId] = [...new Set([...accumulator[parentId], ...children])];

            return accumulator;

        }, {})).map(([rootParent, selection]) => ({ rootParent, selection }));

        setResourceSelection(groupedSelectionByParent);

        setLaunchResourceSelection(false);

    }

    useEffect(() => {
        handleResourceFieldView();
    }, [treeItems, selectedItems, launchResourceSelection]);


    return (
        <>
            <RichTreeView
                apiRef={apiRef}
                getItemId={(item) => item?.id?.toString()}
                getItemLabel={(item) => item.name + (item.children.length ? ' (' + item.children.length + ')' : '')}
                items={treeItems}
                selectedItems={selectedItems}
                expandedItems={itemsToSee}
                className={'hidden'}
            />

            {(resourceSelection?.find((item) => item.rootParent === '') && selectedItems.length !== 0) && (
                <ResourcesFieldLoader />
            )}

            {(resourceSelection?.find((item) => item.rootParent !== '')) && (
                <div className={'w-full flex flex-wrap justify-between p-2 gap-y-2 border-t border-t-zinc-200'}>
                    {resourceSelection.map((item, index) => {

                        const resourcesFromSelectOptions = [...listInput?.select?.listSelectOptions];
                        const resourceTypeName = resourcesFromSelectOptions.find((found) => found.id === Number(item.rootParent))?.name;

                        return (
                            item.selection !== 0 && (
                                <div key={index} className={`resource-card ${resourceSelection.length > 4 ? 'w-[49%]' : 'w-full'} rounded-lg overflow-hidden border border-blue-100`}>
                                    <div className="resource-card-title flex justify-between items-center lg:gap-2 py-2 md:px-2 lg:px-3 bg-blue-400 border-b border-b-zinc-200 font-bold">
                                        <div className={"flex items-center gap-2 pl-1 pr-0 lg:px-2 py-1 text-white relative"}>
                                            <span className={'w-4'}>
                                                <FiFolder size={18} stroke={'white'} />
                                            </span>
                                            <span className={'relative top-[1px]'}>{resourceTypeName}</span>
                                        </div>
                                        <span className={"bg-white px-2 py-[4px] text-xs font-bold text-blue-500 rounded-full"}>
                                            {item.selection.length}
                                        </span>
                                    </div>
                                    <div className="resource-card-selection flex flex-wrap items-center py-2 gap-2 relative">
                                        {item.selection.slice(0, sliceNumber).map((child) => {
                                            const resourceName = resourcesFromSelectOptions.find((found) => found.id === Number(child))?.name;

                                            return (
                                                <div key={child} className={'p-2 flex items-center mx-2 gap-2 w-fit bg-white rounded-lg border border-slate-200'}>
                                                        <span className={'w-4'}>
                                                            <BsFillBoxFill size={16} className={'text-md text-blue-500'} />
                                                        </span>
                                                    {resourceName}
                                                    <FormResourcesDeleteItem selectedItems={selectedItems}
                                                                             setSelectedItems={setSelectedItems}
                                                                             item={child}
                                                    />
                                                </div>
                                            )
                                        })}

                                        {item.selection.length > sliceNumber && (
                                            <FormResourcesSeeAll handleResourceSelectionClick={handleResourceSelectionClick} />
                                        )}
                                    </div>
                                </div>
                            )
                        )
                    })}
                </div>
            )}
        </>
    );
};