import * as React from "react";
import {useState} from "react";
import {useParams} from "react-router-dom";

// Design
import {motion} from "framer-motion";

// Components
import {Loader} from "../../../../components/loader/Loader";
import {CompCustom} from "../../../network/components/CompCustom";
import NetworkHeader from "../../../network/components/NetworkHeader";

// Redux
import {createSelector} from "reselect";
import {useSelector} from "react-redux";

// Custom Hooks
import {useFetchOneResourceTypeConfig} from "../../hooks/useFetchResourceType";


export const ResourceTypeConfiguration = () => {

    const { id } = useParams();

    const [filters, setFilters] = useState([
        { id: 1, title: "Hidden", checked: false },
        { id: 2, title: "Visible", checked: false }
    ]);

    // LengthofTabFilters
    const [lengthofTabFilters, setLengthofTabFilters] = useState(0);

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const user = useSelector(selectUser);

    const resourceTypeId = id;
    const [isLoading, setIsLoading] = useState(true);

    const {resourceType, fetchResourceTypeConfig} = useFetchOneResourceTypeConfig(resourceTypeId, user?.token, 0, setIsLoading);


    return (
        <motion.article
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}

            transition={{type: "spring", duration: .5, ease: "easeIn"}}
            className="relative overflow-hidden"
            style={{ height: `calc(100vh - 80px)` }}
        >
            <div className="container h-full">
                <div className={`wrapper relative flex flex-col items-start gap-4`}>
                    <div className={`flex items-start gap-2 w-full h-full overflow-x-hidden `}>
                        {(isLoading || null === resourceType) ?
                            (<Loader content="Loading" hfull />)
                            :
                            (
                                <div className={`w-2/5 grow bg-white flex flex-col gap-0 h-full drop-shadow-md`}>

                                    <NetworkHeader
                                        filters={filters}
                                        setFilters={setFilters}
                                        dataTitle={resourceType?.name}
                                        lengthofTabFilters={lengthofTabFilters}
                                    />

                                    <div className={`relative flex justify-center overflow-y-hidden h-full `}>
                                        <div className="wrapper pt-10 flex flex-col items-start h-full relative">
                                            <CompCustom
                                                id={id}
                                                // networkId={networkId}
                                                filters={filters}
                                                // type="resourceType"
                                                type="resource"
                                                setLengthofTabFilters={setLengthofTabFilters}
                                                data={resourceType}
                                                isLoading={isLoading}
                                                fetchData={fetchResourceTypeConfig}
                                            />
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </motion.article>
    )
};

