import {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import SelectService from "../../../services/SelectService";

export const UseFetchSelect = (networkId, currentInfrastructure, increment) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = useSelector(state => state.login.user?.token) ?? null;

    useEffect(() => {
        const fetchSelect = async () => {
            try {
                setIsLoading(true);

                // if (null !== networkId) {
                //     const selectService = new SelectService();
                //     const res = await selectService.getSelectFromNetwork(networkId, token);
                //     setData(res['hydra:member']);
                // } else {
                //     setData([]);
                // }

                const selectService = new SelectService();

                let res;

                // Config Network Selects (ex: Descriptions & PPA)
                if (networkId) {
                    res = await selectService.getSelectFromNetwork(networkId, token);
                }

                // Config Infrastructures Selects (ex: Resource Types)
                if (!networkId && currentInfrastructure) {
                    res = await selectService.getSelectConfigFromInfrastructure(currentInfrastructure, token);
                }

                setData(res['hydra:member']);

            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        }
        fetchSelect().then();
    }, [
        networkId,
        increment,
        token,
        currentInfrastructure
    ]);

    return {data, isLoading, error};
}