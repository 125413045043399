export const orderTreeByNameAndParentFirst = (loadedTree) => {

    loadedTree.forEach((item) => {
        if (item.children.length !== 0) {
            const currentChildren = item.children;
            currentChildren
                .sort((a, b) => -b?.name.localeCompare(a.name))
                .sort((a, b) => {
                    if (a.children.length > 0 && b.children.length === 0) {
                        return -1
                    }
                    if (a.children.length === 0 && b.children.length > 0) {
                        return 1
                    }
                    return 0;
                });
            if (currentChildren && currentChildren?.length !== 0) {
                orderTreeByNameAndParentFirst(currentChildren);
            }
        }
    });

}