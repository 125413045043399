import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";

// Design
import {FiEdit, FiMoreVertical, FiSettings} from "react-icons/fi";
import {AnimatePresence, motion} from "framer-motion";

// Custom Hooks
import {useCloseOnOutsideClick} from "../../../hooks/useCloseOnOutsideClick";


export const ResourceTypeItemActions = ({ item, handleClickPath }) => {

    const [showActions, setShowActions] = useState(false);
    const buttonClasses = 'flex items-center text-slate-700 bg-white gap-2 p-3 min-w-28 max-w-1/2 rounded-md cursor-pointer text-sm font-semibold';

    const actionsRef = useRef(null);
    useCloseOnOutsideClick(actionsRef, setShowActions);


    return (
        <div className={`actions-card absolute top-0 right-0 m-3 text-slate-500`}>
            <button type="button"
                    className={`flex justify-center items-center h-6 w-6 rounded-full hover:bg-neutral-100 transition-all duration-300 text-white hover:text-slate-600`}
                    onClick={() => setShowActions(true)}
            >
                <FiMoreVertical size={20} />
            </button>

            <AnimatePresence>
                {showActions && (
                    <motion.div initial={{y: -30, opacity: 0}}
                                transition={{duration: .2}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: -30, opacity: 0}}
                                className={`border border-zinc-100 bg-white rounded-lg absolute top-0 right-0`}
                    >
                        <div className={"actions flex flex-col relative"} ref={actionsRef}>
                            <ul>
                                <li className={'border-b-2 border-neutral-100'}>
                                    <button type={"button"}
                                            className={`hover:text-amber-600 hover:bg-amber-50  ${buttonClasses}`}
                                            title={'Edit Resource Type'}
                                            onClick={() => handleClickPath(item.id)}>
                                        <FiEdit />
                                        Edit
                                    </button>
                                </li>
                                <li className={'border-b border-zinc-200 last-of-type:border-0'}>
                                    <Link to={`${item.id}`}
                                          type={"button"}
                                          className={`hover:text-green-600 hover:bg-green-50  ${buttonClasses}`}
                                          title={'Configure Resource Type'}
                                    >
                                        <FiSettings />
                                        <span>Configure</span>
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

        </div>
    )
};

