import React, {useContext, useEffect, useRef, useState} from "react";
import {Field} from "react-final-form";

// Design
import {Grid, IconButton} from "@mui/material";
import {AnimatePresence, motion} from "framer-motion";
import {CustomizedInputLabel, CustomizedTextField} from "./FormCustomizedInputs";
import {FormLabelTooltip} from "./FormLabelTooltip";
import {FiTrash, FiUpload} from "react-icons/fi";
import {IoInformationCircleOutline} from "react-icons/io5";

// Context
import {ListTypeFormContext} from "./listtypeform/ListTypeFormsContainer";
import {FormContentFormsContext} from "../FormContentForms";

// Custom Functions
import {missingFieldsChecking} from "../../../utils/CustomFunctions";
import {FormFileRemoveConfirm} from "./FormFileRemoveConfirm";


// Import React FilePond
// import { FilePond, registerPlugin } from 'react-filepond'
//
// // Import FilePond styles
// import 'filepond/dist/filepond.min.css'
//
// // Import the Image EXIF Orientation and Image Preview plugins
// // Note: These need to be installed separately
// // `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
//
//
// // Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


export const FormImageUpload = ({ id,
                                   name,
                                   description,
                                   inputIndex,
                                   label,
                                   required,
                                   isBlocked,
                                   hide,
                                   values,

                                   keyLinkValue,
                                    isRead
                               }) => {

    const [imageName, setImageName] = useState('');
    const [imageSize, setImageSize] = useState(0);
    const [selectedFile, setSelectedFile] = useState('');

    // Errors
    const [imageTypeError, setImageTypeError] = useState(false);
    const [invalidImageSize, setInvalidImageSize] = useState(0);
    const [imageSizeError, setImageSizeError] = useState(false);

    // File size
    const IMAGE_FILE_LIMIT = 20;
    const IMAGE_FILE_UNIT = 'MB';

    // Remove File Confirmation
    const [removeFileConfirm, setRemoveFileConfirm] = useState(false);


    // Refs
    const hiddenFileInput = useRef(null);
    const visibleFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    }

    // From Context (Cancel Field Management)
    const listTypeFormContext = useContext(ListTypeFormContext);
    const {
        cancelFieldsData: cancelFieldsDataContext,
        setCancelFieldsData: setCancelFieldsDataContext,
        remainingLinesData: remainingLinesDataContext,
        setRemainingLinesData: setRemainingLinesDataContext
    } = listTypeFormContext !== null && listTypeFormContext;

    // From Context (Missing Field Management)
    const formContentFormsContext = useContext(FormContentFormsContext);
    const {
        reloadMissingFieldsChecking: reloadMissingFieldsCheckingContext,
        setReloadMissingFieldsChecking: setReloadMissingFieldsCheckingContext,
    } = formContentFormsContext !== null && formContentFormsContext;


    // Avoid duplicate id Browser Issue
    const fieldUniqueId = inputIndex + '_' + id


    // File Conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result.slice(fileReader.result.indexOf(',') + 1));
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const uploadImage = async (imagefile) => {
        return await convertBase64(imagefile);
    };


    const handleChangeValue = () => {
        const fileName = hiddenFileInput.current.files[0]?.name;
        const imageFile = hiddenFileInput.current.files[0];

        if (!imageFile) { return }

        // File Type & Size
        // const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        const imageFileSize = imageFile?.size;
        const imageFileSizeInKB = (imageFileSize/1024/1024); // converted byte into kilobyte

        // File Size
        if (imageFileSizeInKB > IMAGE_FILE_LIMIT) {

            setImageSizeError(true);
            // imageTypeError && setImageTypeError(false);
            setInvalidImageSize(imageFileSizeInKB)

        }
        // File Valid
        else {

            setImageName(fileName);
            setImageSize(imageFileSizeInKB)

            uploadImage(imageFile).then(function(result) {
                result && setSelectedFile(`${result}`)
            }, function(err) {
                console.log(err);
            });

            setImageTypeError(false);
            setImageSizeError(false);
        }
    }


    const imageValueSetAfterSave = () => {

        if (localStorage.listTypeItem) {
            const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
            const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)

            // Restore data for remaining lines after a cancel or a line removal
            for (let i = 0; i < listTypeItemFromStorageArray.length; i++) {
                if ((listTypeItemFromStorageArray[i].input) === ('/api/inputs/' + id) &&
                    (listTypeItemFromStorageArray[i].keyLink === keyLinkValue)) {

                    // Value & Name
                    const loadedFileNameValueFromStorage = (typeof listTypeItemFromStorageArray[i].value !== "string") ? "" : listTypeItemFromStorageArray[i].value;
                    setImageName(loadedFileNameValueFromStorage)

                    // File Image
                    const loadedFileImageFromStorage = (typeof listTypeItemFromStorageArray[i].file !== "string") ? "" : listTypeItemFromStorageArray[i].file;
                    setSelectedFile(loadedFileImageFromStorage)

                }
            }
        }

        if (listTypeFormContext !== null) {
            setCancelFieldsDataContext(false);
            setRemainingLinesDataContext(false);
        }
    }

    const getFileNameOnly = (fileName) => {
        return fileName?.split('/').pop() ?? "";
    }

    const handleFileRemoveConfirmation = () => {
        setRemoveFileConfirm(true)
    }

    const handleRemoveFile = () => {
        setSelectedFile('')
        setImageName('')

        values.listInputValues[inputIndex].value = '';
        values.listInputValues[inputIndex].file = '';
        values.listInputValues[inputIndex].fileName = '';

        setRemoveFileConfirm(false)
    }

    // Retrieve Values
    const retrieveExistingListInputValues = () => {
        if (values.listInputValues[inputIndex]) {
            if (typeof values.listInputValues[inputIndex].value === "string" && values.listInputValues[inputIndex].value !== "") {

                // Value & Name
                const loadedFileNameValue = values.listInputValues[inputIndex].value;
                setImageName(loadedFileNameValue)
                setSelectedFile(loadedFileNameValue)

                // File Image
                // const loadedFileImage = values.listInputValues[inputIndex].file;
                // setSelectedFile(loadedFileImage)

            }
        }
    }

    useEffect(() => {
        (values.token !== null && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    }, [values]);


    // Values assigning
    useEffect(() => {

        if (values.token !== null && values.listInputValues[inputIndex]) {
            if(selectedFile !== '' && imageName !== '') {
                values.listInputValues[inputIndex].value = imageName;
                values.listInputValues[inputIndex].file = selectedFile ?? "";
                values.listInputValues[inputIndex].fileName = imageName;
            }

        } else {

            (selectedFile !== '') ?
                values.listInputValues[inputIndex] = { value : imageName, file: selectedFile, fileName: imageName  } :
                values.listInputValues[inputIndex] = { value : '', file: '', fileName: '' };
            Object.assign(values.listInputValues[inputIndex], { keyLink: keyLinkValue, input: '/api/inputs/' + id });

        }

    }, [selectedFile, imageName]);


    // Required Fields Detection
    useEffect(() => {
        missingFieldsChecking(hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, selectedFile === '', visibleFileInput);
    }, [selectedFile]);


    // List Type Form Saved Data Verification
    useEffect(() => {
        if (cancelFieldsDataContext || remainingLinesDataContext) {
            localStorage.listTypeItem && imageValueSetAfterSave();
        }
    }, [cancelFieldsDataContext, remainingLinesDataContext]);


    return (
        <Grid item
              xs={12}
              lg={6}
              hidden={hide}
              className={`${!hide && 'flex-grid-input'} relative`}
        >
            <Field name={ name }
            >
                {props => (
                    <>
                        <CustomizedInputLabel shrink
                                              htmlFor={`${fieldUniqueId}`}
                        >
                            {label} {required && " *"}

                            {description && (
                                <FormLabelTooltip title={<div dangerouslySetInnerHTML={{ __html: description }} />}>
                                    <span>
                                        <IoInformationCircleOutline className={"mt-[1.6px] text-blue-500 text-xl"} />
                                    </span>
                                </FormLabelTooltip>
                            )}
                        </CustomizedInputLabel>

                        <div title={`See current logo (${getFileNameOnly(imageName)})`}
                              className={'flex justify-between w-full mb-2 px-3'}
                        >
                            <a href={process.env.REACT_APP_API_URL + imageName}
                               target="_blank"
                               rel="noopener noreferrer"
                               className={'w-11/12 break-words'}
                            >
                                <span className={'text-sm'}>{getFileNameOnly(imageName)}</span>
                            </a>
                            {(selectedFile !== '' &&
                              getFileNameOnly(imageName) !== '' &&
                              !isRead) && (
                                <motion.button
                                    className="self-end cursor-pointer hover:border-red-500 hover:bg-red-500 border-red-400 bg-red-400 rounded-full p-[6px] flex items-center justify-center transition ease-linear border ml-1"
                                    whileTap={{scale: .945}}
                                    whileHover={{scale: 1.045}}
                                    transition={{duration: 0.6, ease: "ease-in", type: "spring"}}
                                    onClick={() => handleFileRemoveConfirmation()}
                                    type="button"
                                >
                                    <div className="text-sm text-slate-50 font-bold"><FiTrash/></div>
                                </motion.button>
                            )}

                            {/* File Remove Confirmation */}
                            {removeFileConfirm && (
                                <FormFileRemoveConfirm setRemoveFileConfirm={setRemoveFileConfirm}
                                                            handleRemoveFile={handleRemoveFile}
                                />
                            )}
                        </div>

                        <IconButton component="div"
                                    onClick={isRead ? () => {
                                    } : handleClick}
                                    sx={{ padding: '0', borderRadius: '20px', width: '100%' }}
                        >
                            <div className={`upload-file border border-blue-200 border-dashed rounded-full flex items-center justify-between w-full h-12 pl-4 pr-3 ${isBlocked && 'bg-gray-200 bg-opacity-50'}`}
                                ref={visibleFileInput}
                            >
                                <span
                                    className="w-[90%] overflow-hidden whitespace-nowrap overflow-ellipsis text-xs lg:text-sm text-slate-500 text-left">
                                    {imageName ? (
                                            <>
                                                {/* TODO - Remove condition when API value (for filename) is fixed */}
                                                {getFileNameOnly(imageName) !== '' ? getFileNameOnly(imageName) : 'Last uploaded image'}
                                                <span className={"italic text-xs ml-1"}>
                                                    {imageSize > 0 && ('(' + (Number.parseFloat(imageSize).toFixed(2)) + ' ' + IMAGE_FILE_UNIT + ')')}
                                                </span>
                                            </>
                                        ) :
                                        'Choose a file to upload'
                                    }
                                </span>
                                <motion.div
                                    className="border border-blue-200 border-solid rounded-full p-2 bg-blue-50 cursor-pointer"
                                    whileTap={{ scale: .945 }}
                                    whileHover={{ scale: 1.045 }}
                                    transition={{ duration: 0.6, ease: "ease-in", type: "spring" }}
                                >
                                    <span className="text-sm text-blue-500 font-bold"><FiUpload /></span>
                                </motion.div>
                            </div>
                        </IconButton>

                        <CustomizedTextField
                            name={props.input.name}
                            value={undefined}
                            type="file"
                            onChange={handleChangeValue}
                            id={`${fieldUniqueId}`}
                            disabled={isRead || isBlocked}
                            inputProps={{
                                'data-inputid': `${id}`
                            }}

                            // file upload only
                            inputRef={hiddenFileInput}
                            sx={{ display: 'none' }}
                        />

                        <AnimatePresence>
                            {(imageTypeError || imageSizeError) && (
                                <motion.div
                                    initial={{ x: -80, opacity: 0 }}
                                    animate={{ x: 6, opacity: 1 }}
                                    transition={{ duration: .3 }}
                                    exit={{ x: -80, opacity: 0 }}
                                    className={'field-error text-white bg-[#FE5353] py-2 px-3 rounded-[4px] absolute bottom-[-40px] text-sm'}
                                >
                                    {imageSizeError &&
                                        <span>File size ({(Math.round(invalidImageSize) > 1024 ? Math.round(invalidImageSize/1024) : Math.round(invalidImageSize)) + ' ' + IMAGE_FILE_UNIT}) is larger than maximum of {IMAGE_FILE_LIMIT + ' ' + IMAGE_FILE_UNIT}</span>}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </>
                )}
            </Field>
        </Grid>
    )
}
