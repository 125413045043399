// Design
import {Grid} from '@mui/material'

// Components
import { Tables } from '../../../components/tables/Tables'
import ItemDescriptif from '../../catalog/components/ItemDescriptif'
import BoxListDescriptif from "../../catalog/components/BoxListDescriptif";
import {CollapsibleFields} from "../collapsible-fields";

// Utils
import {dataToArray} from "../../../utils/dataToArray";


export const ServicesEoscFields = ({ data, isVisible }) => {

    return (
        <Grid container columnSpacing={{ xs: 2, lg: 4 }} sx={{ margin: '0 0 20px' }} gap={0}>

            {/* Specifique Infos */}
            {data.functionalities &&
                <CollapsibleFields
                    title="Specific Information"
                    content={
                        <BoxListDescriptif widthFull={false}>
                            <div className={`text-slate-700 py-3`} dangerouslySetInnerHTML={{__html: data.functionalities}}/>
                        </BoxListDescriptif>
                    }
                />
            }

            {/* User benefits */}
            {data.usersBenefits &&
                <CollapsibleFields
                    title="Users benefits"
                    content={
                        <BoxListDescriptif widthFull={false}>
                            <div className={`text-slate-700 py-3`} dangerouslySetInnerHTML={{__html: data.usersBenefits}}/>
                        </BoxListDescriptif>
                    }
                />
            }

            {/* Users producing gains */}
            {data.usersProducingGains &&
                <CollapsibleFields
                    title="Users producing gains"
                    content={
                        <BoxListDescriptif widthFull={false}>
                            <div className={`text-slate-700 py-3`} dangerouslySetInnerHTML={{__html: data.usersProducingGains}}/>
                        </BoxListDescriptif>
                    }
                />
            }

            {data.multimedia && data.multimedia.length !== 0 && (
                <CollapsibleFields title="Multimedia"
                                   content={<Tables tab={dataToArray(data.multimedia)} />}
                />
            )}

            {data.useCases && data.useCases.length !== 0 && (
                <CollapsibleFields title="Use cases"
                                   content={<Tables tab={dataToArray(data.useCases)} />}
                />
            )}

            {/* Resources Information */}
            {isVisible && (
                <CollapsibleFields title="Resources Information"
                                   content={<Tables tab={data.listResources} />}
                />
            )}

            {data.helpfulLinks && (
                <CollapsibleFields
                    title="Helpful links"
                    content={
                        <BoxListDescriptif widthFull={false}>
                            {data?.helpfulLinks.map((item) => (
                                <ItemDescriptif key={`${item.name}`}
                                                dataTitle={`${item.name}`}
                                                dataInfo={item.url}
                                                path={item.url}
                                />
                            ))}
                        </BoxListDescriptif>
                    }
                />
            )}

        </Grid>
    )
}