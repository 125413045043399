import {useParams} from "react-router-dom";
import {Service} from "../../service/pages/Service";
import {Installation} from "../../installation/page/Installation";
import { useFetchNetworkById } from '../../network/hooks/useFetchNetworkById'
import { NotFound } from '../../../pages/NotFound/NotFound'
import React from 'react'
import { Loader } from '../../../components/loader/Loader'

export const CatalogDescriptif = () => {

    const { id, typecatalog } = useParams();

    const {error: networkError, data, isLoading} = useFetchNetworkById(id);

    if (isLoading) {
        return <Loader content="Loading" />
    }

    if ((networkError && networkError.status === 404) || (data && !data.displayInCatalog)) {
        return <NotFound backString="Browse another catalog" backLink={`/catalog`} />
    }

    if (typecatalog === "services") return <Service type="service"/>

    if (typecatalog === "installations") return <Installation type="installations" />
}