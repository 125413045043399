import { PreviewImage } from './card/PreviewImage'
import { EventsCalendar } from './card/EventsCalendar'

import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useFetchLinksByResourceLinkTo, useFetchLinksByResourceOne } from '../../hooks/useFetchLinksByResourceId'
import { Loader } from '../../../../components/loader/Loader'
import { TreeLink } from '../link/TreeLink'
import React, { useEffect, useState } from 'react'

export const Overview = ({ resource }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )

    const user = useSelector(selectUser)
    const { links: linkedTo, isLoading: isLoadingLinkedTo } = useFetchLinksByResourceOne(resource.id, user.token, 0)
    const { links: linkedBy, isLoading: isLoadingLinkedBy } = useFetchLinksByResourceLinkTo(resource.id, user.token, 0)
    const [links, setLinks] = useState([])
    useEffect(() => {
        if (!isLoadingLinkedTo & !isLoadingLinkedBy) {
            setLinks([...linkedTo, ...linkedBy])
        }
    }, [isLoadingLinkedBy, isLoadingLinkedTo, linkedBy, linkedTo])

    return (
        <div className="resource-overview grid grid-cols-1 lg:grid-cols-2 gap-4 min-h-[600px]">
            <PreviewImage resource={resource} />
            <div className="grid grid-rows-2 gap-4">
                <EventsCalendar resource={resource} />
                {isLoadingLinkedTo && isLoadingLinkedBy ? <Loader /> : <TreeLink links={links} resource={resource} />}
            </div>
        </div>
    )
}
