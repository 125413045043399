import {Brand} from "../../Brand";
import {RightMenu} from "../../../features/admin/components/menu/RightMenu";
import {PlatformList} from "../../../features/platform/component/PlatformList";
import * as React from "react";
import {useMobile} from "../../../hooks/useMobile";
import {useEffect, useState} from "react";
import {MainMenu} from "../../MainMenu/MainMenu";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {isLoginRoute} from "../../../utils/variables";
import {useFetchOneUserInfra} from "../../../features/user/hook/useFetchUserInfra";
import Burger from "../../header/Burger";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const Header = ({ auth, isNetworkConfig }) => {
    const [show, setShow] = useState(false);
    const [scroll, setScroll] = useState(false);

    const [currentPlatform, setCurrentPlatform] = useState(false);

    const user = useSelector(selectUser);

    const [isLoading, setIsLoading] = useState(true);
    const [increment, setIncrement] = useState(1);

    const userInfraId = useSelector((state) => state.platformReducers?.userInfra?.currentUserInfra?.id) ?? 0;
    const {userInfra} = useFetchOneUserInfra(userInfraId, user?.token, increment, setIsLoading);

    const isMobile = useMobile();

    // Change background header
    const changeBack= () => {
        window.scrollY >= 100 ? setScroll(true) : setScroll(false)
    }

    window.addEventListener('scroll', changeBack );

    // overflow the body
    if (show) {
        document.querySelector('body').style.overflow = 'hidden';
    } else {
        document.querySelector('body').style.overflow = '';
    }

    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );

    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    useEffect(() => {
        if (currentInfrastructure !== null && currentInfrastructure !== 0) {
            setCurrentPlatform(true)
        } else {
            setCurrentPlatform(false)
        }
    }, [currentInfrastructure, currentPlatform]);

    useEffect(() => {
        setIncrement(increment+1);
    }, [userInfraId]);

    const userRoleInInfra = userInfra?.role?.name;
    const userListTeamInfra = userInfra?.listTeams;

    return (
        <header className={`flex lg:h-20 min-h-16 items-center justify-center z-20 w-full lg:border-b lg:relative fixed bg-white top-0 left-0 border-slate-100 shadow-sm shadow-slate-200`}>
            <div className={`${isMobile ? "w-full" : "container"} items-center flex flex-col justify-center h-full`}>
                <div className={`${ !isNetworkConfig ? "w-full" : isMobile ? "w-full" : "wrapper"} flex md:flex-nowrap flex-wrap lg:flex-row lg:gap-0 gap-4 items-center py-4 relative h-full`}>
                    <div className={`grid grid-rows-1 ${!auth ? "grid-cols-[50px_1fr]" : "grid-cols-[50px_1fr_1fr] sm:grid-cols-[50px_2fr_1fr] lg:grid-cols-[50px_1fr_2fr_1fr]"}
                                    justify-center items-center w-full gap-1 lg:gap-8 h-12 relative
                                    px-1 sm:px-2 lg:p-0`
                    }>
                        <Brand auth={auth}/>
                        <MainMenu
                            show={show}
                            setShow={setShow}
                            isMobile={isMobile}
                            isLoading={isLoading}
                            auth={auth}
                            userRoleInInfra={userRoleInInfra}
                            userListTeamInfra={userListTeamInfra}
                            currentPlatform={currentPlatform}

                        />

                        { isLoginRoute && auth && <PlatformList /> }

                        { isMobile && !auth && <Burger setShow={setShow} show={show}/> }

                        { isLoginRoute && auth &&
                            <RightMenu
                                currentPlatform={currentPlatform}
                                setShow={setShow}
                                show={show}
                                isLoading={isLoading}
                                userListTeamInfra={userListTeamInfra}
                                userRoleInInfra={userRoleInInfra}
                            />
                        }

                    </div>

                </div>
            </div>
        </header>

    )
}