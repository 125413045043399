import {Typography} from "@mui/material";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {formatDateToCustom} from "../../../utils/CustomDate";
import React, {useEffect, useState} from "react";
import {HexaLoader} from "../../ppa/components/loaders/Loaders";
import DocumentsService from "../../../services/DocumentsService";
import DashboardUserCalendar from "../components/DashboardUserCalendar";
import {TicketBox} from "../components/TicketBox";
import {InfraTaskBox} from "../components/InfraTaskBox";
import {NoteBox} from "../components/NoteBox";
import {ProjectTaskBox} from "../components/ProjectTaskBox";
import {EventResourceBox} from "../components/EventResourceBox";
import {ActivitiesBox} from "../components/ActivitiesBox";
import {Loader} from "../../../components/loader/Loader";

import Banner from "../../../assets/images/bannerdashboard.webp";
import {FiArrowUp, FiClipboard, FiDroplet, FiEdit, FiFileMinus, FiLayers, FiLock, FiPenTool} from "react-icons/fi";
import {toast} from "sonner";

export const Dashboard = () => {
    ////Start init state ////
    const [isLoading, setIsLoading] = useState(true);
    const [documents, setDocuments] = useState(null);
    const [ticketData, setTicketData] = useState(null);
    const [infraTaskData, setInfraTaskData] = useState(null);
    const [projectTaskData, setProjectTaskData] = useState(null);
    const [eventResourceData, setEventResourceData] = useState(null);

    const [isPreview, setIsPreview] = useState(false);

    const navigate = useNavigate();

    const [activemenu, setActivemenu] = useState(1);
    ////End init state ////

    ///// Start get user / infra  ///////
    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );
    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);
    ///// End get user / infra  ///////

    /////////Start get infra task data from infraTaskBox component ////////////////
    const handleProjectTaskData = (data) => {
        setProjectTaskData(data);
    };
    /////////End get infra task data from infraTaskBox component ////////////////


    /////////Start get infra task data from infraTaskBox component ////////////////
    const handleInfraTaskData = (data) => {
        setInfraTaskData(data);
    };
    /////////End get infra task data from infraTaskBox component ////////////////


    /////////Start get ticket data from ticketBox component ////////////////
    const handleTicketData = (data) => {
        setTicketData(data);
    };
   ////////End get ticket data from ticketBox component ////////////////

    /////////Start get event resource data from eventResourceBox component ////////////////
    const handleEventResourceData = (data) => {
        setEventResourceData(data);
    };
    /////////End get event resource data from eventResourceBox component ////////////////



    ///// Start charter sign verification ///
    useEffect(() => {
        setIsLoading(true);
        const fetchDocumentsUnsigned = async () => {
            try {
                const documentService = new DocumentsService();
                const res = await documentService.getDocumentsUnsigned(currentInfrastructure, user?.token);
                setDocuments(res);
            } catch (error) {
                navigate('/');
            }
            setIsLoading(false);
        }
        fetchDocumentsUnsigned();
    }, [currentInfrastructure, navigate, user]);

    // If many charters to sign, redirect to the page
    if (documents && documents["hydra:totalItems"] > 0) {
        navigate('/charter/sign');
    }
    ////// End charter sign verification ///

    const handleClickMenuActive = (id) => {

        if (id !== 1) return toast.info("The dashboard is currently being built.")
        setActivemenu(id);
    }

    const handlePreview = () => {
        setIsPreview(!isPreview)
    }

    if(isLoading) return <Loader content="Loading dashboard"/>

    return (
        <article className="min-h-screen pt-24 lg:pt-10">
            <div className="container">
                <div className="wrapper">
                    <div className="relative h-[500px] flex justify-between items-center mb-10 border border-slate-200 rounded-2xl overflow-hidden">

                        <div className="text-center absolute top-8 left-1/2 transform -translate-x-1/2 z-10 text-slate-50 max-w-[500px] flex items-center flex-col gap-2">
                            <h2 className="text-5xl font-bold mb-4">{user.fullName}</h2>
                            <span className="text-sm order-first">{formatDateToCustom(new Date())}</span>
                            <p className="text-sm text-slate-100 mb-12">Welcome to your personal space, where you can track
                                your progress, manage tasks, and stay organized with ease. This dashboard provides quick
                                access to updates, insights, and tools to help you stay productive every day.</p>

                            <button
                                className="flex gap-4 items-center border border-slate-200 max-w-32 h-12 w-32 rounded-full relative"
                                onClick={handlePreview}
                            >
                                <span className="text-slate-50 text-sm absolute left-2">Preview</span>

                                <span className={`border h-8 w-8 flex items-center justify-center text-slate-200 text-lg rounded-full absolute transition ease-in-out duration-75 right-2 ${!isPreview ? "bg-transparent border-slate-200 " : "bg-blue-500 border-blue-500"}`}><FiDroplet/></span>
                            </button>
                        </div>

                        <div className="absolute left-0 top-0 h-96 w-full">
                            <figure className="h-[500px]">
                                <img src={Banner} alt="Banner dashboard"
                                     className={"w-full h-full object-cover brightness-95"}/>
                            </figure>
                        </div>

                        <div className="h-20 absolute bottom-0 w-full z-10 text-slate-50 backdrop-blur-sm">
                            <ul className="flex items-center gap-10 h-full justify-center font-bold text-slate-50">
                                {menuDashbaord.map(item =>
                                    <li key={item.id} onClick={() => handleClickMenuActive(item.id)}
                                        className={`${activemenu === item.id ? "bg-slate-50 text-slate-700" : "bg-transparent"} h-10 flex items-center justify-center text-sm px-4 rounded-full cursor-pointer ${item.id !== 1 && "cursor-not-allowed"}`}>{item.title}</li>
                                )}
                            </ul>
                        </div>

                    </div>


                    {/*<div className="flex justify-center gap-10 min-h-[650px]">*/}
                    {/*    <div className="w-4/12 grow">*/}
                    {/*        <DashboardUserCalendar allEventResource={eventResourceData} allProjectTask={projectTaskData}*/}
                    {/*                               currentInfraTask={infraTaskData} allTicketEvent={ticketData}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="w-4/12 flex flex-col gap-10 justify-between">*/}
                    {/*        <ProjectTaskBox onDataReceived={handleProjectTaskData}/>*/}
                    {/*        <TicketBox onDataReceived={handleTicketData}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="flex justify-center gap-10">*/}
                    {/*    <div className="w-4/12 grow flex  gap-10">*/}
                    {/*        <EventResourceBox onDataReceived={handleEventResourceData}/>*/}
                    {/*        <NoteBox/>*/}
                    {/*        <InfraTaskBox onDataReceived={handleInfraTaskData}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="flex justify-center gap-10">*/}
                    {/*    <ActivitiesBox/>*/}
                    {/*</div>*/}

                    {!isPreview ?
                        <div className="min-h-72 flex items-center justify-center">
                            <span className="text-sm text-slate-500">Click on <span className="font-bold underline">"Preview" </span>to view the new design under construction.</span>
                        </div>
                        :
                        <div className="flex gap-2 flex-wrap items-stretch h-screen ">
                            <div className="flex flex-col gap-4 w-1/3">
                                <div
                                    className="cursor-not-allowed bg-white w-11/12 min-h-[500px] rounded-lg drop-shadow-sm border border-slate-100 relative overflow-hidden flex items-center justify-center">
                                <span
                                    className="text-lg border border-slate-300 h-14 w-14 flex items-center justify-center rounded-full text-slate-300"><FiLock/></span>
                                    <span className="bg-blue-500 h-2 absolute w-full top-0 left-0"></span>
                                </div>
                            </div>
                            <div className="w-1/3 grow flex flex-col gap-10">
                                <div>
                                    <ul className="flex gap-4">
                                        {dataBox.map(item =>
                                            <li key={item.id}
                                                className="bg-white h-52 border border-slate-100 shadow-sm w-1/3 rounded-2xl overflow-hidden flex flex-col">
                                                <div
                                                    className="border-b border-slate-200 h-14 flex items-center gap-2 px-4">
                                                    <p className="text-sm text-slate-500">{item.title}</p>
                                                    <span
                                                        className="border border-slate-300 rounded-full h-8 w-8 text-slate-50 bg-slate-950 order-first flex items-center justify-center">{item.icon}</span>
                                                </div>
                                                <div
                                                    className="grow flex items-center justify-center cursor-not-allowed">
                                                    <span className="text-lg text-slate-300"><FiLock/></span>
                                                </div>
                                            </li>
                                        )}

                                    </ul>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div
                                        className="min-h-[500px] border border-slate-100 rounded-2xl shadow-sm flex items-center justify-center cursor-not-allowed">
                                    <span
                                        className="text-lg border border-slate-300 h-14 w-14 flex items-center justify-center rounded-full text-slate-300"><FiLock/></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }


                </div>
            </div>
        </article>
    );
};

const menuDashbaord = [
    {
        id: 1,
        title: "Summary"
    },
    {
        id: 2,
        title: "Project tasks"
    },
    {
        id: 3,
        title: "Personal tasks"
    },
    {
        id: 4,
        title: "My notes"
    },
    {
        id: 5,
        title: "My activity"
    }
]

const dataBox = [
    {
        id: 1,
        title: "Project tasks",
        icon: <FiClipboard/>
    },
    {
        id: 2,
        title: "Resources",
        icon: <FiLayers/>
    },
    {
        id: 3,
        title: "My Notes",
        icon: <FiPenTool/>
    }
]