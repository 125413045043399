import {Controller, useForm} from "react-hook-form";
import Select from "react-select"
import {MyEditor} from "../../../../components/editor/MyEditor";
import {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFetchAllInstallation} from "../../../installation/hooks/useFetchAllInstallation";
import NetworkService from "../../../../services/NetworkService";
import {createSelector} from "reselect";
import {
    FiBook,
    FiInfo,
    FiLock,
    FiUpload
} from "react-icons/fi";
import {motion} from "framer-motion";
import {encodeFilesToBase64} from "../../../../utils/encodeFilesToBase64";
import {addNetwork} from "../../../../redux/networkConfig/networkConfigAction";
import {useFetchUserInfraFromMultipleInfraId} from "../../../user/hook/useFetchUserInfra";
import {stringToBoolean} from "../../../../utils/stringToBoolean";
import {toast} from "sonner";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";

import {
    useFetchStandardDescriptionAvailable
} from "../../../standardDescription/hook/useFetchStandardDescriptionAvailable";
import {dataToArray} from "../../../../utils/dataToArray";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import {useNavigate} from "react-router-dom";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

// TODO Si l'utilisateur n'est plus dans une des installations données, le supprimer de la liste

export const FormNetwork = ({method, setIsSubmitting, network, setIsUpdated}) => {
    const context = useContext(DrawerTemporaryContext);

    // User token
    const user = useSelector(selectUser);
    const token = user.token;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [listInfraOptions, setListInfraOptions] = useState([]);
    const [listUserOptions, setListUserOptions] = useState([]);
    const [selectedInfra, setSelectedInfra] = useState([]);

    const [pathLogo, setPathLogo] = useState(null);
    const [logoName, setLogoName] = useState(null);
    const [mainImageName, setMainImageName] = useState(null);
    const [pathMainImage, setPathMainImage] = useState(null);
    const [openConfirmationForm, setOpenConfirmationForm] = useState(false);


    const {data: listInstallations} = useFetchAllInstallation();
    const {listUserInfra, isLoading: isLoadingUsers} = useFetchUserInfraFromMultipleInfraId(selectedInfra);
    const {dataStandardDescription} = useFetchStandardDescriptionAvailable()

    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        formState: {errors},
        watch,
    } = useForm({
        defaultValues: {
            name: "",
            code: "",
            description: "",
            logo: null,
            fileLogo: null,
            centralHub: "",
            listInfra: [],
            users: [],
            mainImage: null,
            fileMainImage: null,
            displayInCatalog: false,
            networkInfrastructureDescription: null,
            networkServiceDescription: null,
            standardInfrastructureDescription: null,
            standardServiceDescription: null,
        }
    })

    useEffect(() => {
        if (null !== network && undefined !== network) {
            let currentListStandardDescriptionsPlatform = null;
            const networkListStandardDescriptionPlatform = network.listStandardDescriptions?.filter(sd => sd.type === 'platform');
            if (networkListStandardDescriptionPlatform.length > 0) {
                currentListStandardDescriptionsPlatform = networkListStandardDescriptionPlatform[0]['id'] ?? null;
            }

            let currentListStandardDescriptionsService = null;
            const networkListStandardDescriptionService = network.listStandardDescriptions?.filter(sd => sd.type === 'service')
            if (networkListStandardDescriptionService.length > 0) {
                currentListStandardDescriptionsService = networkListStandardDescriptionService[0]['id'] ?? null;
            }

            setValue('name', network.name);
            setValue('code', network.code);
            setValue('description', network.description);
            setValue('logo', network.logo);
            setValue('centralHub', network.infrastructureCentralHub);
            setValue('listInfra', dataToArray(network.listInfrastructures));
            setValue('users', dataToArray(network.listManagers));
            setValue('mainImage', network.mainImage);
            setValue('displayInCatalog', network.displayInCatalog);
            setValue('networkInfrastructureDescription', network.networkInfrastructureDescription);
            setValue('networkServiceDescription', network.networkServiceDescription);
            setValue('standardInfrastructureDescription', currentListStandardDescriptionsPlatform);
            setValue('standardServiceDescription', currentListStandardDescriptionsService);

            setLogoName(network.logo?.split('/').pop());
            setMainImageName(network.mainImage?.split('/').pop());
        } else {
            reset({
                name: "",
                code: "",
                description: "",
                logo: null,
                fileLogo: null,
                centralHub: "",
                listInfra: [],
                users: [],
                mainImage: null,
                fileMainImage: null,
                displayInCatalog: false,
                networkInfrastructureDescription: null,
                networkServiceDescription: null,
                standardInfrastructureDescription: null,
                standardServiceDescription: null,
            })
            setLogoName(null);
            setMainImageName(null);
        }
    }, [network]);

    useEffect(() => {
        setListInfraOptions(listInstallations);
    }, [listInstallations]);

    useEffect(() => {
        setSelectedInfra(watch('listInfra'));
    }, [watch('listInfra')]);


    useEffect(() => {
        let listUserInfraCustom = listUserInfra.map((item) => {
            return {id: item.user?.id, name: item.user?.fullName};
        })

        setListUserOptions(listUserInfraCustom);
    }, [
        listUserInfra,
        listInfraOptions
    ]);

    const networkService = new NetworkService();
    /****
     * SUBMIT FORMS FUNCTION
     * ****/
    const onSubmit = async (data) => {

        let listInfra = data.listInfra?.map(infra => `/api/infrastructures/${infra.id}`);
        let listUsers = data.users?.map(user => `/api/users/${user.id}`);

        let logoBase64, mainImageBase64 = null;

        const logo = watch("logo");
        const mainImage = watch("mainImage");

        // Custom logo
        if (logo && typeof logo == "object") {
            // convert to array
            const filesArray = Array.from(data.logo);
            logoBase64 = await encodeFilesToBase64(filesArray);
        }

        // Custom mainImage
        if (mainImage && typeof mainImage == "object") {
            // convert to array
            const filesArrayMainImage = Array.from(data.mainImage);
            mainImageBase64 = await encodeFilesToBase64(filesArrayMainImage);
        }

        let currentListStandardDescriptions = [];
        if (data.standardInfrastructureDescription) {
            currentListStandardDescriptions.push('/api/standard_descriptions/' + data.standardInfrastructureDescription);
        }
        if (data.standardServiceDescription) {
            currentListStandardDescriptions.push('/api/standard_descriptions/' + data.standardServiceDescription);
        }

        // Check if the file exist
        const customData = {
            name: data.name,
            code: data.code,
            description: data.description,
            infrastructureCentralHub: data.centralHub ? data.centralHub['@id'] : null,
            listInfrastructures: listInfra || null,
            listUserManagers: listUsers || null,
            logo: pathLogo ? pathLogo : null,
            fileLogo: logoBase64 ? logoBase64[0] : null,
            mainImage: pathMainImage ? pathMainImage : null,
            fileMainImage: mainImageBase64 ? mainImageBase64[0] : null,
            displayInCatalog: stringToBoolean(data.displayInCatalog),
            networkInfrastructureDescription: data.networkInfrastructureDescription ? data.networkInfrastructureDescription['@id'] : null,
            networkServiceDescription: data.networkServiceDescription ? data.networkServiceDescription['@id'] : null,
            listStandardDescriptions: currentListStandardDescriptions,
        }

        try {

            let res = null;
            if (method === "CREATE") {
                res = await networkService.postNetworkWithToken(customData, token);
            } else if (method === 'UPDATE') {
                res = await networkService.putNetworkByIdWithToken(network.id, customData, token);
            }

            if (res) {
                if (method === "CREATE") {
                    dispatch(addNetwork(customData));
                }

                reset({
                    name: "",
                    code: "",
                    description: "",
                    centralHub: null,
                    listInfra: [],
                    users: [],
                    logo: null,
                    fileLogo: null,
                    fileMainImage: null,
                    mainImage: null,
                    displayInCatalog: "false",
                    networkInfrastructureDescription: null,
                    networkServiceDescription: null,

                });

                // Reset Data
                setIsSubmitting(true);
                // Reset mainImage and logo
                setPathLogo(null)
                setPathMainImage(null)

                if (null !== context) {
                    context.onDrawerClose();
                } else if (typeof setIsUpdated === 'function') {
                    setIsUpdated(true);
                }
                if (method === "CREATE") {
                    return toast.success("Network successfully created");
                } else if (method === 'UPDATE') {
                    return toast.success("Network successfully updated");
                }
                return toast.success("Everything going well");
            }

        } catch (error) {
            console.error(error)
            setIsSubmitting(false);
            return toast.warning("Oops! An error occurred while creating the network.");
        }
    };

    const handleDeleteNetwork = async () => {
        if (!network) return toast.message("Something went wrong!");
        setIsSubmitting(true);
        try {
            const res = await networkService.deleteNetworkByIdWithToken(network.id, token);

            if (!res) {

                setIsSubmitting(false);
                navigate('/list/networks');

                return toast.success("Network deleted successfully!");
            }

        } catch (error) {
            console.error(error);
            setIsSubmitting(false);
            return toast.warning("Something went wrong!");
        }
    }

    const handleNameBlur = () => {
        if (!watch('code')) {
            setValue('code', watch('name').replace(/\W/g, "").toLowerCase())
        }
    };

    /****
     * CKEDITOR
     ****/
    const handleEditorChange = (content) => {
        setValue("description", content);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {/* 01 */}
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 ">
                    <div className="flex items-center gap-4">
                        <div className="flex flex-col gap-2 w-full grow">
                            <label htmlFor="name" className="font-semibold text-md">Name *</label>
                            <input type="text"
                                   id="name"
                                   placeholder="Name of your Network"
                                   {...register("name", {required: "Enter name of network"})}
                                   className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                   onBlur={() => handleNameBlur()}
                            />
                            {errors.name && !watch("name") && (
                                <span className={"block text-xs text-red-500/70"}
                                      role="alert">{errors.name.message}</span>
                            )}
                        </div>
                        <div className="flex flex-col gap-2 w-full ">
                            <label htmlFor="code" className="font-semibold text-md">Code *</label>
                            <input type="text"
                                   id="code"
                                   placeholder="Code"
                                   {...register("code", {
                                       required: {
                                           value: true,
                                           message: "Code is required",
                                       },
                                       pattern: {
                                           value: /^[a-z0-9_-]+$/i,
                                           message: "Please enter a valid code (Alphanumerical and special characters ( - or _ ) only).",
                                       }
                                   })}
                                   className="border border-slate-300 border-solid rounded-sm w-full h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                            />
                            {errors.code && (
                                <span className={"block text-xs text-red-500/70"}
                                      role="alert">{errors.code.message}</span>
                            )}
                        </div>
                    </div>

                    {/* Upload file */}
                    <div className="flex flex-col items-start gap-0 divide-y divide-slate-300 border border-solid border-slate-300 rounded-sm">
                        {/* Logo */}
                        <div className="w-full h-12 flex gap-2 items-center justify-start relative px-4">
                            <motion.label
                                htmlFor="logo"
                                className={`border border-slate-200 border-solid rounded-sm h-12 flex items-center justify-center px-2 cursor-pointer absolute right-0 text-slate-500 bg-slate-100`}
                            >
                                <div className='flex'>
                                    <span className="text-sm px-2">Click to upload</span>
                                    <span
                                        className={`border rounded-full text-xs p-1 ${pathLogo ? "border-blue-50 text-blue-50 bg-blue-500" : "text-slate-500 border-slate-300"}`}><FiUpload/></span>
                                </div>
                            </motion.label>

                            <div>{logoName ? <p className="flex items-center text-sm text-slate-500 gap-2">{logoName} <a href={process.env.REACT_APP_API_URL + network?.logo} target="_blank" rel="noopener noreferrer" ><span className=" text-slate-950 text-md"><FiInfo /></span></a></p> : <p className="text-sm text-slate-500"> {pathLogo ? "logo selected": "Choose new Logo"}</p>}</div>

                            <input
                                type="file"
                                name="logo"
                                id="logo"
                                multiple
                                className="sr-only"
                                {...register('logo')}
                                onChange={(e) => {
                                    const fileNameLogo = e.target.files[0]?.name;
                                    setPathLogo(fileNameLogo);
                                }}
                            />
                        </div>

                        {/* MainImage */}
                        <div className="w-full h-12 flex gap-2 items-center justify-start relative px-4">
                            <motion.label
                                htmlFor="mainImage"
                                className={`border border-slate-200 border-solid rounded-sm h-12 flex items-center justify-center px-2 cursor-pointer absolute right-0 text-slate-500 bg-slate-100`}
                            >
                                <div className='flex flex-row'>
                                    <span className="text-sm px-2">Click to upload</span>
                                    <span className={`border rounded-full text-xs p-1 ${pathMainImage ? "border-blue-50 text-blue-50 bg-blue-500" : "text-slate-500 border-slate-300"}`}><FiUpload/></span>
                                </div>
                            </motion.label>

                            <div>{mainImageName ? <p className="flex items-center text-sm text-slate-500 gap-2">{mainImageName} <a
                                href={process.env.REACT_APP_API_URL + network?.mainImage} target="_blank"
                                rel="noopener noreferrer"><span className=" text-slate-950 text-md"><FiInfo/></span></a>
                            </p> : <p className="text-sm text-slate-500">{pathMainImage ? "Main image selected": "Choose new Main image"}</p>}</div>

                            <input
                                type="file"
                                name="mainImage"
                                id="mainImage"
                                multiple
                                className="sr-only"
                                {...register('mainImage')}
                                onChange={(e) => {
                                    const fileNameMainImage = e.target.files[0]?.name;
                                    setPathMainImage(fileNameMainImage);
                                }}
                            />
                        </div>
                    </div>

                    {/* Editor */}
                    <label htmlFor="description" className="font-semibold text-md">Description</label>
                    <Controller
                        control={control}
                        name="description"
                        id="description"
                        render={({field}) => (
                            <MyEditor
                                initialvalue={watch("description")}
                                setEditorData={handleEditorChange}
                            />
                        )}
                    />
                </div>
            </div>

            {/* 001 */}
            <div className={`flex flex-col gap-4 border border-dotted border-slate-300 min-h-14 p-4`}>
                    {displayCatalog.map(item =>
                        <label
                            key={item.id}
                            htmlFor={`inCatalog-${item.title}`}
                            className={`flex gap-4 items-center text-md cursor-pointer`}
                        >
                        <span
                            className={`block rounded-full p-2 text-xs ${String(watch("displayInCatalog")) === String(item.value) ? "border border-blue-600 bg-blue-500 text-white" : "border border-slate-200 text-slate-400"}`}>{item.icon}</span>
                            <input
                                type="radio"
                                id={`inCatalog-${item.title}`}
                                value={item.value}
                                className="sr-only"
                                {...register("displayInCatalog")}
                            />

                            <p className="flex flex-col leading-tight">
                                {item.title}
                                <span className="text-sm text-slate-400">{item.description}</span>
                            </p>
                        </label>
                    )}
                </div>


            {/* 02 */}
            <div className="flex flex-col gap-4">
                <label htmlFor="centralHub" className="font-semibold text-md">Central hub</label>
                <div className="w-full">
                    <Controller
                        name="centralHub"
                        control={control}
                        render={({field}) => (
                            <Select
                                {...register('centralHub')}
                                {...field}
                                options={listInfraOptions}
                                isClearable
                                id="centralHub"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                            />
                        )}
                    />
                </div>
            </div>

            {/* 04 */}
            <div className="flex flex-col gap-4">
                <label htmlFor="listInfra" className="font-semibold text-md" >List of installations</label>

                <div className="w-full">
                    <Controller
                        name="listInfra"
                        control={control}
                        render={({field}) => (
                            <Select
                                {...field}
                                {...register('listInfra')}
                                onChange={(e) => {
                                    setValue('listInfra', e);
                                }}
                                isMulti
                                id="listInfra"
                                options={listInfraOptions}
                                getOptionLabel={(option) => option.name} // Récupère le nom de l'option
                                getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                isSearchable
                            />
                        )}
                    />
                </div>
            </div>

            {/* 03 */}
            <div className="flex flex-col gap-4">
                <label htmlFor="users" className="font-semibold text-md">User Manager *</label>
                <div className="w-full">
                    {isLoadingUsers ?
                        (<>Loading...</>) :
                        (<Controller
                            name="users"
                            control={control}
                            render={({field}) => (
                                <Select
                                    {...register('users', {required: 'You need at least one manager'})}
                                    {...field}
                                    onChange={(e) => {
                                        setValue('users', e);
                                    }}
                                    isMulti
                                    id="users"
                                    options={listUserOptions}
                                    getOptionLabel={(option) => option.name} // Récupère le nom de l'option
                                    getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                    isSearchable
                                />
                            )}
                        />)
                    }
                </div>
            </div>

            {/* Choose standard description */}
            <div className={`flex flex-col gap-4 border border-dashed border-slate-300 min-h-14 p-4 `}>
                { dataStandardDescription.filter((s) => s.type === 'platform').map(item =>
                    <div className="w-full flex justify-between grow gap-10" key={item.id}>
                        <div className="flex flex-wrap items-center gap-4 grow">
                            <p className="text-lg text-slate-800 min-w-1/3">{item.title}</p>
                            <p className="text-sm text-slate-500 border border-slate-200 rounded-sm h-8 flex items-center pl-2 grow">{item.description}</p>
                        </div>

                        <div>
                            <label
                                key={`platform-standardDescription-${item.id}`}
                                htmlFor={`platform-standardDescription-${item.id}`}
                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${parseInt(watch('standardInfrastructureDescription'), 10) !== item.id ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                            >
                                <input
                                    type="checkbox"
                                    id={`platform-standardDescription-${item.id}`}
                                    value={item.id}
                                    name="standardInfrastructureDescription"
                                    {...register('standardInfrastructureDescription')}
                                    checked={parseInt(watch('standardInfrastructureDescription'), 10) === item.id}

                                    className="sr-only"
                                />
                                <span
                                    className={`${!watch('standardInfrastructureDescription') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                            </label>
                        </div>

                    </div>
                )}

                {dataStandardDescription.filter((s) => s.type === 'service').map(item =>

                    <div className="w-full flex justify-between gap-10" key={item.id}>
                        <div className="flex flex-wrap items-center gap-4 grow ">
                            <p className="text-lg text-slate-800 min-w-1/3">{item.title}</p>
                            <p className="text-sm text-slate-500 border border-slate-200 rounded-sm h-8 flex items-center pl-2 grow">{item.description}</p>
                        </div>

                        <div>
                            <label
                                key={`service-standardDescription-${item.id}`}
                                htmlFor={`service-standardDescription-${item.id}`}
                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${parseInt(watch("standardServiceDescription"), 10) !== item.id ? "text-orange-400 bg-orange-100 border-orange-300" : "text-green-400 bg-green-100 border-green-300"}`}
                            >
                                <input
                                    type="checkbox"
                                    id={`service-standardDescription-${item.id}`}
                                    value={item.id}
                                    name="standardServiceDescription"
                                    {...register("standardServiceDescription")}
                                    checked={parseInt(watch("standardServiceDescription"), 10) === item.id}
                                    className="sr-only"
                                />

                                <span
                                    className={`${!watch("standardServiceDescription") ? "border-orange-400 left-0" : "right-0 border-green-400"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                            </label>
                        </div>

                    </div>
                )}
            </div>

            {/* 05 save button */}
            <motion.button
                className={`${network ? "max-w-20 bg-slate-100 border-slate-300 hover:text-slate-100 hover:bg-blue-500" : "max-w-32 bg-blue-500 text-slate-100 hover:bg-blue-400"}  rounded-full border h-10 transition ease-linear`}
                type="submit"
                title={network ? "Save the new information" : "Create new Network"}
            >
                {network ? "Save" : "Create"}
            </motion.button>

            <hr/>
            {/* 06 Danger zone */}
            {network &&
                <div className="flex flex-col gap-4 border border-dotted border-red-300 min-h-14 rounded-xl ">

                    <div className="px-8 py-2 flex flex-col gap-2">
                        <p className="text-lg font-semibold">Delete Network</p>
                        <p className="text-sm text-slate-500">The network will be permanently deleted. This action is
                            irreversible and cannot be undone.</p>
                        <hr/>
                        <div className="flex gap-4">
                            <div className="w-52 h-24">
                                <figure className="w-52 h-24 overflow-hidden">
                                <img
                                        src={process.env.REACT_APP_API_URL + network?.mainImage}
                                        alt="network image"
                                        className="w-full h-full object-contain rounded-lg"
                                    />
                                </figure>
                            </div>
                            <div>
                                <p className="text-lg font-medium">{network?.name}</p>
                                <span className="text-sm text-slate-500">{network?.code}</span>
                            </div>

                        </div>
                    </div>

                    <button
                        className={`h-14 bg-red-100 border border-red-300 text-slate-500 hover:text-slate-50 hover:bg-red-400 transition ease-in-out duration-100 text-center px-10 rounded-b-xl }`}
                        onClick={() => setOpenConfirmationForm(true)}
                        type="button"
                        title="Delete Network"
                    >
                        Delete network
                    </button>

                    <ConfirmDialog
                        title={"Delete Network"}
                        isOpen={openConfirmationForm}
                        onClose={() => setOpenConfirmationForm(false)}
                        setIsOpen={setOpenConfirmationForm}
                        onAgree={() => handleDeleteNetwork()}
                        content={

                            <div className="flex flex-col gap-4">
                                <p>This network will be deleted, along with all of its Deployments, Installation description, Service description, Pre-proposal project submition, and Settings.</p>
                                <p className="bg-red-100 border border-red-300 h-10 flex items-center justify-center rounded-sm text-red-400"> <span className="font-bold">Warning</span>: This action is not reversible. Please be certain.</p>
                                <hr/>
                                <ul className="list-decimal ml-4 text-slate-500 flex flex-col gap-2 text-sm">
                                    <li>This will permanently delete the network <span className="font-bold text-md">'{network?.name}'</span>.</li>
                                    <li>All Installations attached will loose this network.</li>
                                    <li>All Forms, Custom selects will be deleted.</li>
                                </ul>
                                <hr/>
                            </div>
                        }
                    />

                </div>
            }

        </form>
    )
}

const displayCatalog = [
    {
        id: 0,
        value: "true",
        icon: <FiBook/>,
        title: "Public",
        description: "Anyone on catalog page can see this network."
    },
    {
        id: 1,
        value: "false",
        icon: <FiLock/>,
        title: "Private",
        description: "This Network is not visible on catalog."
    }
]