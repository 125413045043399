import * as React from "react";
import {Suspense} from "react";

// Components
import {ProjectsPpaList} from "../components/projects-ppa-list/projects-ppa-list";
import {ProjectsList} from "../components/projects-list/projects-list";

// Design
import {motion} from "framer-motion";
import {Loader} from "../../../components/loader/Loader";
import {MdAddCircle} from "react-icons/md";
import {FaClipboardList} from "react-icons/fa";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useFetchProjectsPpasPerInfrastructure} from "../hooks/useFetchProjects";

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

const selectUser = createSelector(
    state => state.login.user,
    user => user
);


export const ProjectsContainer = () => {

    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const user = useSelector(selectUser);
    const token = user?.token;

    const { installationProjectsPpa} = useFetchProjectsPpasPerInfrastructure(currentInfrastructure, '', token);
    const incomingProjectsPpa = installationProjectsPpa?.filter((item) => item.status === 'incoming');


    return (
        <motion.section
            initial={{translateY: -15, opacity: 0}}
            transition={{type: "spring", duration: .3, ease: "easeInOut"}}
            animate={{translateY: 0, opacity: 1}}
            exit={{opacity: 0}}

            className="projects w-full flex flex-col items-center lg:pt-10 pt-52 bg-zinc-50"
        >
            <article id="" className="w-full min-h-screen flex flex-col items-center">
                <div className="container">
                    <div className="wrapper flex flex-col pb-10">
                        <h2 className="flex items-center text-2xl font-medium text-left max-w-[500px] text-slate-900 mb-6 lg:mb-8 gap-2">
                            <span>List of projects</span>
                            <span className="text-xl text-slate-900 bg-white border border-slate-200 w-10 h-10 flex items-center justify-center rounded-full">
                                <FaClipboardList />
                            </span>
                        </h2>

                        {/*<motion.div className={"flex w-full py-10"}>*/}
                        <motion.div className={"w-full hidden"}>
                            <motion.button initial={{translateY: -15, opacity: 0}}
                                           transition={{type: "spring", duration: .3, ease: "easeInOut"}}
                                           animate={{translateY: 0, opacity: 1}}
                                           exit={{opacity: 0}}
                                           className={"flex w-full items-center"}
                                           // onClick={}
                            >
                                <MdAddCircle size={30} className={"mr-2"} />Create a new project
                            </motion.button>
                        </motion.div>

                        {/*<Suspense fallback={<Loader content="Loading Projects Proposal"/>}>*/}
                            <ProjectsPpaList currentInfrastructure={currentInfrastructure}
                                             installationProjectsPpa={incomingProjectsPpa}
                            />
                        {/*</Suspense>*/}

                        {/*<Suspense fallback={<Loader content="Loading Projects List"/>}>*/}
                            <ProjectsList installationProjectsPpa={installationProjectsPpa} />
                        {/*</Suspense>*/}

                    </div>
                </div>
            </article>
        </motion.section>
    )
}

