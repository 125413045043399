import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {Field} from "react-final-form";

// Design
import {Grid} from "@mui/material";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    CustomizedDateAndTimePicker,
    CustomizedDatePicker,
    CustomizedInputLabel,
    CustomizedTimePicker
} from "./FormCustomizedInputs";
import {renderTimeViewClock} from "@mui/x-date-pickers";
import {FormLabelTooltip} from "./FormLabelTooltip";
import {IoInformationCircleOutline} from "react-icons/io5";

// Context
import {ListTypeFormContext} from "./listtypeform/ListTypeFormsContainer";
import {FormContentFormsContext} from "../FormContentForms";

// Custom Functions
import {missingFieldsChecking} from "../../../utils/CustomFunctions";


export default function FormDateAndTimePickers ({ name, description, label, required, isBlocked, hide, id, inputIndex, values, keyLinkValue, isRead, dateType }) {

    const [selectedDate, setSelectedDate] = useState(null);

    // From Context (Cancel Field Management)
    const listTypeFormContext = useContext(ListTypeFormContext);
    const {
        cancelFieldsData: cancelFieldsDataContext,
        setCancelFieldsData: setCancelFieldsDataContext,
        remainingLinesData: remainingLinesDataContext,
        setRemainingLinesData: setRemainingLinesDataContext
    } = listTypeFormContext !== null && listTypeFormContext;

    // From Context (Missing Field Management)
    const formContentFormsContext = useContext(FormContentFormsContext);
    const {
        reloadMissingFieldsChecking: reloadMissingFieldsCheckingContext,
        setReloadMissingFieldsChecking: setReloadMissingFieldsCheckingContext,
    } = formContentFormsContext !== null && formContentFormsContext;


    // Avoid duplicate id Browser Issue
    const fieldUniqueId = inputIndex + '_' + id

    const datePickerValuesSetAfterSave = () => {

        if(localStorage.listTypeItem) {
            const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
            const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)

            // Restore data for remaining lines after a cancel or a line removal
            for (let i = 0; i < listTypeItemFromStorageArray.length; i++) {
                if ((listTypeItemFromStorageArray[i].input) === ('/api/inputs/' + id) &&
                    (listTypeItemFromStorageArray[i].keyLink === keyLinkValue)) {

                    const loadedFromStorage = (typeof listTypeItemFromStorageArray[i].value !== "string") ? "" : listTypeItemFromStorageArray[i].value;
                    setSelectedDate(loadedFromStorage)
                }
            }
        }

        if (listTypeFormContext !== null) {
            setCancelFieldsDataContext(false);
            setRemainingLinesDataContext(false);
        }
    }

    // Retrieve Values
    const retrieveExistingListInputValues = () => {
        if (values.listInputValues[inputIndex]) {
            if (typeof values.listInputValues[inputIndex].value === "string" && values.listInputValues[inputIndex].value !== "") {
                const loadedDateValues = values.listInputValues[inputIndex].value;
                setSelectedDate(loadedDateValues)
            }
        }
    }

    useEffect(() => {
        (values.token !== null && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    }, [values]);


    // Values assigning
    useEffect(() => {

        if (values.token !== null && values.listInputValues[inputIndex]) {
            if(selectedDate !== null) {
                values.listInputValues[inputIndex].value = selectedDate;
            }
        } else {
            values.listInputValues[inputIndex] = { value : selectedDate };
            Object.assign(values.listInputValues[inputIndex], { keyLink: keyLinkValue, input: '/api/inputs/' + id });
        }

    }, [selectedDate]);


    // Required Fields Detection
    useEffect(() => {
        missingFieldsChecking(hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, selectedDate === null);
    }, [selectedDate]);


    // List Type Form Saved Data Verification
    useEffect(() => {
        if (cancelFieldsDataContext || remainingLinesDataContext) {
            localStorage.listTypeItem && datePickerValuesSetAfterSave();
        }
    }, [cancelFieldsDataContext, remainingLinesDataContext]);


    // DateAndTimePickers depending on htmlType
    const componentProps = {
        value: dayjs(selectedDate),
        onAccept: setSelectedDate,
        required: {required},
        disabled: isRead || isBlocked,
        slotProps: {
            textField: {
                id: `${fieldUniqueId}`,
                    inputProps: { 'data-inputid' : `${id}` },
            },
        },
        viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
        }
    }


    return (
        <Grid item xs={6} hidden={hide} sx={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'end' }}>
            <Field name={ name }
            >
                {props => (
                    <>
                        <CustomizedInputLabel shrink htmlFor={`${fieldUniqueId}`} >
                            { label } {required && " *"}

                            { description && (
                                <FormLabelTooltip title={ <div dangerouslySetInnerHTML={{__html: description}} /> }>
                                    <span>
                                        <IoInformationCircleOutline className={"mt-[1.6px] text-blue-500 text-xl"}/>
                                    </span>
                                </FormLabelTooltip>
                            )}
                        </CustomizedInputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">

                            {/* Date Only */}
                            {dateType === 'date' && (
                                <CustomizedDatePicker name={props.input.name}
                                                       onChange={props.input.onChange}
                                                       {...componentProps}
                                />
                            )}

                            {/* Date and Time */}
                            {dateType === 'datetime' && (
                                <CustomizedDateAndTimePicker name={props.input.name}
                                                              onChange={props.input.onChange}
                                                              {...componentProps}
                                />
                            )}

                            {/* Time Only */}
                            {dateType === 'time' && (
                                <CustomizedTimePicker name={props.input.name}
                                                       onChange={props.input.onChange}
                                                       {...componentProps}
                                />
                            )}

                        </LocalizationProvider>
                        {props.meta.error && props.meta.touched && <span>{props.meta.error}</span>}
                    </>
                )}
            </Field>
        </Grid>
    );
}