import { useForm } from 'react-hook-form'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useState } from 'react'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import { displayMessage } from '../../../../redux/alertAction'
import { ResourceTree } from '../ResourceTree'
import ResourceService from '../../services/Resource'
import { useSearchParams } from 'react-router-dom'


const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user,
)

export function DuplicateResourceForm({ resourceType, apiRefSource, apiRefDestination, resourceSelected, setRid }) {
    const user = useSelector(selectUser)

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? 'CREATE'
    const isCreate = method === 'CREATE'
    const [submitting, setSubmitting] = useState(false)
    const [resourceSource, setResourceSource] = useState(resourceSelected?.id.toString())
    const [resourceDestination, setResourceDestination] = useState(null)
    const [resourceSourceError, setResourceSourceError] = useState(false)
    const [resourceDestinationError, setResourceDestinationError] = useState(false)
    const [isLoadingResourceSource, setIsLoadingResourceSource] = useState(false)
    const [isLoadingResourceDestination, setIsLoadingResourceDestination] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            resourceSource: null,
            resourceDestination: null,
            numberCopy: 1,
            withAccess: false,
            withResourceInfo: false,
            withImage: false,
            withEvent: false,
            withLink: false,

        },
    })

    const onSubmit = async (data) => {
        try {
            let isError = false;
            let resourceSourceId = resourceSource
            if (null !== resourceSource && typeof resourceSource === 'object') {
                resourceSourceId = resourceSource.id
            }
            if (null === resourceSourceId) {
                isError = true
                setResourceSourceError(true);
            } else {
                setResourceSourceError(false);
            }
            if (null === resourceDestination) {
                isError = true;
                setResourceDestinationError(true);
            } else {
                setResourceDestinationError(false);
            }
            if (isError) {
                return;
            }

            setSubmitting(true)
            const requestData = {
                resourceSource: `/api/resources/${resourceSourceId}`,
                resourceDestination: `/api/resources/${resourceDestination}`,
                numberCopy: data.numberCopy,
                withAccess: data.withAccess,
                withResourceInfo: data.withResourceInfo,
                withImage: data.withImage,
                withEvent: data.withEvent,
                withLink: data.withLink,
            }

            const resourceService = new ResourceService()
            await resourceService.postDuplicateResource(requestData, user.token).then((response) => {

                if (response.status === 200 || response.id) {
                    setRid(response.id)
                    setSearchParams({
                        rid: response.id,
                    })

                    reset({
                        resourceSource: null,
                        resourceDestination: null,
                        numberCopy: 1,
                        withAccess: false,
                        withResourceInfo: false,
                        withImage: false,
                        withEvent: false,
                        withLink: false,
                    })

                    dispatch(displayMessage('Success', 'Element duplicated', 'success'))

                    context.onDrawerClose()

                }
                setSubmitting(false)
            });
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error?.data?.title, error?.data?.detail, 'error'))
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            <>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4">
                        <div>
                            <h3 className="text-xl font-bold mb-2 tracking-wide">
                                Resource source
                            </h3>
                            {resourceSourceError && (
                                <span className={'text-sm text-red-500/70'} role="alert">
                                            The resource source is required
                                        </span>
                            )}
                        </div>
                        <div className="w-full">
                            <ResourceTree
                                resourceType={resourceType}
                                setIsLoadingResource={setIsLoadingResourceSource}
                                setResource={setResourceSource}
                                rid={resourceSelected?.id.toString()}
                                apiRef={apiRefSource}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4">
                        <div>
                            <h3 className="text-xl font-bold mb-2 tracking-wide">
                                Resource destination
                            </h3>
                            {resourceDestinationError && (
                                <span className={'text-sm text-red-500/70'} role="alert">
                                            The resource destination is required
                                        </span>
                            )}
                        </div>
                        <div className="w-full">
                            <ResourceTree
                                setIsLoadingResource={setIsLoadingResourceDestination}
                                setResource={setResourceDestination}
                                apiRef={apiRefDestination}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4">
                        <div>
                            <h3 className="text-xl font-bold mb-2 tracking-wide required">
                                Number of copy
                            </h3>
                        </div>
                        <div className="w-full">
                            <input
                                type="number"
                                placeholder="Number of copy"
                                {...register('numberCopy', {
                                    required: 'Number of copy is required',
                                    valueAsNumber: true,
                                    min: {
                                        value: 1,
                                        message: 'Minimum of copy is 1',
                                    },
                                    max: {
                                        value: 50,
                                        message: 'Maximum of copy is 50',
                                    }
                                })}
                                className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                            />
                            {errors.numberCopy && !watch('numberCopy') && (
                                <span className={'text-sm text-red-500/70'} role="alert">
                                            {errors.numberCopy.message}
                                        </span>
                            )}
                        </div>
                    </div>
                </div>
                <ul className="flex flex-col gap-2 bg-white border border-slate-200 rounded-xl p-4">
                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                        <div className="w-1/2 grow">
                            <label htmlFor="withAccess" className="flex items-center gap-1">
                                <span className="text-sm"></span>
                                Copy Accesses
                            </label>
                        </div>
                        <div className=" text-blue-500 flex items-center gap-2">
                            <label
                                htmlFor="withAccess"
                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${!watch('withAccess') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                            >
                                <input
                                    type="checkbox"
                                    id="withAccess"
                                    {...register('withAccess')}
                                    className="sr-only"
                                />

                                <span
                                    className={`${!watch('withAccess') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                ></span>
                            </label>
                        </div>
                    </li>
                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                        <div className="w-1/2 grow">
                            <label htmlFor="withResourceInfo" className="flex items-center gap-1">
                                <span className="text-sm"></span>
                                Copy Resource info
                            </label>
                        </div>
                        <div className=" text-blue-500 flex items-center gap-2">
                            <label
                                htmlFor="withResourceInfo"
                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${!watch('withResourceInfo') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                            >
                                <input
                                    type="checkbox"
                                    id="withResourceInfo"
                                    {...register('withResourceInfo')}
                                    className="sr-only"
                                />

                                <span
                                    className={`${!watch('withResourceInfo') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                ></span>
                            </label>
                        </div>
                    </li>
                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                        <div className="w-1/2 grow">
                            <label htmlFor="withImage" className="flex items-center gap-1">
                                <span className="text-sm"></span>
                                Copy Images
                            </label>
                        </div>
                        <div className=" text-blue-500 flex items-center gap-2">
                            <label
                                htmlFor="withImage"
                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${!watch('withImage') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                            >
                                <input
                                    type="checkbox"
                                    id="withImage"
                                    {...register('withImage')}
                                    className="sr-only"
                                />

                                <span
                                    className={`${!watch('withImage') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                ></span>
                            </label>
                        </div>
                    </li>
                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                        <div className="w-1/2 grow">
                            <label htmlFor="withEvent" className="flex items-center gap-1">
                                {' '}
                                <span className="text-sm"></span>
                                Copy Events
                            </label>
                        </div>
                        <div className=" text-blue-500 flex items-center gap-2">
                            <label
                                htmlFor="withEvent"
                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${!watch('withEvent') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                            >
                                <input
                                    type="checkbox"
                                    id="withEvent"
                                    {...register('withEvent')}
                                    className="sr-only"
                                />

                                <span
                                    className={`${!watch('withEvent') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                ></span>
                            </label>
                        </div>
                    </li>
                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                        <div className="w-1/2 grow">
                            <label htmlFor="withLink" className="flex items-center gap-1">
                                {' '}
                                <span className="text-sm"></span>
                                Copy Links
                            </label>
                        </div>
                        <div className=" text-blue-500 flex items-center gap-2">
                            <label
                                htmlFor="withLink"
                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-sm font-medium border px-1 ${!watch('withLink') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                            >
                                <input
                                    type="checkbox"
                                    id="withLink"
                                    {...register('withLink')}
                                    className="sr-only"
                                />

                                <span
                                    className={`${!watch('withLink') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                ></span>
                            </label>
                        </div>
                    </li>
                </ul>
                <input
                    type="submit"
                    disabled={submitting}
                    value={isCreate ? 'Create' : 'Update'}
                    className={`py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer ${submitting ? 'disabled' : ''} `}
                />
            </>
        </form>
    )
}
