import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useFetchResourceActivities } from '../../hooks/useFetchResourceActivities'
import { FormLoader } from '../../../servicetype/loaders/Loaders'
import dayjs from 'dayjs'
import { Accordion, AccordionDetails, AccordionSummary, InputLabel, MenuItem } from '@mui/material'
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    timelineSeparatorClasses,
    timelineDotClasses,
    TimelineSeparator,
} from '@mui/lab'
import {
    FiBox,
    FiCalendar,
    FiClipboard,
    FiClock,
    FiEdit3,
    FiEye,
    FiFilter,
    FiGitBranch,
    FiList,
    FiRotateCw,
    FiUser,
    FiXCircle,
} from 'react-icons/fi'
import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { motion } from 'framer-motion'
import _ from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Alert from "@mui/material/Alert";

const categoriesTransaltions = {
    RESOURCE: 'Resource',
    RESOURCE_LINK: 'Resource link',
    RESOURCE_FORM: 'Resource info',
    FORM: '????',
    EVENT: 'Event',
    TICKET: 'Ticket',
    RESOURCE_ACCESS: 'Access',
    INPUT_VALUE: 'Resource info',
}

const categoriesIcons = {
    RESOURCE: <FiBox />,
    RESOURCE_LINK: <FiGitBranch />,
    RESOURCE_FORM: <FiList />,
    FORM: <FiList />,
    EVENT: <FiCalendar />,
    TICKET: <FiClipboard />,
    RESOURCE_ACCESS: <FiEye />,
    INPUT_VALUE: <FiEdit3 />,
}

const actionTypeTranslation = {
    CREATE: 'created',
    UPDATE: 'updated',
    DELETE: 'deleted',
    READ: 'read',
}

const actionTypeColor = {
    CREATE: 'info',
    UPDATE: 'success',
    DELETE: 'error',
    READ: 'grey',
}
export const Activity = ({ resource }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const user = useSelector(selectUser)
    const { activities, isLoading: isActivitiesLoading } = useFetchResourceActivities(resource.id, user.token)
    const [filters, setFilters] = useState({ actionTypes: [], categories: [], users: [] })
    const [selectedActionTypes, setSelectedActionTypes] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [filteredActivities, setFilteredActivities] = useState([])

    const handleChangeMultiple = (event) => {
        const {
            target: { value },
        } = event

        return typeof value === 'string' ? value.split(',') : value
    }

    const handleChangeFilterUsers = (event) => {
        setSelectedUsers(handleChangeMultiple(event))
    }
    const handleChangeFilterCategories = (event) => {
        setSelectedCategories(handleChangeMultiple(event))
    }
    const handleChangeFilterActionTypes = (event) => {
        setSelectedActionTypes(handleChangeMultiple(event))
    }
    const [expanded, setExpanded] = useState('')

    useEffect(() => {
        if (!isActivitiesLoading) {
            var actionTypes = []
            var categories = []
            var users = []
            activities['hydra:member'].map((activity) => {
                if (actionTypes.indexOf(activity.actionType) === -1) {
                    actionTypes.push(activity.actionType)
                }
                if (categories.indexOf(activity.category) === -1) {
                    categories.push(activity.category)
                }
                if (users.indexOf(activity.userInfra.fullName) === -1) {
                    users.push(activity.userInfra.fullName)
                }
            })
            setFilters({ actionTypes: actionTypes, categories: categories, users: users })
            var tempFilteredActivities = activities['hydra:member']
            if (selectedUsers.length > 0) {
                tempFilteredActivities = tempFilteredActivities.filter((activity) => {
                    return selectedUsers.indexOf(activity.userInfra.fullName) !== -1
                })
            }
            if (selectedCategories.length > 0) {
                tempFilteredActivities = tempFilteredActivities.filter((activity) => {
                    return selectedCategories.indexOf(activity.category) !== -1
                })
            }
            if (selectedActionTypes.length > 0) {
                tempFilteredActivities = tempFilteredActivities.filter((activity) => {
                    return selectedActionTypes.indexOf(activity.actionType) !== -1
                })
            }
            setFilteredActivities(
                _.groupBy(tempFilteredActivities, ({ createdAt }) => dayjs(createdAt).format('YYYY-MM'))
            )
        }
    }, [activities, isActivitiesLoading, selectedCategories, selectedUsers, selectedActionTypes])
    useEffect(() => {
        setExpanded(Object.keys(filteredActivities)[0])
    }, [filteredActivities])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const customSelectStyle = {
        m: 1,
        minWidth: 120,
        flexGrow: 1,
        'label, .MuiInputBase-root': {
            fontSize: '14px'
        }
    }

    const filterLength = [...selectedCategories, ...selectedUsers, ...selectedActionTypes].length;


    return (
        <div>
            {isActivitiesLoading ? (
                <FormLoader />
            ) : (
                <div className="flex flex-col lg:flex-row-reverse gap-4">
                    <div className="flex flex-wrap overflow-hidden
                                    lg:max-w-40 h-fit mb-1 pb-4
                                    rounded-lg border border-gray-200"
                    >
                        <div className="flex gap-2 items-center bg-slate-600
                                        w-full h-fit px-2 py-[6px]"
                        >
                            <span className="flex justify-center items-center p-1 bg-slate-100 rounded-full">
                                <FiFilter size="13" color="#475569" />
                            </span>
                            <span className="text-white">Filters</span>
                            {filterLength !== 0 && (
                                <span className="flex justify-center items-center ml-auto min-w-5 px-1 bg-white text-slate-600 text-sm font-bold rounded-full">
                                    {filterLength}
                                </span>
                            )}
                        </div>
                        <div className="flex flex-wrap px-1 justify-start lg:justify-center w-full">
                            <FormControl variant="standard" sx={{...customSelectStyle}}>
                                <InputLabel id="User">Users</InputLabel>
                                <Select
                                    value={selectedUsers}
                                    labelId="User"
                                    label="User"
                                    onChange={handleChangeFilterUsers}
                                    placeholder="User"
                                    multiple
                                >
                                    {filters.users.map((user) => (
                                        <MenuItem key={user} value={user}>
                                            {user}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{...customSelectStyle}}>
                                <InputLabel id="Action">Actions</InputLabel>
                                <Select
                                    value={selectedActionTypes}
                                    label="Action"
                                    labelId="Action"
                                    onChange={handleChangeFilterActionTypes}
                                    multiple
                                >
                                    {filters.actionTypes.map((actionType) => (
                                        <MenuItem key={actionType} value={actionType}>
                                            {actionType}
                                        </MenuItem>
                                    ))}
                                </Select>{' '}
                            </FormControl>
                            <FormControl variant="standard" sx={{...customSelectStyle}}>
                                <InputLabel id="Category">Categories</InputLabel>
                                <Select
                                    value={selectedCategories}
                                    label="Category"
                                    labelId="Category"
                                    onChange={handleChangeFilterCategories}
                                    multiple
                                >
                                    {filters.categories.map((category) => (
                                        <MenuItem key={category} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </Select>{' '}
                            </FormControl>
                            {selectedUsers.length > 0 || selectedActionTypes.length > 0 || selectedCategories.length > 0 ? (
                                <div className="w-full">
                                    <button
                                        className="flex items-center gap-2
                                                   mt-3 mx-auto p-2
                                                   text-slate-500 border rounded-lg"
                                        onClick={() => {
                                            setSelectedCategories([])
                                            setSelectedActionTypes([])
                                            setSelectedUsers([])
                                        }}
                                    >
                                        Reset
                                        <FiRotateCw />
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="w-full lg:h-[66vh] lg:pr-2 overflow-y-auto">
                        {_.keys(filteredActivities)
                            .sort()
                            .reverse()
                            .map((createdAt, index) => (
                                <div key={index}>
                                    <Accordion expanded={expanded === createdAt} onChange={handleChange(createdAt)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{
                                                borderBottom: '1px solid rgba(0, 0, 0, .08)',
                                                ...(expanded === createdAt) && ({
                                                    background: 'rgba(241, 245, 249, .6)'
                                                }),
                                            }}
                                            className="hover:bg-slate-100"
                                        >
                                            <div className="flex flex-row justify-between items-center
                                                            text-md lg:text-lg text-slate-800
                                                            w-full"
                                            >
                                                <span className={"capitalize font-bold"}>{dayjs(createdAt).format('YYYY MMMM')}{' '}</span>
                                                <span className="text-sm mr-2 px-2 py-1 bg-slate-200 rounded-md">
                                                    {filteredActivities[createdAt].length} record{filteredActivities[createdAt].length > 1 ? 's' : '' }
                                                </span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: '24px 16px 16px' }}>
                                            <Timeline
                                                sx={{
                                                    position: 'relative',
                                                    padding: 0,
                                                    [`& .${timelineOppositeContentClasses.root}`]: {
                                                        position: {xs: 'absolute', md: 'relative'},
                                                        top: '14%',
                                                        left: {xs: '2vw', md: '4px', lg: '2px'},
                                                        padding: 0,
                                                        flex:  .1
                                                    },
                                                    [`& .${timelineSeparatorClasses.root}`]: {
                                                        flex: {xs: .3, sm: .2, md: .1},
                                                    },
                                                    [`& .${timelineDotClasses.root}`]: {
                                                        alignSelf: 'center'
                                                    },

                                                }}
                                                className="justify-start"
                                            >
                                                {filteredActivities[createdAt].map((activity) => (
                                                    <TimelineItem key={activity.id}>
                                                        <TimelineOppositeContent
                                                            sx={{ m: 'auto 0' }}
                                                            align="left"
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            <div className="whitespace-pre sm:whitespace-nowrap text-xs text-slate-800
                                                                           bg-slate-50 border border-slate-100 rounded-md
                                                                            p-1"
                                                            >
                                                                {dayjs(activity.createdAt).format('DD/MM[\n]HH:mm')}
                                                            </div>
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineConnector />
                                                            <TimelineDot color={actionTypeColor[activity.actionType]}>
                                                                {categoriesIcons[activity.category]}
                                                            </TimelineDot>
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                            <motion.div className="flex flex-col gap-1
                                                                                   border border-slate-200 rounded-md shadow-[0px_0px_6px_rgba(0,_0,_0,_0.04)]
                                                                                   pt-8 p-4 lg:pt-5"
                                                            >
                                                                <Alert className="absolute -top-[5px]"
                                                                       color={actionTypeColor[activity.actionType]}
                                                                       sx={{ padding: '0 10px', fontWeight: 'bold', maxWidth: '55%', wordBreak: 'break-word' }}
                                                                       icon={false}
                                                                >
                                                                    {categoriesTransaltions[activity.category]}{' '}
                                                                    {actionTypeTranslation[activity.actionType]}
                                                                </Alert>
                                                                <div className="flex flex-col lg:flex-row py-3">
                                                                    <span className="text-slate-400 mr-2 mb-2 text-xs relative top-[2px]">Message</span>
                                                                    <span className="text-sm" dangerouslySetInnerHTML={{__html: activity.message}} />
                                                                </div>
                                                                <div className="w-full flex bg-white justify-end text-slate-500 text-sm">
                                                                    <span className="flex items-center gap-2">
                                                                        <FiUser />
                                                                        {activity.userInfra.fullName}
                                                                    </span>
                                                                </div>
                                                            </motion.div>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                ))}
                                            </Timeline>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    )
}
