import React from "react";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

// Design
import {AnimatePresence, motion} from "framer-motion";
import {FaRegClock} from "react-icons/fa6";
import {ProjectsCardTooltip} from "../../features/project/components/projects-card-tooltip/projects-card-tooltip";

// Components
import CustomError from "../error/CustomError";

// Redux
import {useSelector} from "react-redux";
import {projectSlice} from "../../redux/project/projectSlice";

// Utils
import {getLimitedData} from "../../utils/getLimetedData";
import {statusData} from "../../features/project/utils/statusData";


export const ProjectCard = ({isOpen}) => {

    const { selectInfrastructureProjectsList } = projectSlice.selectors;
    const infrastructureProjectsListFromStore = useSelector(selectInfrastructureProjectsList);

    const list = infrastructureProjectsListFromStore?.map((item) => item).sort((a, b) => b?.updatedAt.localeCompare(a.updatedAt));
    const data = getLimitedData(list, 8);


    return (
        <AnimatePresence>
            { isOpen &&
                <motion.div
                    className="w-full lg:w-72
                    bg-white drop-shadow-md rounded-3xl
                    top-[70px] landscape:top-[40px] absolute left-0 z-10
                    flex-col items-center
                    overflow-hidden"

                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 10}}
                    transition={{type: "spring", stiffness: 300, damping: 24}}
                >
                    <div
                        className="bg-slate-300 border border-slate-100 h-14 flex items-center justify-center text-sm px-10 w-full">
                        <div className="h-14 flex items-center justify-center text-md font-semibold px-10 mb-2">
                            <p className="text-slate-700 text-sm text-center">Projects</p>
                        </div>
                    </div>

                    <div className="w-full flex flex-col gap-0 divide-y divide-slate-200">
                        <ul className="flex flex-col justify-between h-[40vh] overflow-auto lg:h-full w-full text-left">
                            {data?.length > 0 && data?.map(item => {

                                const statusColor = statusData?.find((found) => found?.status === item.status)?.color;
                                const statusText = statusData.find((found) => found?.status === item.status).text;
                                const updatedAt = dayjs(item.updatedAt).format('DD/MM/YYYY HH:mm');

                                return (
                                    <li key={item.id} className="w-full h-full grow">
                                        <Link
                                            to={`/project/${item.id}`}
                                            className="w-full h-full flex gap-2 items-center justify-start p-4 text-slate-500 hover:bg-slate-50"
                                        >
                                            <ProjectsCardTooltip
                                                title={
                                                    <div className={'flex flex-wrap gap-2 w-36'}>
                                                        <div>{statusText}</div>
                                                        <div className={'flex w-full'}>
                                                            <FaRegClock className={"mr-2"} size={18} />
                                                            <span className={"whitespace-nowrap"}>{updatedAt}</span>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                            <span className={`top-[3px] p-2 rounded-full ${statusColor} ${item.status}`}></span>
                                            </ProjectsCardTooltip>
                                            {item.acronym && item.acronym}
                                        </Link>
                                    </li>
                                )}
                            )}
                            <li key={'ProjectProposal-list'} className="w-full h-full text-center grow bg-slate-100">
                                <Link
                                    to={`/list/projects`}
                                    title='See all projects'
                                    className="w-full h-full flex items-center justify-center p-4 text-slate-700 hover:bg-slate-300"
                                >
                                    See all projects
                                </Link>
                            </li>
                        </ul>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    )
}