import React from "react";
import {styled} from "@mui/material/styles";
import {Tooltip, tooltipClasses} from "@mui/material";

export const ProjectsCardTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(12),
        padding: '8px',
        borderRadius: '5px',
        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.06)',
        width: 'fit-content',
    },
    [`& .${tooltipClasses.arrow}`]: {
        // color: '#BBEAFE',
    },
}));