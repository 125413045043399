export const statusData = [
    { status: 'opened', text: 'Opened', color: 'bg-sky-500', },
    { status: 'under_prepare', text: 'Under prepare', color: 'bg-sky-500', },
    { status: 'completed', text: 'Completed', color: 'bg-green-700', },
    { status: 'in_progress', text: 'In progress', color: 'bg-violet-500', },
    { status: 'locked', text: 'Locked', color: 'bg-red-700', },
    { status: 'on_review', text: 'On review', color: 'bg-orange-500', },
    { status: 'on_hold', text: 'On hold', color: 'bg-orange-500', },
    { status: 'on_prepare', text: 'On prepare', color: 'bg-sky-500', },
    { status: 'terminated', text: 'Terminated', color: 'bg-green-500', },
    { status: 'validated', text: 'Validated', color: 'bg-green-500', },
];