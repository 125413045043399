import { useForm } from 'react-hook-form'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useState } from 'react'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import { Loader } from '../../../../components/loader/Loader'
import { displayMessage } from '../../../../redux/alertAction'
import { ResourceTree } from '../ResourceTree'
import ResourceService from '../../services/Resource'
import { useSearchParams } from 'react-router-dom'


const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user,
)

export function MoveResourceForm({ resourceType, resourceSelected, apiRefDestination }) {
    const user = useSelector(selectUser)

    const dispatch = useDispatch()

    const context = useContext(DrawerTemporaryContext)
    const [submitting, setSubmitting] = useState(false)
    const [resourceDestination, setResourceDestination] = useState(null)
    const [resourceDestinationError, setResourceDestinationError] = useState(false)
    const [isLoadingResourceDestination, setIsLoadingResourceDestination] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const setParamRid = (itemId) => {
        if (null !== itemId) {
            setSearchParams({
                rid: itemId,
            })
        } else {
            setSearchParams({})
        }
    }

    const {
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            resourceDestination: null,
        },
    })

    const onSubmit = async (data) => {
        try {
            let resourceId = resourceDestination;
            if (null !== resourceDestination && typeof resourceDestination === 'object') {
                resourceId = resourceDestination.id
            }

            setSubmitting(true)
            const requestData = {
                parent: resourceId ? `/api/resources/${resourceId}` : null,
            }

            const resourceService = new ResourceService()
            await resourceService.putResource(requestData, resourceSelected.id, user.token).then((result) => {
                setParamRid(result.id)
            })

            reset({
                resourceDestination: null,
            })

            dispatch(displayMessage('Success', 'Element moved', 'success'))

            context.onDrawerClose()

            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error?.data?.title, error?.data?.detail, 'error'))
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                <Loader content="Submitting" />
            ) : (
                <>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-4">
                            <div>
                                <h3 className="text-xl font-bold mb-2 tracking-wide">
                                    Resource destination
                                </h3>
                                {resourceDestinationError && (
                                    <span className={'text-sm text-red-500/70'} role="alert">
                                                The resource destination is required
                                            </span>
                                )}
                            </div>
                            <div className="w-full">
                                <ResourceTree
                                    setIsLoadingResource={setIsLoadingResourceDestination}
                                    setResource={setResourceDestination}
                                    rid={resourceSelected?.parent?.id.toString()}
                                    resourceType={resourceType}
                                    apiRef={apiRefDestination}
                                />
                            </div>
                        </div>
                    </div>
                    <input
                        type="submit"
                        value={'Move'}
                        className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                    />
                </>
            )}
        </form>
    )
}
