import {useState} from "react";

// Design
import {Collapse, Grid} from "@mui/material";
import {IoChevronDownCircleOutline} from "react-icons/io5";
import {AnimatePresence, motion} from "framer-motion";

// Components
import HeadingDescriptif from "../catalog/components/HeadingDescriptif";

// Context
import useCatalogContext from "../../hooks/useCatalogContext";
import {useParams} from "react-router-dom";


export const CollapsibleFields = ({ title, content }) => {

    const { typecatalog } = useParams();

    const { styleCatalog } = useCatalogContext("", typecatalog, 500);

    const [toggleContent, setToggleContent] = useState(false);

    const handleToggleContent = () => {
        setToggleContent(!toggleContent);
    }

    return (
        <Grid item
              xs={12}
              lg={12}
              sx={{
                  overflowY: 'auto',
                  padding: '0',
                  margin: '0',
                  position: 'relative',
                  ...toggleContent && ({
                      margin: '0 0 20px',
                  }),
              }}
        >

            <AnimatePresence>
                {toggleContent && (
                    <motion.div className={`absolute left-[16px] lg:left-[32px] h-full ${styleCatalog}`}
                                initial={{width: 0}}
                                animate={{width: 4}}
                                exit={{width: 0}}
                                transition={{duration: .1}}
                    ></motion.div>
                )}
            </AnimatePresence>

            <div className={`flex justify-between px-3 py-5 border border-x-0 border-b-0 border-t-zinc-200 cursor-pointer hover:bg-zinc-50`}
                 onClick={handleToggleContent}
            >
                <HeadingDescriptif title={title} noUnderline addtionalClass={`${toggleContent && 'font-semibold'} text-lg`} />
                <IoChevronDownCircleOutline size={30} className={`text-slate-500 ${toggleContent ? 'rotate-180' : 'rotate-0'} duration-300`} />
            </div>
            <Collapse in={toggleContent}
                      timeout="auto"
                      sx={{ padding: '0 0 0 12px', }}
            >
                { content }
            </Collapse>
        </Grid>
    )
};

