import { useEffect, useState } from 'react'
import ResourceActivityService from '../services/ResourceActivity'

export const useFetchResourceActivities = (resource, token) => {
    const [activities, setActivities] = useState([])
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (isLoading) {
            fetchActivities()
        }
    }, [setIsLoading, activities, isLoading, resource])

    const fetchActivities = async () => {
        const activityService = new ResourceActivityService()
        const response = await activityService.getFromResource(resource, token)
        setActivities(response)
        setIsLoading(false)
    }

    return { activities, error, isLoading, setIsLoading }
}
