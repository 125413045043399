import React from "react";

// Design
import {BsFillBoxFill} from "react-icons/bs";

// Components
import {FormResourcesDeleteItem} from "../items/FormResourcesDeleteItem";
import {FormResourcesSeeAll} from "../items/FormResourcesSeeAll";


export const FormResourceTypesFieldView = ({
                                               selectedItems,
                                               setSelectedItems,
                                               listInput,
                                               handleResourceSelectionClick,
                                       }) => {

    const sliceNumber = 5;


    return (
        <>
            {selectedItems.length !== 0 && (
                <div className={`min-h-16 w-full py-1 border-t border-zinc-200`}>
                    <ul>
                        {selectedItems.slice(0, sliceNumber).map((item) => {
                            const resourcesFromSelectOptions = [...listInput?.select?.listSelectOptions];
                            const resourceName = resourcesFromSelectOptions.find((found) => found.id === Number(item))?.name

                            return (
                                <li key={item} className={'border-b border-zinc-200 last-of-type:border-0'}>
                                    <div className={'py-3 pl-6 pr-2 flex items-center gap-3'}>
                                        <BsFillBoxFill className={'text-blue-500'} size={16} />
                                        {resourceName}
                                        <FormResourcesDeleteItem selectedItems={selectedItems}
                                                                 setSelectedItems={setSelectedItems}
                                                                 item={item}
                                        />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    {selectedItems.length > sliceNumber && (
                        <FormResourcesSeeAll handleResourceSelectionClick={handleResourceSelectionClick} />
                    )}
                </div>
            )}
        </>
    );
};