import { useState } from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useFetchResourceSearch } from '../../../resourcetype/hooks/useFetchResourceType'
import {
    Dialog,
    DialogContent,
    DialogTitle, Grid,
    InputAdornment,
    MenuItem,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import Select from '@mui/material/Select'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { FiArchive, FiBox } from 'react-icons/fi'
import * as React from 'react'
import { Loader } from '../../../../components/loader/Loader'

export const SearchModal = ({ open, setOpen, setRid, isFromResource = false, searchResourceType = null  }) => {
    const globalSearchType = 'global'
    const resourceSearchType = 'resource'
    const handleClose = () => setOpen(false)
    const [inputValue, setInputValue] = useState('')
    const selectUser = createSelector(
        state => state.login.user,
        user => user,
    )
    const [searchType, setSearchType] = useState(globalSearchType)
    const user = useSelector(selectUser)
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci,
    )
    const currentInfrastructure = useSelector(selectCurrentInfrastructure)
    const {
        data: listResources,
        isLoading,
    } = useFetchResourceSearch(currentInfrastructure, inputValue, user.token, searchResourceType)
    const handleChangeSearchType = (event) => {
        setSearchType(event.target.value)
    }

    const HighlightText = ({ text, highlight }) => {
        // Function to show the search test
        const highlightText = (text, highlight) => {
            if (!highlight.trim()) {
                return text
            }
            const regex = new RegExp(`(${highlight})`, 'gi')
            const parts = text.split(regex)

            return parts.flatMap((part, index) => {
                const isHighlight = index % 2 === 1
                if (isHighlight) {
                    return [<span className={'underline font-bold text-blue-500'} key={index}>{part}</span>]
                }
                return part.split('').map((char, charIndex) => (
                    <span key={`${index}-${charIndex}`}>{char}</span>
                ))
            })
        }

        return <span>{highlightText(text, highlight)}</span>
    }

    const handleListItemClick = (resource) => {
        if (isFromResource) {
            console.log(resource)
            setOpen(false)
            if(null !== setRid && undefined !== setRid && typeof setRid === 'function') {
                setRid(resource.id.toString())
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle>
                <div className={'flex flex-row'}>
                    <TextField
                        className={isFromResource ? 'w-full' : 'basis-2/3'}
                        placeholder={`Type something to start searching ${isFromResource ? 'in ' + searchResourceType?.name : ''}`}
                        name="searchTerm"
                        value={inputValue}
                        onChange={(event) => {
                            setInputValue(event.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {!isFromResource &&
                        <Select
                            className={'basis-1/3'}
                            name="searchTypeSelect"
                            labelId="searchTypeSelectLabel"
                            id="searchTypeSelect"
                            value={searchType}
                            label="type"
                            onChange={handleChangeSearchType}
                            variant="outlined">
                            <MenuItem value={globalSearchType}>Everywhere</MenuItem>
                            <MenuItem value={resourceSearchType}>Resources</MenuItem>
                        </Select>
                    }
                </div>
            </DialogTitle>
            <DialogContent style={{
                height: '60vh',
                paddingLeft: '0',
                display: 'flex',
            }}>
                <div className={'flex flex-col w-full overflow-scroll'}>
                    {(isLoading && inputValue !== '' && inputValue.length > 2) ?
                        <Loader content="Searching..."/>
                        :
                        <>
                            {listResources.length > 0
                                ?
                                listResources.map(resourceType => (
                                    <Grid item
                                          key={'resourceType-' + resourceType.id}
                                          xs={12}
                                          lg={12}
                                          sx={{
                                              overflow: 'none',
                                              padding: '0',
                                              margin: '0',
                                              position: 'relative',
                                          }}
                                    >
                                        <Accordion key={'resourceType_' + resourceType.id}
                                                   defaultExpanded={listResources.length === 1}
                                        >

                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <div
                                                    className={`flex w-full ml-2 mb-0 px-3 py-5 font-bold border border-l-0 border-r-0 border-t-0 bg-slate-100 text-slate-700 hover:bg-slate-200`}
                                                >
                                                    <div className={'flex sticky'}>
                                                        <FiBox className={'mr-2 mt-1'} />
                                                        {resourceType.name} ({resourceType.resources.length})
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className={`flex flex-col ml-2 border-l-2 border-blue-200`}>
                                                    {resourceType.resources.map((resource, index) => (
                                                        <div key={'resource-' + resource.id}
                                                             className={`${index > 0 ? 'mt-1' : ''}  ml-4 border hover:bg-blue-200`}>
                                                            <ListItemButton
                                                                onClick={() => handleListItemClick(resource)}
                                                                key={'resource' + resource.id}
                                                                href={isFromResource ? null : `/resourcetypes/${resourceType.id}?rid=${resource.id}`}
                                                                target={'_blank'}
                                                            >
                                                                <FiBox className={'mr-2 mt-1'} />
                                                                <ListItemText
                                                                    className={'p-0'}
                                                                    secondary={<HighlightText text={resource.name}
                                                                                              highlight={inputValue} />}
                                                                />
                                                            </ListItemButton>
                                                        </div>
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                ))
                                :
                                <p className={'flex flex-col pt-5 gap-2 justify-center items-center text-slate-500 text-sm'}>
                                    <span className="text-slate-400 text-xl"><FiArchive /></span>
                                    Nothing to display, please search again
                                </p>
                            }
                        </>
                    }
                </div>

            </DialogContent>
        </Dialog>
    )
}