import {useEffect, useState} from "react";

// Redux
import {projectSlice} from "../../../redux/project/projectSlice";
import {useDispatch, useSelector} from "react-redux";

// Services
import ProjectService from "../../../services/ProjectService";
const projectService = new ProjectService();


export const useFetchProjects = (filters, token) => {

    const projectService = new ProjectService();
    const [projectsList, setProjectsList] = useState([]);

    useEffect(() => {

        async function handleFetchProjects(){
            try {

                const response = await projectService.getProjects(filters, token);
                setProjectsList(response);

            } catch (error) {
                console.error(error);
            }
        }
        handleFetchProjects().then();

    }, [filters]);

    return { projectsList }
}


export const useFetchProjectsPpasPerInfrastructure = (id, status, token) => {

    const [installationProjectsPpa, setInstallationProjectsPpa] = useState([]);

    useEffect(() => {


        async function handleFetchProjectsPpasPerInfrastructure(){
            try {

                const response = await projectService.getProjectsPpasPerInfrastructure(id, status, token);
                const hydraMember = response['hydra:member'];

                setInstallationProjectsPpa(hydraMember);


            } catch (error) {
                console.error(error);
            }
        }

        id > 0 && handleFetchProjectsPpasPerInfrastructure();


    }, [id]);

    return { installationProjectsPpa };

}


export const useFetchProjectsPerInfrastructureId = (id, token) => {


    const dispatch = useDispatch();

    const [installationProjectsList, setInstallationProjectsList] = useState([]);

    const { selectInfrastructureProjectsList } = projectSlice.selectors;
    const infrastructureProjectsListFromStore = useSelector(selectInfrastructureProjectsList);

    useEffect(() => {

            async function handleFetchInfrastructureProjectsList(){
                try {

                    const response = await projectService.getProjectsPerInfrastructureId(id, token);
                    const hydraMember = response['hydra:member'];
                    setInstallationProjectsList(hydraMember);

                    infrastructureProjectsListFromStore.length === 0 && dispatch(projectSlice.actions.addInfrastructureProjectsList(hydraMember));


                } catch (error) {
                    console.error(error);
                }
            }

            id > 0 && handleFetchInfrastructureProjectsList();


    }, [id]);

    // SWR
    // const { data, error } = useSWR([token],([token])  => projectService.getProjectsPerInfrastructureId(id, token), { suspense: true });
    //
    // useEffect(() => {
    //     if (data) {
    //         const hydraMember = data['hydra:member'];
    //         setInstallationProjectsList(hydraMember);
    //         if (id > 0 && infrastructureProjectsListFromStore.length === 0) {
    //             console.log('Here')
    //             dispatch(projectSlice.actions.addInfrastructureProjectsList(hydraMember));
    //         }
    //     }
    //
    // }, [id]);

    return { installationProjectsList };

}


export const useFetchProjectPerId = (id, token) => {

    const [installationProject, setInstallationProject] = useState(null);

    useEffect(() => {

            async function handleFetchInfrastructureProject(){
                try {

                    const response = await projectService.getProjectPerId(id, token);
                    console.dir(response)
                    setInstallationProject(response);


                } catch (error) {
                    console.error(error);
                }
            }

            id > 0 && handleFetchInfrastructureProject();


    }, [id]);

    return { installationProject };

}