import React from 'react';
import "./assets/scss/backgrounds.scss"

export const HoneycombBackgrounds = (props) => (
    <div className="honeycomb-bg mb-10 absolute">
        <div className="relative">
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
            <div className="honeycomb-item"></div>
        </div>
    </div>
);