import React from 'react'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchResourceFromResourceTypeId } from '../hooks/useFetchResourceFromResourceTypeId'

import { useEffect, useState } from 'react'
import { RichTreeView, TreeItem2, TreeItem2Label, useTreeItem2Utils } from '@mui/x-tree-view'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { useFetchResourceById } from '../hooks/useFetchResourceById'
import { useSearchParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import {
    FiCheck,
    FiChevronDown,
    FiChevronUp,
    FiCopy,
    FiEdit,
    FiMove,
    FiPlusCircle,
    FiSearch,
    FiTrash,
} from 'react-icons/fi'
import { TreeItem2LabelInput } from '@mui/x-tree-view/TreeItem2LabelInput'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import ConfirmDialog from '../../../components/confirm/ConfirmDialog'
import { Loader } from '../../../components/loader/Loader'
import { useFetchUserRole } from '../../../hooks/useFetchUserRole'
import { SearchModal } from '../../admin/components/menu/SearchModal'
import {FaCheckCircle} from "react-icons/fa";
import {CustomIcons, customTreeItem2Style} from "../../../components/tree/DesignedCustomTreeItem";
import {toast} from "sonner";
import {orderTreeByNameAndParentFirst} from "../utils/orderTreeByNameAndParentFirst";

const selectCurrentInfrastructure = createSelector(
    (state) => state.currentInfrastructure,
    (ci) => ci
)

export const ResourceTree = ({
                                 rid,
                                 setRid,
                                 resourceType,
                                 setResource,
                                 setIsLoadingResource,
                                 apiRef,
                                 isMainTree = false,
                                 handleCreateResourceClick,
                                 handleDuplicateResourceClick,
                                 handleMoveResourceClick,
                                 handleOnItemLabelChange,
                                 handleDelete,
                                 isMultiple = false,
                             }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const currentInfrastructure = useSelector(selectCurrentInfrastructure)

    const [isLoading, setIsLoading] = useState(true)

    const user = useSelector(selectUser)

    const [increment, setIncrement] = useState(0)

    const { listResources } = useFetchResourceFromResourceTypeId(
        resourceType?.id,
        user.token,
        setIsLoading,
        0,
        currentInfrastructure
    )

    const [treeItems, setTreeItems] = useState([])

    const [curResourceId, setCurResourceId] = useState(null)

    const { resource } = useFetchResourceById(curResourceId, resourceType?.id, user.token, setIsLoadingResource)

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedItems, setSelectedItems] = useState([])
    const [expandedItems, setExpandedItems] = useState([])

    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [openSearchModal, setOpenSearchModal] = useState(false)
    const [isEditResource, setIsEditResource] = useState(false)
    const [isItemExpansion, setIsItemExpansion] = useState(false)
    const [isOpenMoveResource, setIsOpenMoveResource] = useState(false)
    const [ridIncrement, setRidIncrement] = useState(0)

    const messageServiceTypeWarning = 'This element can\'t be selected because it\'s the Resource Type'

    const role = useFetchUserRole()

    const handleItemClick = (event, itemId) => {
        if (itemId?.startsWith('rt-') && event.type === 'change') {
            toast.warning(messageServiceTypeWarning)
        }
        if (isEditResource) {
            event.defaultMuiPrevented = true;
            return;
        }
        if (
            !itemId?.startsWith('rt-')
            &&
            (
                (!isItemExpansion && !event.defaultMuiPrevented)
                || (!isEditResource && !event.defaultMuiPrevented)
            )
        ) {
            setIncrement(increment + 1)
            const alreadyExist = selectedItems.indexOf(itemId) > -1
            if (isMainTree) {
                if (!alreadyExist) {
                    setCurResourceId(itemId)
                    updateUrl(itemId)
                } else {
                    setCurResourceId(null)
                    updateUrl(null)
                }
            } else {
                setResource(itemId)
            }

            if (!alreadyExist) {
                setSelectedItems([itemId])
            } else {
                setSelectedItems([])
            }
        }
        setIsItemExpansion(false)
    }

    const updateUrl = (itemId) => {
        if (null !== itemId) {
            setSearchParams({
                rid: itemId,
            })
        } else {
            setSearchParams({})
        }
        if (undefined !== setRid && typeof setRid === 'function') {
            setRid(itemId)
            setRidIncrement(ridIncrement + 1)
        }
    }

    const handleItemExpansionToggle = (event, itemIds, isExpanded) => {
        event.defaultMuiPrevented = true
        setIsItemExpansion(true)
        let newItemIds = []
        if (isExpanded) {
            newItemIds = expandedItems.concat(itemIds)
        } else {
            newItemIds = expandedItems.toSpliced(expandedItems.indexOf(itemIds), 1)
        }

        setExpandedItems(newItemIds)
    }

    useEffect(
        (event) => {
            setSelectedItems([rid])
            setCurResourceId(rid)
        },
        [rid]
    )

    useEffect(
        (event) => {
            if (isMainTree) {
                setSelectedItems([searchParams.get('rid')])
                setCurResourceId(searchParams.get('rid'))
            }
        },
        [ridIncrement]
    )

    useEffect(
        (event) => {
            setResource(resource)
        },
        [resource]
    );

    useEffect(() => {
        setTreeItems(listResources['hydra:member'] ?? [])
    }, [listResources])

    useEffect(() => {
        async function getData() {
            if (null !== curResourceId) {
                const parentList = await getParentList(curResourceId)
                setExpandedItems(parentList)
            }
        }
        getData().then();

        treeItems.length !== 0 && orderTreeByNameAndParentFirst(treeItems);
    }, [resource, treeItems]);

    const getParentList = (rId) => {
        let result = []
        const maxTime = 2000; // Temps limite en millisecondes (2s)
        const intervalTime = 200; // Intervalle entre chaque tentative (200ms)

        const startTime = Date.now(); // Temps de départ
        if (resourceType) {
            result.push('rt-' + resourceType.id)
        }
        return new Promise((resolve) => {
            const checkForResult = setInterval(() => {
                const r = apiRef?.current?.getItem(rId);

                if (r) {
                    result.push(rId);
                    if (r.parent) {
                        const splittedValue = r.parent.split('/');
                        const parentId = splittedValue.pop();
                        result.push(parentId);

                        // Recherche du parent
                        getParentList(parentId).then((parentResult) => {
                            result.push(...parentResult);
                            resolve(result); // Résout la Promise avec tous les parents
                        });
                    } else {
                        resolve(result); // Résout si pas de parent
                    }

                    clearInterval(checkForResult);
                } else if (Date.now() - startTime >= maxTime) {
                    clearInterval(checkForResult);
                    resolve(result); // Retourne ce qu'on a trouvé avant timeout
                }
            }, intervalTime);
        });
    }

    function CustomLabel({ editing, editable, children, toggleItemEditing, itemId, hasChildren, ...other }) {

        return (
            <TreeItem2Label
                {...other}
                editable={editable}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    justifyContent: 'space-between',
                }}
            >
                <div className={`custom-label py-2 px-3 rounded-lg ${hasChildren && 'border border-gray-200'}
                                text-sm overflow-hidden whitespace-pre-wrap text-ellipsis`}
                     title={children}
                >
                    {children}
                </div>
                {editable && itemId === curResourceId && resource?.canManage && (
                    <div className="flex">
                        <Tooltip title={'Move resource'}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    handleMoveResourceClick(resource)
                                }}
                                className={`opacity-20 hover:opacity-100 hover:text-slate-500`}
                                sx={{
                                    color: 'text.secondary',
                                }}
                            >
                                <FiMove />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Rename resource'}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setIsEditResource(true)
                                    toggleItemEditing()
                                }}
                                className={`opacity-20 hover:opacity-100 hover:text-blue-500`}
                                sx={{
                                    color: 'text.secondary',
                                }}
                            >
                                <FiEdit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Delete resource'}>
                            <IconButton
                                size="small"
                                onClick={() => setOpenConfirmation(true)}
                                className={`opacity-20 hover:opacity-100 hover:text-red-500`}
                                sx={{
                                    color: 'text.secondary',
                                }}
                            >
                                <FiTrash />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </TreeItem2Label>
        )
    }

    function CustomLabelInput(props) {
        const { handleCancelItemLabelEditing, handleSaveItemLabel, value, itemId, ...other } = props

        return (
            <React.Fragment>
                <TreeItem2LabelInput {...other} value={value} />
                <IconButton
                    color="success"
                    size="small"
                    onClick={(event) => {
                        handleSaveItemLabel(event, value)
                    }}
                >
                    <FiCheck />
                </IconButton>
                <IconButton
                    color="error"
                    size="small"
                    onClick={(event) => {
                        setIsEditResource(false)
                        setIsOpenMoveResource(false)
                        event.defaultMuiPrevented = false
                        handleCancelItemLabelEditing()
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </React.Fragment>
        )
    }

    const CustomTreeItem2 = React.forwardRef(function CustomTreeItem2(props, ref) {
        const { interactions, status } = useTreeItem2Utils({
            itemId: props.itemId,
            children: props.children,
        })

        const handleContentDoubleClick = (event) => {
            event.defaultMuiPrevented = true
        }

        const handleInputBlur = (event) => {
            event.defaultMuiPrevented = true
        }

        const handleInputKeyDown = (event) => {
            event.defaultMuiPrevented = true
        }

        const handleEdit = (event) => {
            setIsEditResource(true)
            return interactions.toggleItemEditing()
        }

        const handleClick = (event) => {
            if(!props.itemId.startsWith("rt-")) {
                setCurResourceId(props.itemId)
                if (isMainTree) {
                    event.defaultMuiPrevented = true
                    updateUrl(props.itemId)
                } else {
                    setResource(props.itemId);
                }
            } else {
                toast.warning(messageServiceTypeWarning)
            }
        }

        return (
            <TreeItem2
                {...props}
                ref={ref}
                slots={{ label: CustomLabel, labelInput: CustomLabelInput }}
                slotProps={{
                    label: {
                        onDoubleClick: handleContentDoubleClick,
                        editable: status.editable,
                        editing: status.editing,
                        onClick: handleClick,
                        toggleItemEditing: handleEdit,
                        itemId: props.itemId,
                        hasChildren: props.children,
                    },
                    labelInput: {
                        onBlur: handleInputBlur,
                        onKeyDown: handleInputKeyDown,
                        handleCancelItemLabelEditing: interactions.handleCancelItemLabelEditing,
                        handleSaveItemLabel: interactions.handleSaveItemLabel,
                        itemId: props.itemId,
                    },
                    checkbox: {
                        icon: <CustomIcons props={props} />,
                        checkedIcon: <CustomIcons props={props} checkedIcon={<FaCheckCircle size={16} className={"text-green-600 bg-white rounded-full"} />} />,
                    },
                }}
                sx={{...customTreeItem2Style}}
            />
        )
    })

    const handlePrivateOnItemLabelChange = (itemId, newLabel) => {
        setIsEditResource(false)
        if ('function' === typeof handleOnItemLabelChange) {
            handleOnItemLabelChange(itemId, newLabel)
        }
    }

    const updateTreeItems = (item, itemId, newLabel) => {
        let children = []
        if (item.children && item.children.length > 0) {
            children = item.children.map((child) => updateTreeItems(child, itemId, newLabel))
        }
        return item?.id.toString() === itemId.toString()
            ? { ...item, children: children, name: newLabel }
            : {
                  ...item,
                  children: children,
              }
    }

    const handleSearchModal = () => {
        return setOpenSearchModal(true)
    }

    return (
        <>
            {isLoading ? (
                <Loader content="Loading resources" />
            ) : (
                <>
                    <div className={'p-2'}>
                        {isMainTree && (
                            <>
                                <Tooltip
                                    title={'Search a resource'}
                                    placement={'top-end'}
                                    children={
                                        <button
                                            type="button"
                                            className="bg-blue-300 hover:bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-2 "
                                            onClick={handleSearchModal}
                                        >
                                            <FiSearch />
                                        </button>
                                    }
                                />
                                {openSearchModal &&
                                    <SearchModal
                                        open={openSearchModal}
                                        setOpen={setOpenSearchModal}
                                        isFromResource={true}
                                        searchResourceType={resourceType}
                                        setRid={setRid}
                                    />
                                }

                                {'function' === typeof handleCreateResourceClick && role.canCreateResources && (
                                    <Tooltip
                                        title={'Create a new resource'}
                                        placement={'top-end'}
                                        children={
                                            <button
                                                type="button"
                                                className="bg-blue-300 hover:bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-2 mx-2"
                                                onClick={() => {
                                                    handleCreateResourceClick(resource)
                                                }}
                                            >
                                                <FiPlusCircle />
                                            </button>
                                        }
                                    />
                                )}
                                {'function' === typeof handleDuplicateResourceClick && role.canCreateResources && (
                                    <Tooltip
                                        title={'Duplicate a resource'}
                                        placement={'top-end'}
                                        children={
                                            <button
                                                type="button"
                                                className="bg-blue-300 hover:bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-2"
                                                onClick={() => {
                                                    handleDuplicateResourceClick(resource)
                                                }}
                                            >
                                                <FiCopy />
                                            </button>
                                        }
                                    />
                                )}
                                {null !== resource && role.canDeleteResources && (
                                    <ConfirmDialog
                                        isOpen={openConfirmation}
                                        onClose={() => setOpenConfirmation(false)}
                                        setIsOpen={setOpenConfirmation}
                                        onAgree={() => handleDelete()}
                                        content={`Are you sure you want to delete resource "${resource.name}" and their children ?`}
                                    />
                                )}
                            </>
                        )}
                    </div>

                    <div className={`${isMainTree ? 'h-[92%] lg:h-[calc(100vh+45px)] overflow-y-auto' : 'h-full'}`}>
                        <RichTreeView
                            apiRef={apiRef}
                            slots={{
                                expandIcon: KeyboardArrowRight,
                                collapseIcon: KeyboardArrowDown,
                                item: CustomTreeItem2,
                            }}
                            getItemId={(item) => item?.id?.toString()}
                            getItemLabel={(item) => item.name + (item.children.length ? ' (' + item.children.length + ')' : '')}
                            items={treeItems}
                            isItemEditable={isMainTree}
                            experimentalFeatures={{ labelEditing: isMainTree }}
                            expansionTrigger="iconContainer"
                            checkboxSelection
                            selectedItems={selectedItems}
                            expandedItems={expandedItems}
                            onItemClick={handleItemClick}
                            onSelectedItemsChange={handleItemClick}
                            onItemExpansionToggle={handleItemExpansionToggle}
                            onItemLabelChange={(itemId, newLabel) => {
                                const newTreeItems = treeItems.map((item) => updateTreeItems(item, itemId, newLabel))
                                setTreeItems(newTreeItems)
                                handlePrivateOnItemLabelChange(itemId, newLabel)
                            }}
                            multiSelect={isMultiple ?? false}
                        />
                    </div>
                </>
            )}
        </>
    )
}
